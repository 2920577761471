import React from 'react';
import { Drawer, Divider, Typography } from 'antd';
import { connect } from 'react-redux';
import { onSideNavToggle } from 'redux/actions/Theme';
import Logo from './Logo';
import Flex from 'components/shared-components/Flex';
import { SmileOutlined, QrcodeOutlined, ControlOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { tabMenuId } from 'configs/NavigationConfig';
import { checkUrlMenu, getTranslate } from 'components/CustomService';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const svgrepo = '/img/svgrepo.svg';
const { Text } = Typography;

export const SlideBarNav = ({ location, slideBarNav, onSideNavToggle }) => {
  let history = useHistory();
  const menuId = checkUrlMenu(location, tabMenuId);

  const onClose = () => {
    onSideNavToggle(false);
  };

  const onToggleSideBar = () => {
    onSideNavToggle(!slideBarNav);
  };

  const onTabMenuClick = (id) => {
    if (id == 1) {
      history.push(`/app/home`);
    } else if (id == 2) {
      history.push(`/app/profile`);
    } else if (id == 3) {
      history.push(`/app/apps/nfc`);
    } else {
      history.push(`/app/home`);
    }
    onClose();
  };

  const classMenuAvatar = 'line-height-1-2 clickable';
  const isDevelopment = process.env.REACT_APP_ENV === 'development';
  return (
    <Drawer
      className="ant-drawer-body-side-bar"
      placement="left"
      closable={false}
      width={300}
      onClose={onClose}
      visible={slideBarNav}>
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="start" alignItems="center">
          <AvatarStatus
            className="pl-4 avatar-status-clickable"
            onDivClick={() => onToggleSideBar()}
            src={svgrepo}
            shape="square"
            size={'small'}
          />
          <Logo mobileLogo={false} forceNavCollapsed={false} />
          <div className="nav-close" onClick={() => onClose()}>
            {/* <ArrowLeftOutlined /> */}
          </div>
        </Flex>
        <div className="px-4">
          <Divider className="m-0 p-0" />
          <div className="p-2 pt-3 px-1">
            <Text type="secondary" className="font-size-sm font-weight-bold">
              All APP
            </Text>
            <div className="slidebar-title-blue-waan py-3 pb-4">
              {isDevelopment && (
                <>
                  {menuId != 1 && (
                    <AvatarStatus
                      className={classMenuAvatar}
                      shape="square"
                      size={34}
                      icon={<SmileOutlined className="font-size-19" />}
                      onDivClick={() => onTabMenuClick(1)}
                      name={getTranslate('Waan_Point')}
                      subTitle={getTranslate('Member_point_accumulation_system')}
                    />
                  )}
                </>
              )}

              {menuId != 3 && (
                <>
                  {isDevelopment && <>{menuId != 1 && <Divider className="m-0 p-0 my-3" />}</>}
                  <AvatarStatus
                    className={classMenuAvatar}
                    shape="square"
                    size={34}
                    icon={<QrcodeOutlined className="font-size-19" />}
                    onDivClick={() => onTabMenuClick(3)}
                    name={getTranslate('Waan_Tid')}
                    subTitle={getTranslate('Create_NFC_QR_Code')}
                  />
                </>
              )}

              {menuId != 2 && (
                <>
                  {menuId != 3 && <Divider className="m-0 p-0 my-3" />}
                  {isDevelopment && <>{menuId != 1 && <Divider className="m-0 p-0 my-3" />}</>}
                  <AvatarStatus
                    className={classMenuAvatar}
                    shape="square"
                    size={34}
                    icon={<ControlOutlined className="font-size-19" />}
                    onDivClick={() => onTabMenuClick(2)}
                    name={getTranslate('setting1')}
                    subTitle={getTranslate('Set_up_profiles_and_packages')}
                  />
                </>
              )}
            </div>
            <div className="mb-3 mt-1">
              <Text type="secondary">{getTranslate('setting1')}</Text>
            </div>

            <div>
              <Link to={'/app/profile'} onClick={() => onClose()}>
                <Text className="font-weight-semibold">{getTranslate('profile')}</Text>
              </Link>
            </div>
            <Divider className="m-0 p-0 my-2" />
            <div>
              <Link to={'/app/profile/package'} onClick={() => onClose()}>
                <Text className="font-weight-semibold">{getTranslate('Packages_Quota1')}</Text>
              </Link>
            </div>
            <Divider className="m-0 p-0 my-2" />
            <div>
              <Link to={'/app/profile/billing'} onClick={() => onClose()}>
                <Text className="font-weight-semibold">{getTranslate('Bill_Payment1')}</Text>
              </Link>
            </div>
            <Divider className="m-0 p-0 my-2" />
            <div>
              <Link to={'/app/apps/system/system-activity'} onClick={() => onClose()}>
                <Text className="font-weight-semibold">{getTranslate('movement')}</Text>
              </Link>
            </div>
            <Divider className="m-0 p-0 my-2" />
          </div>
        </div>
      </Flex>
    </Drawer>
  );
};

SlideBarNav.propTypes = {
  sideNavTheme: PropTypes.string,
  slideBarNav: PropTypes.bool,
  onSideNavToggle: PropTypes.func,
  routeInfo: PropTypes.object,
  hideGroupTitle: PropTypes.string,
  localization: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, slideBarNav } = theme;
  return { navCollapsed, sideNavTheme, slideBarNav };
};

export default connect(mapStateToProps, { onSideNavToggle })(SlideBarNav);
