import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
// import { Link } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  // SearchOutlined
} from '@ant-design/icons';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavSearch from './NavSearch';
import NavOrganization from './NavOrganization';
// import SearchInput from './NavSearch/SearchInput.js';
import { toggleCollapsedNav, onMobileNavToggle, onSideNavToggle } from 'redux/actions/Theme';
import PropTypes from 'prop-types';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils';
import AvatarStatus from 'components/shared-components/AvatarStatus';


// const { SubMenu } = Menu;
import QRHorizontal from './NavQrTop';

const svgrepo = '/img/svgrepo.svg';
const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    slideBarNav,
    onSideNavToggle,
    waan,
  } = props;
  const userInfo = waan.userInfo;
  const { isAdmin = false } = userInfo;

  const [searchActive, setSearchActive] = useState(false);

  // const onSearchActive = () => {
  //   setSearchActive(true);
  // };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {

    if(waan){ 
      document.title = 'Console ' +  waan.userInfo.companie_name + ' : WAANKIT'; 
    }
    if (!isMobile) {
      onSearchClose();
    }
  });

  const onToggleSideBar = () => {
    onSideNavToggle(!slideBarNav);
  };


 


  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
     
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        {!isAdmin ? (
          <AvatarStatus
            className="pl-4 avatar-status-clickable"
            onAvatarClick={() => onToggleSideBar()}
            src={svgrepo}
            shape="square"
            size={'small'}
          />
        ) : (
          <div className="w-px-65" />
        )}
        <Logo logoType={navMode} /> 
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child" style={{display:"none"}}
                  onClick={() => {
                    onToggle();
                  }}>
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {/* {console.log(menuId,"dd")} */}
              <li className="ant-menu-item ">
                
                  <div className="top-nav-wrapper">
                    {/* <MenuContent type={NAV_TYPE_TOP} {...props} /> */}
                    <QRHorizontal    />
                    
                  </div>
                
              </li>
              {/* {isMobile ? (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onSearchActive();
                  }}>
                  <SearchOutlined />
                </li>
              ) : (
                <li className="ant-menu-item ant-menu-item-only-child" style={{ cursor: 'auto' }}>
                  <SearchInput mode={mode} isMobile={isMobile} />
                </li>
              )} */}
            </ul>
          </div>
          <div className="nav-right">
            {!isAdmin && <NavOrganization />}
            {!isAdmin && <NavNotification />}
            {!isAdmin && <NavLanguage />}
            <NavProfile />
            {/* <NavPanel direction={direction} /> */}
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

HeaderNav.propTypes = {
  navCollapsed: PropTypes.bool,
  mobileNav: PropTypes.bool,
  navType: PropTypes.string,
  headerNavColor: PropTypes.string,
  toggleCollapsedNav: PropTypes.func,
  onMobileNavToggle: PropTypes.func,
  onSideNavToggle: PropTypes.func,
  isMobile: PropTypes.bool,
  currentTheme: PropTypes.string,
  direction: PropTypes.string,
  slideBarNav: PropTypes.bool,
  waan: PropTypes.object,
};

const mapStateToProps = ({ theme, waan }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, slideBarNav } =
    theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    slideBarNav,
    waan,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
  onSideNavToggle,
})(HeaderNav);
