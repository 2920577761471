import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import Loading from 'components/shared-components/Loading';
import PropTypes from 'prop-types';
const Notfound = lazy(() => import(`./app-views/Notfound`));

export const Views = (props) => {
  const { locale, location, direction, token, userInfo, redirect } = props;
  const isLogin = token != null ? true : false;

  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={AUTH_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout
              direction={direction}
              location={location}
              isLogin={isLogin}
              userInfo={userInfo}
              redirect={redirect}
            />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} isLogin={isLogin} />
          </Route>
          <Suspense fallback={<Loading cover="content" />}>
            <Route path="*" component={Notfound} />
          </Suspense>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

Views.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.object,
  direction: PropTypes.string,
  token: PropTypes.string,
  userInfo: PropTypes.object,
  redirect: PropTypes.string,
};

const mapStateToProps = ({ theme, auth, waan }) => {
  const { locale, direction } = theme;
  const { refresh, userInfo } = waan;
  const { token, redirect } = auth;
  return { refresh, userInfo, locale, token, redirect, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
