import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Waan from "./Waan";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  waan: Waan,
});

export default reducers;
