/**
 * @type {UserConfig}
 */
import ReactGA from 'react-ga4';

const pluginConfig = {
  current_lang: 'th',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  gui_options: {
    consent_modal: {
      layout: 'cloud', // box/cloud/bar
      position: 'bottom center', // bottom/middle/top + left/right/center
      transition: 'slide', // zoom/slide
      swap_buttons: false, // enable to invert buttons
    },
    settings_modal: {
      layout: 'box', // box/bar
      //position: 'left',              // left/right
      transition: 'slide', // zoom/slide
    },
  },
  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  cookie_domain: 'waankit.com', // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  revision: 1, // default: 0

  // onFirstAction: function (user_preferences, cookie) {
  //   // callback triggered only once
  //   const analyticsEnabled = window.CC.allowedCategory('analytics');
  //   console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
  //  // console.log(cookie, user_preferences);
  // },

  onAccept: function (cookie) {
    const outputObject = {
      necessary: true,
      analytics: false,
      targeting: false,
    };

    const google_consent = {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    };

    for (const item of cookie.categories) {
      outputObject[item] = true;
    }

    if (outputObject.analytics) {
      google_consent.analytics_storage = 'granted';
    }
    if (outputObject.targeting) {
      google_consent.ad_storage = 'granted';
    }
    ReactGA.gtag('consent', 'update', {
      ad_storage: google_consent.ad_storage,
      analytics_storage: google_consent.analytics_storage,
    });

    ReactGA.gtag('event', 'ConsentConfiguredEvent');

    //console.log(google_consent);
  },

  // onChange: function (cookie, changed_preferences) {
  //   //console.log(cookie, changed_preferences);
  // },

  languages: {
    th: {
      consent_modal: {
        title: 'นโยบายการใช้คุกกี้!',
        description:
          'WaanKit ใช้คุกกี้เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้เว็บไซต์ ท่านสามารถเลือกตั้งค่ายินยอมการใช้คุกกี้ได้โดยคลิก “การตั้งค่าคุกกี้” และสามารถศึกษารายละเอียดการใช้คุกกี้ได้ที่ "<a href="https://www.waankit.com/cookie-policy/"  target="_blank"  >นโยบายการใช้คุกกี้</a>" &nbsp&nbsp&nbsp <button type="button" data-cc="c-settings" class="cc-link">การตั้งค่าคุกกี้</button>',
        primary_btn: {
          text: 'ยอมรับทั้งหมด',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'ปฎิเสธทั้งหมด',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'การจัดการคุกกี้',
        save_settings_btn: 'บันทึก',
        accept_all_btn: 'ยอมรับทั้งหมด',
        reject_all_btn: 'ปฏิเสธทั้งหมด',
        close_btn_label: 'ปิด',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: '',
            description:
              'เรามีการเก็บและใช้งานคุกกี้เพื่อช่วยปรับปรุงพัฒนาประสบการณ์การใช้งานให้ดีขึ้นเมื่อคุณเข้าเยี่ยมชมเว็บไซต์ของเรา คุณสามารถเลือกให้ความยินยอมคุกกี้แต่ละประเภทได้ (ยกเว้นคุกกี้ที่จำเป็น)  <a href="https://www.waankit.com/cookie-policy/" class="cc-link">นโยบายคุกกี้</a>.',
          },
          {
            title: 'คุกกี้ที่จําเป็นอย่างยิ่งในการใช้งาน',
            description:
              'WaanKit จำเป็นต้องใช้คุกกี้ประเภทนี้ เพื่อให้คุณสามารถเข้าถึงข้อมูล และใช้งานทุกฟังก์ชันบนเว็บไซต์ได้อย่างมีประสิทธิภาพ โดยคุกกี้นี้ ไม่ได้มีการจัดเก็บข้อมูลที่สามารถระบุตัวตนของผู้ใช้แต่อย่างใด',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'คุกกี้สำหรับเก็บข้อมูลวิเคราะห์การใช้งาน',
            description:
              'ยินยอมให้ WaanKit เก็บรวมรวมข้อมูลการใช้งานเพื่อนำมาวิเคราะห์ เพื่อปรับปรุงและพัฒนาประสบการณ์การใช้งานให้ดียิ่งขึ้น',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            // cookie_table: [
            //   // list of all expected cookies
            //   {
            //     col1: '^_ga', // match all cookies starting with "_ga"
            //     col2: 'google.com',
            //     col3: '2 years',
            //     col4: 'description ...',
            //     is_regex: true,
            //   },
            //   {
            //     col1: '_gid',
            //     col2: 'google.com',
            //     col3: '1 day',
            //     col4: 'description ...',
            //   },
            // ],
          },
          {
            title: 'คุกกี้สำหรับทําการตลาดและโฆษณา',
            description:
              'ยินยอมให้ WaanKit เก็บรวมรวมข้อมูลสำหรับการทำโฆษณา เพื่อให้เราช่วยนำเสนอเนื้อหา บริการที่เหมาะสมกับคุณที่สุด',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          //   {
          //     title: 'More information',
          //     description:
          //       'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
          //   },
        ],
      },
    },
    en: {
      consent_modal: {
        title: "Cookie consent!",
        description:
          'WaanKit uses cookies to enhance performance and improve user experience on the website. You can manage your cookie preferences by clicking "Cookie Settings" and learn more about cookie usage in our "<a href="https://www.waankit.com/en/cookie-policy/"  target="_blank"  >Cookie Policy</a>" &nbsp&nbsp&nbsp<button type="button" data-cc="c-settings" class="cc-link">Cookie Settings</button>',
        primary_btn: {
          text: "Accept all cookies",
          role: "accept_all", // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Cookie Settings",
          role: "c-settings", // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Cookie preferences",
        save_settings_btn: "Save setting",
        accept_all_btn: "Accept all",
        reject_all_btn: "Reject all",
        close_btn_label: "Close",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Expiration" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "",
            description:
            'We collect and use cookies to help improve and develop the user experience when you visit our website. You can choose to consent to each type of cookie (except necessary cookies) <a href="https://www.waankit.com/cookie-policy/" class="cc-link">Cookie Policy</a>.',
          },
          {
            title: "Necessary Cookies (Always Enabled)",
            description:
              "WaanKit requires the use of this type of cookie to enable you to access information and utilize all functions on the website efficiently. This cookie does not store any personally identifiable information.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: "Analytics Cookies",
            description:
              "I consent to WaanKit collecting usage data for analysis, in order to improve and develop the user experience.",
            toggle: {
              value: "analytics", // your cookie category
              enabled: true,
              readonly: false,
            },
            // cookie_table: [
            //   // list of all expected cookies
            //   {
            //     col1: '^_ga', // match all cookies starting with "_ga"
            //     col2: 'google.com',
            //     col3: '2 years',
            //     col4: 'description ...',
            //     is_regex: true,
            //   },
            //   {
            //     col1: '_gid',
            //     col2: 'google.com',
            //     col3: '1 day',
            //     col4: 'description ...',
            //   },
            // ],
          },
          {
            title: "Advertisement and Targeting Cookies",
            description:
              "I consent to WaanKit collecting data for advertising purposes, to help tailor content and services that are most relevant to you.",
            toggle: {
              value: "targeting",
              enabled: true,
              readonly: false,
            },
          },
          //   {
          //     title: 'More information',
          //     description:
          //       'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
          //   },
        ],
      },
    },
  },
};

export default pluginConfig;
