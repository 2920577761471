import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/AppConfig';
// import Schema from 'async-validator';
// Schema.warning = function(){};
import TagManager from 'react-gtm-module';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import CookieConsentComponent from './components/CookieConsentComponent';
import ReactGA from 'react-ga4';

// if (process.env.REACT_APP_ENV === 'production') {
const tagManagerArgs = {
  gtmId: 'GTM-59CXGSNC',
};
// }
TagManager.initialize(tagManagerArgs);
ReactGA.gtag('consent', 'default', {
  ad_storage: 'denied',
  analytics_storage: 'denied',
});

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  //TagManager.dataLayer({dataLayer: ['consent', 'default', { ad_storage: 'denied' , analytics_storage: 'denied' }]})

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point">
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
        <CookieConsentComponent />
      </Provider>
    </div>
  );
}

export default App;
