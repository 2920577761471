import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  CUSTOMER_TAGS_GET,
  CUSTOMER_TAGS_UPDATE,
  COMPANIES_GET,
  NOTIFICATION_GET,
} from '../constants/Waan';
import {
  customerTagsChange,
  loadDataCustomerTags,
  companiesChange,
  hideLoading,
  showLoading,
  notificationChange,
  countReadChange,
  onUserInfoChange,
} from '../actions/Waan';
import { getCookieToken, checkCompanieStaff } from 'components/CustomService';
import axios from 'axios';
import qs from 'qs';
import store from 'redux/store';

export function* customerTagsAll() {
  yield takeEvery(CUSTOMER_TAGS_GET, function* ({ payload }) {
    const { companie_id } = payload;
    const { waan } = store.getState();
    const { customerTags } = waan;
    yield put(loadDataCustomerTags(true));
    try {
      if (customerTags.length < 1 && companie_id != null) {
        const { data } = yield axios.get(
          process.env.REACT_APP_API + `/api/customer-tag/by_companie_id/${companie_id}`,
        );
        yield put(customerTagsChange(data.data));
      }
      yield put(loadDataCustomerTags(false));
    } catch (err) {
      yield put(loadDataCustomerTags(false));
    }
  });
}

export function* customerTagsAllUpdate() {
  yield takeEvery(CUSTOMER_TAGS_UPDATE, function* ({ payload }) {
    const { companie_id } = payload;
    yield put(loadDataCustomerTags(true));
    try {
      if (companie_id != null) {
        const { data } = yield axios.get(
          process.env.REACT_APP_API + `/api/customer-tag/by_companie_id/${companie_id}`,
        );
        yield put(customerTagsChange(data.data));
      }
      yield put(loadDataCustomerTags(false));
    } catch (err) {
      yield put(loadDataCustomerTags(false));
    }
  });
}

export function* companiesAllGet() {
  yield takeEvery(COMPANIES_GET, function* () {
    const { waan } = store.getState();
    const { userInfo } = waan;
    let companies = [];
    yield put(showLoading());
    try {
      const { data, status } = yield axios
        .get(process.env.REACT_APP_API + `/api/companie/myCompanies`, {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${getCookieToken()}`,
          },
        })
        .catch(() => {});
      if (status === 200) {
        companies = data;
        // yield put(companiesChange(data));
      } else {
        // yield put(companiesChange([]));
      }
    } catch (err) {
      // yield put(companiesChange([]));
    }

    const companie = yield call(checkCompanieStaff, companies, userInfo);
    if (companie.isUpdate && companie.userInfo) {
      yield put(onUserInfoChange(companie.userInfo));
    }
    yield put(companiesChange(companies));
    yield put(hideLoading());
  });
}

export function* notificationGet() {
  yield takeEvery(NOTIFICATION_GET, function* () {
    try {
      const queryPagination = qs.stringify(
        {
          pagination: {
            page: 0,
            pageSize: 10,
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        },
      );
      const { data, status } = yield axios
        .get(process.env.REACT_APP_SOCKET + `/api/notification/by_user_id?${queryPagination}`, {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${getCookieToken()}`,
          },
        })
        .catch(() => {});
      if (status === 200) {
        yield put(notificationChange(data.data));
        yield put(countReadChange(data.count_read));
      } else {
        yield put(notificationChange([]));
        yield put(countReadChange(0));
      }
    } catch (err) {
      yield put(notificationChange([]));
      yield put(countReadChange(0));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(customerTagsAll),
    fork(customerTagsAllUpdate),
    fork(companiesAllGet),
    fork(notificationGet),
  ]);
}
