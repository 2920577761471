import React from 'react';
import PropTypes from 'prop-types';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from 'react-redux';
import utils from 'utils';
import { Grid } from 'antd';
// import { useHistory } from 'react-router-dom';
import { tabMenuId } from 'configs/NavigationConfig';
import { checkUrlMenu } from 'components/CustomService';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH - 60}px`;
  }
};

const getImgLogoWidth = (props, isMobile) => {
  const { navCollapsed, forceNavCollapsed } = props;
  if (isMobile && props.mobileLogo) {
    return `40px`;
  }
  if (forceNavCollapsed != undefined) {
    if (forceNavCollapsed === true) {
      return `20px`;
    } else if (forceNavCollapsed === false) {
      return `30px`;
    }
  }

  if (navCollapsed) {
    return `20px`;
  } else {
    return `25px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  const pathLogo = '/img/logo-waankit-red.png';

  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-sm-white.png';
    }
    return '/img/logo-white.png';
  }

  if (navCollapsed) {
    return pathLogo;
  }
  return pathLogo;
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && mobileLogo === undefined) {
    return 'd-none';
  } else if (isMobile && mobileLogo === false) {
    return 'logo pl-3';
  } else if (isMobile && mobileLogo === true) {
    return 'logo pl-2';
  } else {
    return 'logo pl-1';
  }
};

export const Logo = (props) => {
  // let history = useHistory();
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
      >
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          const menuId = checkUrlMenu(location, tabMenuId);
          if (menuId === 3) {
            window.location.href = '/app/apps/nfc';
            // history.push(`/app/apps/nfc`);
          } else if (menuId === 2) {
            // history.push(`/app/profile/edit-profile`);
            window.location.href = '/app/profile/edit-profile';
          } else {
            // history.push(`/app/home`);
            window.location.href = '/app/home';
          }
        }}>
        <img
          className="clickable head-logo"
          src={getLogo(props)}
          alt={`${APP_NAME} logo`}
          style={{ height: `${getImgLogoWidth(props, isMobile)}` }}
        />
      </a>
    </div>
  );
};

Logo.propTypes = {
  mobileLogo: PropTypes.bool,
  forceNavCollapsed: PropTypes.bool,
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
