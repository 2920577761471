import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { GlobalOutlined, DownOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { onLocaleChange } from 'redux/actions/Theme';
import { getTranslate } from 'components/CustomService';

const lang = [
  {
    langName: 'Thailand',
    langId: 'th',
    icon: 'th',
    lang: 'Th',
    localeKey: 'thailand',
  },
  {
    langName: 'English',
    langId: 'en',
    icon: 'us',
    lang: 'En',
    localeKey: 'english',
  },
];

function getLanguageDetail(locale) {
  const data = lang.filter((elm) => elm.langId === locale);
  return data[0];
}

const SelectedLanguage = ({ locale }) => {
  const language = getLanguageDetail(locale);
  const { localeKey, icon } = language;
  return (
    <div className="d-flex align-items-center">
      <img
        style={{ maxWidth: '20px' }}
        src={`/img/flags/${icon}.png`}
        alt={getTranslate(localeKey)}
      />
      <span className="font-weight-semibold ml-2">
        {getTranslate(localeKey)} <DownOutlined className="font-size-xs" />
      </span>
    </div>
  );
};

SelectedLanguage.propTypes = {
  locale: PropTypes.string,
};

export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {
  const languageOption = (
    <Menu>
      {lang.map((elm, i) => {
        return (
          <Menu.Item
            key={i}
            className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
            onClick={() => {
              localStorage.setItem('language', elm.langId);
              onLocaleChange(elm.langId);
            }}>
            <span className="d-flex justify-content-between align-items-center">
              <div>
                <img
                  style={{ maxWidth: '20px' }}
                  src={`/img/flags/${elm.icon}.png`}
                  alt={getTranslate(elm.localeKey)}
                />
                <span className="font-weight-normal ml-2">{getTranslate(elm.localeKey)}</span>
              </div>
              {locale === elm.langId ? <CheckCircleFilled className="waan-blue-color" /> : null}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    const urlSearchString = window.location.search;
    const urlParams = new URLSearchParams(urlSearchString);
    const paramLang = urlParams.get('lang');

    if (paramLang != null) {
      const getParamLang = lang.find(({ langId }) => langId === paramLang);
      if (getParamLang && locale !== paramLang) {
        localStorage.setItem('language', getParamLang.langId);
        onLocaleChange(getParamLang.langId);
      }

      urlParams.delete('lang');
      const urlQuery = urlParams.toString().trim();
      const newUrl = `${window.location.pathname}${urlQuery !== '' ? `?${urlQuery}` : ''}${
        window.location.hash
      }`;
      window.history.pushState(null, null, newUrl);
    }
  }, []);

  return (
    <Dropdown placement="bottomRight" overlay={languageOption} trigger={['click']}>
      {configDisplay ? (
        <a href="#/" className="text-gray" onClick={(e) => e.preventDefault()}>
          <SelectedLanguage locale={locale} />
        </a>
      ) : (
        <Menu mode="horizontal">
          <Menu.Item key="language">
            <a href="#/" onClick={(e) => e.preventDefault()}>
              <GlobalOutlined className="nav-icon mr-0" />
            </a>
          </Menu.Item>
        </Menu>
      )}
    </Dropdown>
  );
};

NavLanguage.propTypes = {
  locale: PropTypes.string,
  configDisplay: PropTypes.bool,
  onLocaleChange: PropTypes.func,
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
