import {
  REFRESH_CHANGE,
  USER_INFO_CHANGE,
  CUSTOMER_TAGS_CHANGE,
  LOADDATA_CUSTOMER_TAGS,
  COMPANIES_CHANGE,
  WAAN_SHOW_LOADING,
  WAAN_HIDE_LOADING,
  NOTIFICATION_CHANGE,
  NOTIFICATION_COUNT_READ_CHANGE,
  IMPORT_DATA_ID_CHANGE,
  VISIBLE_CHANGE_PACKAGE,
} from '../constants/Waan';
import { GetUserInfo, setUserInfo } from 'components/CustomService';

const initCompanie = {
  loading: false,
  refresh: false,
  userInfo: GetUserInfo(),
  loadDataCustomerTags: false,
  customerTags: [],
  companies: [],
  notification: [],
  count_read: 0,
  importDataId: null,
  visibleChangePackage: false,
};

const waan = (state = initCompanie, action) => {
  switch (action.type) {
    case REFRESH_CHANGE:
      return {
        ...state,
        refresh: action.refresh,
      };
    case USER_INFO_CHANGE:
      setUserInfo({
        ...action.userInfo,
      });
      return {
        ...state,
        userInfo: { ...action.userInfo },
      };
    case CUSTOMER_TAGS_CHANGE:
      return {
        ...state,
        customerTags: action.customerTags,
      };
    case LOADDATA_CUSTOMER_TAGS:
      return {
        ...state,
        loadDataCustomerTags: action.status,
      };
    case COMPANIES_CHANGE:
      return {
        ...state,
        companies: action.companies,
      };
    case WAAN_SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case WAAN_HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case NOTIFICATION_CHANGE:
      return {
        ...state,
        notification: action.notification,
      };
    case NOTIFICATION_COUNT_READ_CHANGE:
      return {
        ...state,
        count_read: action.count_read,
      };
    case IMPORT_DATA_ID_CHANGE:
      return {
        ...state,
        importDataId: action.importId,
      };
    case VISIBLE_CHANGE_PACKAGE:
      return {
        ...state,
        visibleChangePackage: action.visible,
      };
    default:
      return state;
  }
};

export default waan;
