import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
const login = lazy(() => import('./authentication/login'));
const register = lazy(() => import('./authentication/register-2'));
const welcome = lazy(() => import("./welcome"));
const receiveAnInvitation = lazy(() => import("./acceptInvite/receiveAnInvitation"));
const google_auth = lazy(() => import("./google_auth"));

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={login} />
        <Route path={`${AUTH_PREFIX_PATH}/google-auth`} component={google_auth} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={register} />
        <Route path={`${AUTH_PREFIX_PATH}/welcome`} component={welcome} />
        <Route path={`${AUTH_PREFIX_PATH}/accept-invite`} component={receiveAnInvitation} />
        <Route path={`${AUTH_PREFIX_PATH}/change-pass`} component={lazy(() => import(`./authentication/change-pass`))} />
        {/* <Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} /> */}
        {/* <Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} /> */}
        {/* <Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} /> */}
        {/* <Route path={`${AUTH_PREFIX_PATH}/register-2`} component={lazy(() => import(`./authentication/register-2`))} /> */}
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        {/* <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} /> */}
        {/* <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} /> */}
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

