import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Menu, Dropdown, Avatar, Typography, Badge, Spin, Card, Progress, Divider } from 'antd';
import { connect } from 'react-redux';
import { HistoryOutlined, AppstoreAddOutlined, ApiOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { getTranslate, checkNull, cleanCookieAuth } from 'components/CustomService';
import PropTypes from 'prop-types';
import CardStaff from 'components/waan-components/staff/CardStaff';
import { ROLE_PREFIX_ADMIN, APP_PREFIX_PATH } from 'configs/AppConfig';
import { onUserInfoChange } from 'redux/actions/Waan';
import { useHistory } from 'react-router-dom';
import Flex from 'components/shared-components/Flex';
import { appLayoutContext } from 'components/waan-components/use/createContext';
// import UseRights from 'components/waan-components/use/UseRights';
// import { onVisibleChangePackage } from 'redux/actions/Waan';
// import ChangePackage from 'components/waan-components/drawer/profile/ChangePackage';

const { Text } = Typography;
const isProduction = process.env.REACT_APP_ENV === 'production';
export const NavProfile = ({ signOut, waan, onUserInfoChange }) => {
  let history = useHistory();
  const userInfo = waan.userInfo;
  const companies = waan.companies;
  const admin_rights = userInfo.admin_rights;
  const roles = userInfo.roles;
  const { isAdmin = false } = userInfo;
  const [ImgAvatar, setImgAvatar] = useState(null);
  const [checkCompanie, setCheckCompanie] = useState(null);
  const [checkRoleAdmin, setCheckRoleAdmin] = useState(false);

  // package.count
  const _packageContext = useContext(appLayoutContext);
  const { dataPackage, isLoadingPackage } = _packageContext;

  useEffect(() => {
    if (checkNull(userInfo.img_avatar) && checkNull(userInfo.path_img_avatar)) {
      setImgAvatar(
        `${process.env.REACT_APP_PathCDN}/${userInfo.path_img_avatar}/${userInfo.img_avatar}`,
      );
    }
  }, [userInfo]);

  useEffect(() => {
    const getCompanie = (companies ?? []).find(
      (f) =>
        f.attributes.staff_admin?.attributes?.status == true &&
        f.attributes.staff_admin?.attributes?.enable == true,
    );
    setCheckCompanie(getCompanie ? getCompanie : null);
  }, [companies]);

  useEffect(() => {
    setCheckRoleAdmin((roles ?? []).includes(ROLE_PREFIX_ADMIN));
  }, [roles]);

  const switch_console = () => {
    if (isAdmin) {
      if (checkCompanie) {
        const admin_rights = checkCompanie.attributes.staff_admin?.attributes.admin_rights;
        onUserInfoChange({
          ...userInfo,
          companie_id: checkCompanie.id,
          companie_name: checkCompanie.attributes.name,
          companie_uid: checkCompanie.attributes.uid,
          admin_rights: admin_rights == null ? null : admin_rights,
          isAdmin: false,
        });
        history.push(`${APP_PREFIX_PATH}/home`);
      }
    } else {
      if (checkRoleAdmin) {
        onUserInfoChange({
          ...userInfo,
          companie_id: null,
          companie_name: null,
          companie_uid: null,
          admin_rights: null,
          isAdmin: true,
        });
        history.push(`/app/staff/link`);
      }
    }
  };

  const calculatePercent = (use, quantity) => {
    return parseInt(((use / quantity) * 100).toFixed());
  };

  const getPackage = useMemo(() => {
    if (!dataPackage?.package_id) return null;
    const { package: packageDes, count } = dataPackage;
    const { link: used_link, page: used_page } = count;
    const { Name, Q_Link, Q_Page } = packageDes;

    const percent_link = Q_Link === 0 ? 100 : calculatePercent(used_link, Q_Link);
    const percent_page = Q_Page === 0 ? 100 : calculatePercent(used_page, Q_Page);
    return {
      Name,
      used_link,
      used_page,
      quota_link: Q_Link,
      quota_page: Q_Page,
      percent_link,
      percent_page,
    };
  }, [dataPackage]);

  const isPermissionApi = useMemo(() => {
    return ![0].includes(admin_rights) && !isAdmin ? false : true;
  }, [admin_rights, isAdmin]);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={ImgAvatar} />
          <div className="pl-3">
            <h4 className="mb-0">
              {userInfo?.firstname} {userInfo?.lastname}
            </h4>
            <Link to="/app/profile">{getTranslate('editProfile')}</Link>
          </div>
        </div>
      </div>
      <div className="nav-profile-body pt-0 pb-1">
        <Menu>
          <Menu.Item key={'nav-profile-movement'} className="my-1 py-0">
            <Link to={'/app/profile/movement'}>
              <Icon className="mr-2" type={HistoryOutlined} /> {getTranslate('movement')}
            </Link>
          </Menu.Item>
          <Menu.Divider className="m-0 p-0" />
          <Menu.Item key={'nav-profile-package'} className="my-1 py-0">
            <Link to={'/app/profile/package'}>
              <Icon className="mr-2" type={AppstoreAddOutlined} /> {getTranslate('package')}
            </Link>
          </Menu.Item>
          {!isProduction && isPermissionApi && (
            <>
              <Menu.Divider className="m-0 p-0" />
              <Menu.Item key={'nav-profile-api-developers'} className="my-1 py-0">
                <Link to={'/app/profile/api-developers'}>
                  <Icon className="mr-2" type={ApiOutlined} /> {getTranslate('API_for_developers')}
                </Link>
              </Menu.Item>
            </>
          )}
          {isAdmin && checkCompanie && (
            <CardStaff
              onClick={switch_console}
              cardClass={'rounded-0 mb-0'}
              isAdmin={true}
              name={'เปลี่ยนใช้งาน CONSOLE WaanKit'}
              suffix="Console Waankit"
            />
          )}
          {!isAdmin && checkRoleAdmin && (
            <CardStaff
              onClick={switch_console}
              cardClass={'rounded-0 mb-0'}
              isAdmin={false}
              name={'เปลี่ยนใช้งาน CONSOLE ADMIN'}
              suffix="Staff Waando"
            />
          )}
          <Menu.Divider className="m-0 p-0" />

          {getPackage && (
            <div className="slide-bar-package-box">
              <Flex
                className="ml-3 mr-2 mb-2 mt-3"
                alignItems={'start'}
                justifyContent={'between'}
                flexDirection={'row'}>
                <Text className="font-size-sm title-package-box">
                  {/* {getTranslate('package')}  */}
                  <Badge status="success" />
                  <Text>{getPackage.Name}</Text>
                </Text>
              </Flex>
              <Spin spinning={isLoadingPackage}>
                <Card className="ml-2 mr-2 mr-0 p-3 ant-card-body-p-0">
                  <Flex
                    className="mt-1 line_text"
                    alignItems={'start'}
                    justifyContent={'between'}
                    flexDirection={'row'}>
                    <Text>{getTranslate('usage')}</Text>
                    <a href="/app/profile/package">{getTranslate('see_all')}</a>
                  </Flex>
                  <Divider className="my-2" />
                  <Flex
                    className="mt-2 line_text"
                    alignItems={'start'}
                    justifyContent={'between'}
                    flexDirection={'row'}>
                    <Text>{getTranslate('link')}</Text>
                    <Text>
                      <span className="pf">{getPackage.used_link}</span> /{' '}
                      <span className="pb">{getPackage.quota_link}</span>
                    </Text>
                  </Flex>
                  <Progress
                    percent={getPackage.percent_link}
                    showInfo={false}
                    status={getPackage.percent_link >= 80 ? 'exception' : ''}
                  />
                  <Flex
                    className="mt-2 line_text"
                    alignItems={'start'}
                    justifyContent={'between'}
                    flexDirection={'row'}>
                    <Text>{getTranslate('page1')}</Text>
                    <Text>
                      <span className="pf">{getPackage.used_page}</span> /{' '}
                      <span className="pb">{getPackage.quota_page}</span>
                    </Text>
                  </Flex>
                  <Progress
                    percent={getPackage.percent_page}
                    showInfo={false}
                    status={getPackage.percent_page >= 80 ? 'exception' : ''}
                  />
                  {/* <Divider className="my-2" /> */}
                  {/* <div className="text-right  line_text">
                  <UseRights>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        onVisibleChangePackage(true);
                      }}>
                      {getTranslate('adjust_package')}
                    </a>
                  </UseRights>
                </div> */}
                </Card>
              </Spin>

              {/* <ChangePackage
              companie_id={companie_id}
              visible={visibleChangePackage}
              setVisible={onVisibleChangePackage}
              windowSize={windowSize}
            /> */}
            </div>
          )}

          <Menu.Divider className="m-0 p-0" />

          <Menu.Item
            key={'nav-profile-2'}
            className="text-center"
            onClick={() => {
              signOut();
              cleanCookieAuth();
            }}>
            <span>
              {/* <LogoutOutlined /> */}
              {/* <span className="font-weight-normal">ออกจากระบบ</span> */}
              <Text type="danger">{getTranslate('logOut')}</Text>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={ImgAvatar} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};
const mapStateToProps = ({ waan }) => {
  return { waan };
};
NavProfile.propTypes = {
  signOut: PropTypes.func,
  onUserInfoChange: PropTypes.func.isRequired,
  waan: PropTypes.object,
};
export default connect(mapStateToProps, { signOut, onUserInfoChange })(NavProfile);
