// import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getTranslate } from 'components/CustomService';
import { MoreOutlined, FileOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
const { SubMenu } = Menu;

const NFCpathsToCheck = [
  '/apps/nfc/index',
  '/apps/nfc/detail',
  '/apps/nfc/customize-qr',
  '/apps/page-builder',
];

//     {
//         label: 'Navigation One',
//         key: 'mail',
//         icon: <MailOutlined />,
//     },
//     {
//         label: 'Navigation Two',
//         key: 'app',
//         icon: <AppstoreOutlined />,
//         disabled: true,
//     },
//     {
//         label: 'Navigation Three - Submenu',
//         key: 'SubMenu',
//         icon: <SettingOutlined />,
//         children: [
//             {
//                 type: 'group',
//                 label: 'Item 1',
//                 children: [
//                     {
//                         label: 'Option 1',
//                         key: 'setting:1',
//                     },
//                     {
//                         label: 'Option 2',
//                         key: 'setting:2',
//                     },
//                 ],
//             },
//             {
//                 type: 'group',
//                 label: 'Item 2',
//                 children: [
//                     {
//                         label: 'Option 3',
//                         key: 'setting:3',
//                     },
//                     {
//                         label: 'Option 4',
//                         key: 'setting:4',
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         label: (
//             <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
//                 Navigation Four - Link
//             </a>
//         ),
//         key: 'alipay',
//     },
// ];
const QRHorizontal = () => {
  const location = useLocation();
  return (
    <Menu className="Qr-nev-top">
      <Menu.Item
        key={1}
        className={
          NFCpathsToCheck.some((path) => location.pathname.includes(path))
            ? 'hmenu active'
            : 'hmenu'
        }>
        <span>{getTranslate('NFC_QR_Link')}</span>
        <Link to={APP_PREFIX_PATH + '/apps/nfc/index'} />
      </Menu.Item>
      <Menu.Item
        key={2}
        className={location.pathname.includes('/apps/nfc/statistics') ? 'hmenu active' : 'hmenu'}>
        <span>{getTranslate('statistics_report')}</span>
        <Link to={APP_PREFIX_PATH + '/apps/nfc/statistics'} />
      </Menu.Item>
      {/* <Menu.ItemGroup className='ant-menu-item-group-p-0'> */}
      <SubMenu
        popupClassName="top-nav-menu"
        className="qr-submenu"
        icon={<MoreOutlined className="morebtn" />}>
        {/* <Menu.Item key={3}>
                <PieChartOutlined />
                    <span>NFC Tools</span>
                </Menu.Item> */}
        <Menu.Item key={4}>
          <FileOutlined />
          <span>
            <a target="_bank" href={'https://www.waankit.com/blogs/'}>
              {getTranslate('Instruction_manual')}
            </a>
          </span>
        </Menu.Item>
      </SubMenu>
      {/* </Menu.ItemGroup> */}
    </Menu>
  );
};
export default QRHorizontal;
