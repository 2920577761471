import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav';
import SlideBarNav from 'components/layout-components/SlideBarNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import { companiesGet, notificationGet } from 'redux/actions/Waan';
import { Redirect } from 'react-router-dom';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';
import { Layout, Grid } from 'antd';
import { signOut } from 'redux/actions/Auth';
import { appLayoutContext } from 'components/waan-components/use/createContext';
import { useAxiosFetch } from 'components/CustomService';

// import navigationConfig from 'configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import PropTypes from 'prop-types';
import {
  navigationGroup1Nav,
  navigationGroup2Nav,
  navigationGroup3Nav,
  navigationGroup4Nav,
  tabMenuId,
} from 'configs/NavigationConfig';
import { checkUrlMenu, cleanCookieAuth } from 'components/CustomService';
import moment from 'moment-timezone';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  isLogin,
  userInfo,
  companiesGet,
  notificationGet,
  companies,
  waan_loading,
  signOut,
}) => {
  const [refreshPackage, setRefreshPackage] = useState(false);
  const menuId = checkUrlMenu(location, tabMenuId);
  const navMenu =
    menuId === 1
      ? navigationGroup1Nav
      : menuId === 2
      ? navigationGroup2Nav
      : menuId === 3
      ? navigationGroup3Nav
      : menuId === 4
      ? navigationGroup4Nav
      : navigationGroup1Nav;

  const currentRouteInfo = utils.getRouteInfo(navMenu, location.pathname, menuId);
  const { isAdmin = false, admin_rights, timeZone } = userInfo;
  const companie_id = userInfo.companie_id;
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile || menuId == 3) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { data: dataPackage, isLoading: isLoadingPackage } = useAxiosFetch(
    companie_id != null ? `/api/companie/myPackage/${companie_id}` : null,
    refreshPackage,
  );

  useEffect(() => {
    companiesGet();
    notificationGet();
  }, []);

  useEffect(() => {
    moment.tz.setDefault(timeZone);
  }, [timeZone]);

  useEffect(() => {
    if (admin_rights == null && !isAdmin) {
      signOut();
      cleanCookieAuth();
    }
  }, [admin_rights, isAdmin]);

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  if (!isLogin) {
    return <Redirect to={AUTH_PREFIX_PATH} />;
  }

  if (userInfo.companie_id == null && !isAdmin) {
    return <Redirect to={`${AUTH_PREFIX_PATH}/welcome`} />;
  } else if (location.pathname.includes('/app/staff') && !isAdmin) {
    return <Redirect to={`${APP_PREFIX_PATH}/home`} />;
  } else if (!location.pathname.includes('/app/staff') && isAdmin) {
    return <Redirect to={`${APP_PREFIX_PATH}/staff/link`} />;
  }
  if (location.pathname.includes('/app/apps/page-builder/preview')) {
    return (
      <Layout>
        <Layout className="app-container">
          <Layout className="app-layout">
            <div className="">
              <Content>
                <AppViews />
              </Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    );
  }
  const contextValueLayout = {
    dataPackage: dataPackage?.data ?? null,
    isPackageExpire: dataPackage?.data?.is_expire ?? false,
    isLoadingPackage: isLoadingPackage,
    setRefreshPackage,
  };

  return (
    <Layout>
      <appLayoutContext.Provider value={contextValueLayout}>
        <HeaderNav isMobile={isMobile} companies={companies} waan_loading={waan_loading} />
        {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} menuId={menuId} /> : null}
        <Layout className="app-container">
          {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} menuId={menuId} /> : null}
          <Layout className="app-layout" style={getLayoutDirectionGutter()}>
            <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
              <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
              <Content>
                <AppViews />
              </Content>
            </div>
            <Footer />
          </Layout>
        </Layout>
        {isMobile && <MobileNav menuId={menuId} />}
        <SlideBarNav location={location} />
      </appLayoutContext.Provider>
    </Layout>
  );
};

AppLayout.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  direction: PropTypes.string.isRequired,
  isLogin: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  companiesGet: PropTypes.func.isRequired,
  notificationGet: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  waan_loading: PropTypes.bool.isRequired,
  signOut: PropTypes.func,
};

const mapStateToProps = ({ theme, waan }) => {
  const { navCollapsed, navType, locale } = theme;
  const { userInfo, companies, loading } = waan;
  return { navCollapsed, navType, locale, userInfo, companies, waan_loading: loading };
};

export default connect(mapStateToProps, { companiesGet, notificationGet, signOut })(
  React.memo(AppLayout),
);
