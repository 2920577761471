import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';

const renderAvatar = (props) => {
  return (
    <Avatar {...props} className={`ant-avatar-${props.type}`}>
      {props.text}
    </Avatar>
  );
};

export const AvatarStatus = (props) => {
  const {
    className,
    name,
    suffix,
    subTitle,
    id,
    type,
    src,
    icon,
    size,
    shape,
    gap,
    text,
    onNameClick,
    link_to,
    onAvatarClick,
    onDivClick
  } = props;

  const LinkTo = link_to ? (
    <Link className="text-dark" to={link_to}>
      {name}
    </Link>
  ) : (
    name
  );

  var onClickAvatar = null;
  if (onAvatarClick) {
    onClickAvatar = onAvatarClick;
  }

  return (
    <div className={`avatar-status d-flex align-items-center ${className && className}`} onClick={onDivClick}>
      {renderAvatar({ icon, src, type, size, shape, gap, text, onClick: onClickAvatar })}
      <div className="ml-3">
        <div>
          {onNameClick ? (
            <div
              onClick={() => onNameClick({ name, subTitle, src, id })}
              className="avatar-status-name clickable">
              {LinkTo}
            </div>
          ) : (
            <div className="avatar-status-name">{LinkTo}</div>
          )}
          <span>{suffix}</span>
        </div>
        <div className="text-muted avatar-status-subtitle text-left font-size-sm mt-1">{subTitle}</div>
      </div>
    </div>
  );
};

AvatarStatus.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  link_to: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  onNameClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
  onDivClick: PropTypes.func,
  suffix: PropTypes.object,
  subTitle: PropTypes.string,
  id: PropTypes.number,
  icon: PropTypes.node,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shape: PropTypes.string,
  gap: PropTypes.number,
  text: PropTypes.string,
};

export default AvatarStatus;
