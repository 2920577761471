import React from 'react';
import { Typography, Avatar, Card } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const renderAvatar = (props) => {
  const { avatarClass = '', isAdmin = false } = props;
  delete props.avatarClass;
  delete props.isAdmin;
  return (
    <Avatar
      {...props}
      className={`ant-avatar-${props.type} ${avatarClass}`}
      style={{ backgroundColor: isAdmin ? '#dd001d' : '#000000' }}>
      {props.text}
    </Avatar>
  );
};
const { Text } = Typography;

export default function CardStaff({
  onClick: cardOnClick,
  name,
  suffix,
  avatarClass,
  cardClass = '',
  isAdmin = false,
}) {
  return (
    <Card
      className={`ant-card-body-p-2 clickable ${cardClass}`}
      style={{
        backgroundColor: isAdmin ? '#1d3153' : '#dd001d',
      }}
      onClick={cardOnClick}
      bordered={false}>
      <div className={`avatar-status d-flex align-items-center ml-1`}>
        {renderAvatar({
          icon: (
            <SafetyOutlined
              style={{ backgroundColor: isAdmin ? '#dd001d' : '#000000', fontSize: '18px' }}
            />
          ),
          size: 30,
          shape: 'square',
          avatarClass: avatarClass ? avatarClass : '',
          isAdmin: isAdmin,
        })}
        <div className="ml-2">
          <div style={{ lineHeight: 1.2 }}>
            <div className="">
              <Text className={`text-white`}>{name}</Text>
            </div>
            <span className="text-white font-size-sm">{suffix}</span>
          </div>
        </div>
      </div>
    </Card>
  );
}

CardStaff.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  suffix: PropTypes.string,
  avatarClass: PropTypes.string,
  cardClass: PropTypes.string,
  isAdmin: PropTypes.bool,
};

// Set default props
CardStaff.defaultProps = {
  // onClick: PropTypes.func,
  name: '',
  suffix: '',
  avatarClass: '',
  cardClass: '',
  isAdmin: false,
};
