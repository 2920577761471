import { useEffect, useState } from 'react';
import pluginConfig from './CookieConsentConfig';
import 'vanilla-cookieconsent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CookieConsentComponent = ({ locale }) => {
  const [current_lang, setCurrentLang] = useState(locale);

  useEffect(() => {
    /**
     * useEffect is executed twice (React 18+),
     * make sure the plugin is initialized and executed once
     */
    document.documentElement.setAttribute('lang', locale);
    if (!document.getElementById('cc--main') || current_lang != locale) {
      document.getElementById('cc--main')?.remove();
      window.CC = window.initCookieConsent();
      window.CC.run({ ...pluginConfig, current_lang: locale });
      setCurrentLang(locale);
    }
  }, [locale]);

  return null;
};

// export default CookieConsentComponent;

CookieConsentComponent.propTypes = {
  locale: PropTypes.string,
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps, {})(CookieConsentComponent);
