import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  Card,
  Drawer,
  Typography,
  Table,
  Select,
  Form,
  Input,
  Button,
  Radio,
  Divider,
  Popconfirm,
  Spin,
  message,
  Switch,
  Space,
} from 'antd';
import PropTypes from 'prop-types';
import Flex from 'components/shared-components/Flex';
import {
  getplaceholder,
  customLabel,
  getMessage,
  getTranslate,
  useAxiosFetch,
  GetUserInfo,
  newLogActivity,
} from 'components/CustomService';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import qs from 'qs';
import InputAmount from 'components/waan-components/InputAmount';

const { Option } = Select;
const { Text } = Typography;
const { TextArea, Search } = Input;
const queryOrder = qs.stringify(
  {
    filters: {
      is_deleted: { $eq: 0 },
    },
    populate: {
      companie: {
        fields: ['id', 'name', 'uid'],
      },
    },
  },
  {
    encodeValuesOnly: true, // prettify URL
  },
);
function ChangePackage(props) {
  const { visible, setVisible, windowSize, companie_id, setRefresh, orderId } = props;
  let history = useHistory();
  const userInfo = GetUserInfo();
  const { isAdmin = false, admin_rights } = userInfo;
  const [priceType, setPriceType] = useState({});
  const [step, setStep] = useState(1);
  const [selectPackage, setSelectPackage] = useState(null);
  const [companieId, setCompanieId] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companie, setCompanie] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);

  const [form] = Form.useForm();
  // const [refresh, setRefresh] = useState(false);
  const { data: dataPackage, isLoading: isLoadingPackage } = useAxiosFetch(
    companieId != null && visible && step == 1 ? `/api/companie/packagesAll` : null,
    false,
  );

  const { data: dataOrder } = useAxiosFetch(
    isAdmin && orderId && visible && step == 2
      ? `/api/package-order-histories/${orderId}?${queryOrder}`
      : null,
    false,
  );

  useEffect(() => {
    if (dataPackage) {
      setPriceType(() => {
        const _priceType = {};
        (dataPackage ?? []).forEach((p) => {
          _priceType[p.Package_ID] = 'Price_Year';
        });
        return { ..._priceType };
      });
    }
  }, [dataPackage]);

  useEffect(() => {
    if (!visible) {
      setStep(1);
      setSelectPackage(null);
      form.resetFields();
      form.setFieldsValue({
        address_type: 1,
        payment_method: 1,
        send_email_new_order: false,
      });
      setSendEmail(false);
      setSearchBy('');
      setCompanieId(null);
      setCompanie(null);
      setCompanies([]);
    } else {
      if (companie_id) {
        setCompanieId(companie_id);
      } else {
        if (isAdmin) {
          if (orderId) {
            setStep(2);
          } else {
            setStep(0);
          }
          form.setFieldsValue({
            send_email_new_order: false,
          });
          setSendEmail(true);
        } else {
          // setVisible(false);
        }
      }
    }
  }, [visible]);

  useEffect(() => {
    if (isAdmin && dataOrder?.data) {
      const Package_ID = dataOrder?.data.attributes.Package_ID;
      const price_type = dataOrder?.data.attributes.price_type;
      const getCompanie = dataOrder?.data.attributes.companie.data;
      const attributes = dataOrder?.data.attributes;
      if (getCompanie) {
        form.setFieldsValue({
          address_type: attributes.address_type,
          delivery_address: attributes.delivery_address,
          phone_contact: attributes.phone_contact,
          payment_method: attributes.payment_method,
          company_name: attributes.company_name,
          company_address: attributes.company_address,
          branch: attributes.branch,
          taxpayer_identification_number: attributes.taxpayer_identification_number,
          contact_name: attributes.contact_name,

          name: attributes.name,
          address: attributes.address,
          id_card: attributes.id_card,

          Package_ID: Package_ID,
          price_type: price_type,
          price: attributes.price,
          package_name: attributes.package_name,
        });
        setPriceType((prevState) => {
          prevState[Package_ID] = objPriceType[price_type];
          return { ...prevState };
        });
        setSelectPackage(Package_ID);
        setCompanie(getCompanie);
        setCompanieId(getCompanie.id);
      }
    }
  }, [dataOrder]);

  const handleChange = useCallback((value, Package_ID) => {
    setPriceType((prevState) => {
      prevState[Package_ID] = value;
      return { ...prevState };
    });
  }, []);

  const data = useMemo(() => {
    const listPackage = [];
    (dataPackage ?? []).forEach((p) => {
      if (p.Is_Private) {
        return;
      }
      listPackage.push({
        key: p.Package_ID,
        ...p,
        data: [
          {
            key: 0,
            title: getTranslate('NFC_QR_Code'),
            description: <ViewDesPackage value={p?.Q_Link} unit={getTranslate('link')} />,
          },
          {
            key: 1,
            title: getTranslate('Page_Builder'),
            description: <ViewDesPackage value={p?.Q_Page} unit={getTranslate('page1')} />,
          },
          {
            key: 2,
            title: getTranslate('Storage'),
            description: <ViewDesPackage value={p?.Q_Storage} unit={'MB'} />,
          },
          {
            key: 3,
            title: getTranslate('Traffic'),
            description: <ViewDesPackage value={p?.Q_Traffic} unit={getTranslate('time1')} />,
          },
          {
            key: 6,
            title: getTranslate('questionnaire'),
            description: (
              <ViewDesPackage value={p?.Q_Questionnaire} unit={getTranslate('respond')} />
            ),
          },
          {
            key: 4,
            title: getTranslate('Staff_Admin'),
            description: <ViewDesPackage value={p?.Q_Staff} unit={'Account'} />,
          },
          {
            key: 5,
            title: (
              <Select
                className="text-left w-100"
                value={priceType[p.Package_ID]}
                style={{ width: 120 }}
                onChange={(value) => handleChange(value, p.Package_ID)}>
                <Option value="Price_Year">{getTranslate('1_year')}</Option>
                <Option value="Price_6_Month">{getTranslate('6_months')}</Option>
                <Option value="Price_Month">{getTranslate('1_month')}</Option>
              </Select>
            ),
            description: (
              <div>
                {priceType[p.Package_ID] === 'Price_Year' ? (
                  <>
                    <Text strong>
                      ฿{(p['Price_Year'] * 12).toLocaleString(undefined)} / {getTranslate('year')}
                    </Text>{' '}
                    <br />
                    <Text>
                      ฿
                      {p['Price_Year'].toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      / {getTranslate('month')}
                    </Text>{' '}
                    {/* <Text type="danger">
                      ( Save $
                      {calculatePrice(p['Price_Year'], p['Price_Month'], 12).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        },
                      )}
                      % )
                    </Text> */}
                  </>
                ) : priceType[p.Package_ID] === 'Price_6_Month' ? (
                  <>
                    <Text strong>
                      ฿{(p['Price_6_Month'] * 6).toLocaleString(undefined)} /{' '}
                      {getTranslate('6_months')}
                    </Text>{' '}
                    <br />
                    <Text>
                      ฿
                      {p['Price_6_Month'].toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      / {getTranslate('month')}
                    </Text>{' '}
                    {/* <Text type="danger">
                      ( Save $
                      {calculatePrice(p['Price_6_Month'], p['Price_Month'], 6).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        },
                      )}
                      % )
                    </Text> */}
                  </>
                ) : priceType[p.Package_ID] === 'Price_Month' ? (
                  <>
                    <Text strong>
                      ฿{p['Price_Month'].toLocaleString(undefined)} / {getTranslate('month')}
                    </Text>
                  </>
                ) : null}
              </div>
            ),
          },
        ],
      });
    });
    return listPackage;
  }, [dataPackage, priceType]);

  const columns = useMemo(() => {
    return [
      {
        key: 'title',
        dataIndex: 'title',
        className: 'text-right vertical-align-top',
        width: 190,
      },
      {
        key: 'description',
        dataIndex: 'description',
      },
    ];
  }, []);

  const onFinish = useCallback(
    async (values) => {
      if (!isAdmin && ![0, 1].includes(admin_rights)) return;
      if (!companieId) return;
      setSubmitLoading(true);
      values.send_email_new_order = isAdmin ? sendEmail : false;

      const packageDes = dataPackage.find((f) => f.Package_ID === selectPackage);
      const price_type = priceType[selectPackage];
      if (isAdmin && orderId) {
        delete values.discount_price;
        await axios
          .put(process.env.REACT_APP_API + `/api/package-order-histories/${orderId}`, {
            data: {
              ...values,
              // Package_ID: selectPackage,
              // price_type: objPriceType[price_type],
              // price: packageDes[price_type],
              // log_package: packageDes,
              // package_name: packageDes['Name'],
              companie: companieId,
              // created_by_userId: userInfo.autoID,
              updated_by_userId: userInfo.autoID,
            },
          })
          .then(async () => {
            await newLogActivity({
              message: `{{Edit_Order}} - ${dataOrder?.data?.attributes?.order_no ?? ''}`,
              status: true,
              action_id: 2,
              category_id: 1,
              companie_name: companie?.attributes?.name ?? null,
              companie_uid: companie?.attributes?.uid ?? null,
              created_by_userId: userInfo.autoID,
            });
            message.success(getTranslate('order_success'));
            setRefresh((refresh) => {
              return !refresh;
            });
          })
          .catch(async () => {
            await newLogActivity({
              message: `{{Edit_Order}} - ${dataOrder?.data?.attributes?.order_no ?? ''}`,
              status: false,
              action_id: 2,
              category_id: 1,
              companie_name: companie?.attributes?.name ?? null,
              companie_uid: companie?.attributes?.uid ?? null,
              created_by_userId: userInfo.autoID,
            });
            message.error(getTranslate('order_failed'));
          });
      } else {
        let urlPost = process.env.REACT_APP_API + `/api/package-order-histories`;
        if (!isAdmin) {
          values.discount_price = 0;
          values.email_to_userId = userInfo.autoID;
          urlPost = process.env.REACT_APP_API + `/api/package-order-history/companie`;
        }
        await axios
          .post(urlPost, {
            data: {
              ...values,
              Package_ID: selectPackage,
              price_type: objPriceType[price_type],
              price: packageDes[price_type],
              log_package: packageDes,
              package_name: packageDes['Name'],
              // discount_price: 0,
              companie: companieId,
              created_by_userId: userInfo.autoID,
              updated_by_userId: userInfo.autoID,
            },
          })
          .then(async (res) => {
            message.success(getTranslate('order_success'));
            if (isAdmin) {
              if (sendEmail) {
                await send_mail_confirm_order(res?.data?.data);
              }
              await newLogActivity({
                message: `{{add_to_order2}} - ${res?.data?.data?.attributes?.order_no ?? ''}`,
                status: true,
                action_id: 1,
                category_id: 1,
                companie_name: companie?.attributes?.name ?? null,
                companie_uid: companie?.attributes?.uid ?? null,
                created_by_userId: userInfo.autoID,
              });
              if (setRefresh) {
                setRefresh((refresh) => {
                  return !refresh;
                });
              }
            } else {
              await send_mail_confirm_order(res?.data?.data);
              history.push(`/app/profile/billing`);
            }
          })
          .catch(async () => {
            message.error(getTranslate('order_failed'));
            await newLogActivity({
              message: `{{add_to_order2}}`,
              status: false,
              action_id: 1,
              category_id: 1,
              companie_name: companie?.attributes?.name ?? null,
              companie_uid: companie?.attributes?.uid ?? null,
              created_by_userId: userInfo.autoID,
            });
          });
      }

      setSubmitLoading(false);
      setVisible(false);
    },
    [
      form,
      selectPackage,
      objPriceType,
      companieId,
      userInfo,
      admin_rights,
      isAdmin,
      dataOrder,
      companie,
    ],
  );

  const send_mail_confirm_order = useCallback(
    async (value) => {
      if (value?.id) {
        let companie_name = userInfo.companie_name;
        if (isAdmin && companie) {
          companie_name = companie.attributes.name;
        }
        await axios
          .post(process.env.REACT_APP_API + `/api/coreemail/send_mail_confirm_order`, {
            data: {
              email_to_userId: isAdmin ? undefined : userInfo.autoID,
              order_no: value.attributes.order_no,
              order_date: value.attributes.createdAt,
              companie_id: companieId,
              companie_name: companie_name,
            },
          })
          .catch(() => {});
      }
    },
    [userInfo, companieId, isAdmin, companie],
  );

  const onSearchBy = useCallback(
    async (val) => {
      const query = qs.stringify(
        {
          filters: {
            is_deleted: {
              $eq: 0,
            },
            name: { $containsi: val },
          },
          fields: ['id', 'name', 'uid'],
          pagination: {
            page: 1,
            pageSize: 20,
          },
          sort: { id: 'desc' },
        },
        {
          encodeValuesOnly: true, // prettify URL
        },
      );
      await axios
        .get(process.env.REACT_APP_API + `/api/companies?${query}`)
        .then((res) => {
          setCompanies(res?.data?.data ?? []);
        })
        .catch(() => {
          setCompanies([]);
        });
      setSearchBy('');
      setCompanieId(null);
      setCompanie(null);
    },
    [searchBy],
  );

  const choose_a_company = useMemo(() => {
    if (step !== 0) return;
    return (
      <>
        <Card bordered={false} className="m-2">
          <Text className="font-weight-semibold">{getTranslate('choose_a_company')}</Text>
          <div className="w-100 Input-group-search Group-Search-Tag mr-md-3 mb-3 mt-2">
            <Search
              placeholder={getTranslate('find_a_company')}
              enterButton={
                <React.Fragment>
                  <SearchOutlined className="mr-1" />
                  {getTranslate('search')}
                </React.Fragment>
              }
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              onSearch={onSearchBy}
            />
          </div>
          <br />
          <Text strong>{getTranslate('search_results')}</Text>
          <Table
            className="mt-2"
            columns={[
              {
                title: '',
                render: (_, record) => (
                  <>
                    <Text className="font-weight-semibold">{record.attributes.name}</Text>
                    <br />
                    <Text type="secondary">{record.attributes.uid}</Text>
                  </>
                ),
              },
              {
                title: '',
                width: 50,
                render: (_, record) => (
                  <a
                    className={`${companieId === record.id ? 'd-none' : ''}`}
                    onClick={() => {
                      setCompanieId(record.id);
                      setCompanie(record);
                    }}>
                    {getTranslate('choose')}
                  </a>
                ),
              },
            ]}
            rowKey={'id'}
            dataSource={companies}
            size="small"
            rowClassName={(record) => record.id === companieId && 'bg-gray-lighter'}
            pagination={false}
            showHeader={false}
            scroll={{ y: 400 }}
          />

          <br />
          <div className="mt-2">
            <Form.Item noStyle name="send_email_new_order" valuePropName="checked">
              <Switch onChange={(checked) => setSendEmail(checked)}></Switch>
            </Form.Item>

            <Text className="ml-2">
              {getTranslate('send_email')} <Text strong>{getTranslate('new_order_list')}</Text>{' '}
              {getTranslate('from_the_system')}
            </Text>
          </div>

          <br />
          <br />
          <br />
        </Card>
        <div className="float-right mb-5 mt-3">
          <Button
            disabled={!companieId}
            onClick={() => {
              if (companieId) {
                setStep(1);
              }
            }}
            className="btn-primary"
            type="primary">
            {getTranslate('choose')}
          </Button>
        </div>
      </>
    );
  }, [visible, step, searchBy, companies, companieId, companie]);

  const choose_a_package = useMemo(() => {
    if (step !== 1) return;
    return (
      <>
        {isAdmin && (
          <div className="m-3">
            <Space>
              <Text strong>{companie?.attributes?.name}</Text>{' '}
              <Text type="secondary">{companie?.attributes?.uid}</Text>
            </Space>
          </div>
        )}
        <Spin spinning={isLoadingPackage}>
          {data.map((p, index) => (
            <Card
              key={`package-${p.key}`}
              bordered={false}
              className={`${index > 0 && 'mt-4'} m-3`}>
              <Text strong>{p.Name}</Text>
              <Table
                rowKey={'key'}
                className="mt-0 table-border-0"
                bordered
                pagination={false}
                showHeader={false}
                columns={columns}
                dataSource={p.data}
              />
              <Flex
                className="mt-3"
                alignItems={'center'}
                justifyContent={'between'}
                flexDirection={'row'}>
                <div>
                  <Text className="font-size-sm text-gray-light">
                    {getTranslate('prices_do_not_include_vat')}
                  </Text>
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectPackage(p.Package_ID);
                      setStep(2);
                    }}>
                    {getTranslate('choose')}
                  </Button>
                </div>
              </Flex>
            </Card>
          ))}

          <div className="ml-4 mt-3">
            <a href="https://www.waankit.com/pricing/" target="_bank">
              {getTranslate('see_detailed_comparison')}
            </a>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </Spin>
      </>
    );
  }, [visible, priceType, step, dataPackage, isLoadingPackage]);

  const billing_information = useMemo(() => {
    if (!selectPackage) return;
    if (step !== 2) return;
    let packageDes = dataPackage.find((f) => f.Package_ID === selectPackage);
    if (isAdmin && orderId && !dataOrder?.data) return;
    if (isAdmin && orderId && dataOrder?.data) {
      packageDes = dataOrder?.data.attributes.log_package;
    }
    if (!packageDes || typeof packageDes != 'object') return;
    if (packageDes[priceType[selectPackage]] == null) return;

    return (
      <>
        {isAdmin && (
          <div className="m-3">
            <Space>
              <Text strong>{companie?.attributes?.name}</Text>{' '}
              <Text type="secondary">{companie?.attributes?.uid}</Text>
            </Space>
          </div>
        )}
        <Card bordered={false} className="m-2">
          <Text strong>
            {getTranslate('package')} : {packageDes['Name']}
          </Text>
          <br />
          {priceType[selectPackage] === 'Price_Year' ? (
            <>
              <Text>
                ฿{(packageDes['Price_Year'] * 12).toLocaleString(undefined)} /{' '}
                {getTranslate('year')}
              </Text>{' '}
              <br />
            </>
          ) : priceType[selectPackage] === 'Price_6_Month' ? (
            <>
              <Text>
                ฿{(packageDes['Price_6_Month'] * 6).toLocaleString(undefined)} /{' '}
                {getTranslate('6_months')}
              </Text>{' '}
              <br />
            </>
          ) : priceType[selectPackage] === 'Price_Month' ? (
            <>
              <Text>
                ฿{packageDes['Price_Month'].toLocaleString(undefined)} / {getTranslate('month')}
              </Text>
            </>
          ) : null}
          {isAdmin && !orderId && (
            <>
              <Divider className="my-2" />
              <Form.Item name="discount_price" className="mb-2" label={getTranslate('discount')}>
                <InputAmount
                  className="w-100"
                  disabled={submitLoading}
                  addonAfter={getTranslate('baht')}
                  placeholder={getTranslate('discount')}
                />
              </Form.Item>
            </>
          )}
        </Card>
        <Card bordered={false} className="m-2">
          <Text className="font-weight-semibold">{getTranslate('type')}</Text>

          <Form.Item name="address_type" className="mb-2">
            <Radio.Group
              onChange={(e) => {
                form.resetFields();
                form.setFieldsValue({
                  address_type: e.target.value,
                  payment_method: 1,
                });
              }}>
              <Radio value={1}>{getTranslate('juristic_person')}</Radio>
              <Radio value={2}>{getTranslate('individual')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.address_type !== currentValues.address_type
            }>
            {({ getFieldValue }) => {
              return getFieldValue('address_type') === 1 ? (
                <Form.Item
                  className="mt-2 mb-3"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: getplaceholder('company_name'),
                    },
                  ]}
                  name="company_name"
                  label={customLabel('company_name', true)}>
                  <Input placeholder={getplaceholder('company_name')} maxLength={255} />
                </Form.Item>
              ) : (
                <Form.Item
                  className="mt-2 mb-3"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: getplaceholder('name_surname'),
                    },
                  ]}
                  name="name"
                  label={customLabel('name_surname', true)}>
                  <Input placeholder={getplaceholder('name_surname')} maxLength={255} />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.address_type !== currentValues.address_type
            }>
            {({ getFieldValue }) => {
              return getFieldValue('address_type') === 1 ? (
                <Form.Item
                  className="mt-2 mb-3"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: getplaceholder('company_address'),
                    },
                  ]}
                  name={'company_address'}
                  label={customLabel('company_address', true)}>
                  <TextArea maxLength={255} />
                </Form.Item>
              ) : (
                <Form.Item
                  className="mt-2 mb-3"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: getplaceholder('address'),
                    },
                  ]}
                  name={'address'}
                  label={customLabel('address', true)}>
                  <TextArea maxLength={255} />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            className="mt-2 mb-3"
            required={false}
            rules={[
              {
                required: true,
                message: getplaceholder('delivery_address2'),
              },
            ]}
            name="delivery_address"
            label={customLabel('delivery_address2', true)}>
            <TextArea maxLength={255} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.address_type !== currentValues.address_type
            }>
            {({ getFieldValue }) => {
              return getFieldValue('address_type') === 1 ? (
                <Form.Item
                  required={false}
                  rules={[{ required: true, min: 0, message: getMessage('branch') }]}
                  name="branch"
                  label={customLabel('branch', true)}
                  className="d-inline-block w-50 pr-3 mb-3">
                  <Input placeholder={getplaceholder('branch')} maxLength={255} />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.address_type !== currentValues.address_type
            }>
            {({ getFieldValue }) => {
              return getFieldValue('address_type') === 1 ? (
                <Form.Item
                  required={false}
                  rules={[
                    {
                      required: true,
                      min: 0,
                      message: getMessage('taxpayer_identification_number'),
                    },
                  ]}
                  name="taxpayer_identification_number"
                  label={customLabel('taxpayer_identification_number', true)}
                  className="d-inline-block w-50 mb-3">
                  <Input
                    placeholder={getMessage('taxpayer_identification_number')}
                    maxLength={255}
                  />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.address_type !== currentValues.address_type
            }>
            {({ getFieldValue }) => {
              return getFieldValue('address_type') === 2 ? (
                <Form.Item
                  rules={[
                    { required: true, min: 13, max: 13, message: getMessage('ID_card_number') },
                  ]}
                  name="id_card"
                  required={false}
                  label={customLabel('ID_card_number', true)}
                  className="">
                  <Input placeholder={getMessage('ID_card_number')} maxLength={255} />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Divider className="my-0" />
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.address_type !== currentValues.address_type
            }>
            {({ getFieldValue }) => {
              return getFieldValue('address_type') === 1 ? (
                <Form.Item
                  className="mt-3 mb-3"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: getplaceholder('name_surname_contact_person'),
                    },
                  ]}
                  name="contact_name"
                  label={customLabel('name_surname_contact_person', true)}>
                  <Input
                    placeholder={getplaceholder('name_surname_contact_person')}
                    maxLength={255}
                  />
                </Form.Item>
              ) : (
                <div className="mt-3" />
              );
            }}
          </Form.Item>
          <Form.Item
            className="mb-3"
            rules={[
              {
                min: 10,
                max: 10,
                message: getplaceholder('phone_number_contact_person'),
              },
            ]}
            name="phone_contact"
            label={getTranslate('phone_number_contact_person')}>
            <Input placeholder={getplaceholder('phone_number_contact_person')} maxLength={255} />
          </Form.Item>
          <Divider className="my-0" />
          <Form.Item
            name="payment_method"
            className="mt-3 mb-3"
            required={false}
            label={getTranslate('payment_method')}
            rules={[{ required: true, message: getMessage('payment_method') }]}>
            <Select>
              <Option value={1}>{getTranslate('bank_transfer2')}</Option>
              {/* <Option value={2}>ตัดบัตรเครดิต</Option> */}
            </Select>
          </Form.Item>
          <div className="text-right">
            <Popconfirm
              placement="topRight"
              title={getTranslate('order_confirmation')}
              onConfirm={() => {
                form.submit();
              }}
              onCancel={null}>
              <Button loading={submitLoading} className="btn-primary" type="primary">
                {isAdmin && orderId ? getTranslate('record') : getTranslate('order2')}
              </Button>
            </Popconfirm>
          </div>
        </Card>
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }, [visible, priceType, step, form, dataPackage, selectPackage, submitLoading]);

  // เพิ่มรายการสั่งซื้อ
  return useMemo(() => {
    return (
      <Drawer
        forceRender
        width={windowSize.width > 520 ? 520 : '100%'}
        title={
          step === 0
            ? getTranslate('add_to_order')
            : step === 1
            ? isAdmin
              ? getTranslate('add_to_order')
              : getTranslate('change_package')
            : step === 2
            ? isAdmin && orderId
              ? getTranslate('edit_order2')
              : getTranslate('billing_information')
            : ''
        }
        placement="right"
        extra={
          step === 1 && (
            <a href="https://www.waankit.com/pricing/" target="_bank">
              {getTranslate('see_detailed_comparison')}
            </a>
          )
        }
        onClose={() => setVisible(false)}
        visible={visible && (isAdmin || (!isAdmin && [0, 1].includes(admin_rights)))}>
        <Form
          layout={'vertical'}
          form={form}
          initialValues={{ layout: 'vertical', remember: true }}
          onFinish={onFinish}>
          {choose_a_company}
          {choose_a_package}
          {billing_information}
        </Form>
      </Drawer>
    );
  }, [
    visible,
    priceType,
    step,
    form,
    dataPackage,
    isLoadingPackage,
    selectPackage,
    searchBy,
    companies,
    companieId,
    companie,
    isAdmin,
    admin_rights,
  ]);
}
export default ChangePackage;

ChangePackage.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  windowSize: PropTypes.object,
  companie_id: PropTypes.number,
};
ChangePackage.defaultProps = {
  visible: false,
};

// const calculatePrice = (totalPrice, priceMonth, divide_month = 12) => {
//   if (totalPrice == 0) {
//     return 0;
//   }
//   const decrease = ((priceMonth * divide_month - totalPrice) / (priceMonth * divide_month)) * 100;
//   return isNaN(decrease) ? 0 : decrease;
// };

const ViewDesPackage = (props) => {
  const { value, unit } = props;
  return useMemo(() => {
    return (
      <>
        {value === 0 ? (
          <Text strong>{getTranslate('no_limit')}</Text>
        ) : (
          <Text>
            <Text strong>{(value ?? 0).toLocaleString(undefined)}</Text> {unit}
          </Text>
        )}
      </>
    );
  }, [props]);
};
ViewDesPackage.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
};
const objPriceType = {
  1: 'Price_Month',
  2: 'Price_6_Month',
  3: 'Price_Year',
  Price_Month: 1,
  Price_6_Month: 2,
  Price_Year: 3,
};
