import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const isDevelopment = process.env.REACT_APP_ENV === 'development';
export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <Route path={`${APP_PREFIX_PATH}/demo-page`} component={lazy(() => import(`./demo`))} /> */}
        {isDevelopment && (
          <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./apps/home`))} />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/setting-shop`}
          component={lazy(() => import(`./settings`))}
        />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
        <Route path={`${APP_PREFIX_PATH}/staff`} component={lazy(() => import(`./staff`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/apps`} />
      </Switch>
    </Suspense>
  );
};
// <Redirect exact
export default React.memo(AppViews);
