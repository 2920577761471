import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Grid, Divider } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import {
  navigationGroup1Nav,
  navigationGroup2Nav,
  navigationGroup3Nav,
  navigationGroup4Nav,
  GroupStaffNavTree,
} from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import { onVisibleChangePackage } from 'redux/actions/Waan';
import { SmileOutlined, ControlOutlined, QrcodeOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { getTranslate, useWindowSize } from 'components/CustomService';
import CardStaff from 'components/waan-components/staff/CardStaff';
import ChangePackage from 'components/waan-components/drawer/profile/ChangePackage';

// const { Text } = Typography;
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    onVisibleChangePackage,
    navCollapsed,
    menuId,
    navMenu,
    waan,
  } = props;
  const userInfo = waan.userInfo;
  const companie_id = userInfo.companie_id;
  const visibleChangePackage = waan.visibleChangePackage;
  const { isAdmin = false } = userInfo;

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const windowSize = useWindowSize();
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const classMenuAvatar = 'line-height-1-2 menu-icon';

  return (
    <React.Fragment>
      {!isAdmin ? (
        <div className="slidebar-title-blue-waan ml-4 my-3">
          {menuId == 1 && (
            <AvatarStatus
              className={classMenuAvatar}
              shape="square"
              size={34}
              icon={<SmileOutlined className="font-size-19" />}
              name={!navCollapsed && getTranslate('Waan_Point')}
              subTitle={!navCollapsed && getTranslate('Member_point_accumulation_system')}
            />
          )}
          {menuId == 2 && (
            <AvatarStatus
              className={classMenuAvatar}
              shape="square"
              size={34}
              icon={<ControlOutlined className="font-size-19" />}
              name={!navCollapsed && getTranslate('setting1')}
              subTitle={!navCollapsed && getTranslate('Set_up_profiles_and_packages')}
            />
          )}
          {menuId == 3 && (
            <AvatarStatus
              className={classMenuAvatar}
              shape="square"
              size={34}
              icon={<QrcodeOutlined className="font-size-19" />}
              name={!navCollapsed && getTranslate('Waan_Tid')}
              subTitle={!navCollapsed && getTranslate('Create_NFC_QR_Code')}
            />
          )}
        </div>
      ) : (
        <div className="slidebar-title-blue-waan mx-2 my-3">
          <CardStaff
            onClick={() => null}
            avatarClass={'ml-1'}
            cardClass={'mt-3'}
            name={!navCollapsed && 'CONSOLE ADMIN'}
            suffix={!navCollapsed && 'Staff Waando'}
          />
        </div>
      )}
      <Divider className="my-0" />
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
        mode="inline"
        style={{
          // height: '100%',
          borderRight: 0,
        }}
        selectedKeys={routeInfo ? [routeInfo?.key] : []}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? 'hide-group-title' : ''}>
        {navMenu.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              className={menu.title == '' ? 'ant-menu-item-group-p-0' : ''}
              key={menu.key}
              title={menu.title == '' ? '' : setLocale(localization, menu.title)}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}>
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                        <span>{setLocale(localization, subMenuSecond.title)}</span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>
                ),
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)} </span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item>
          ),
        )}
      </Menu>
      {menuId == 3 && (
        <ChangePackage
          companie_id={companie_id}
          visible={visibleChangePackage}
          setVisible={onVisibleChangePackage}
          windowSize={windowSize}
        />
      )}
      {/* {menuId == 3 && (
        <>
          <div className="slide-bar-package-box" style={{display : "none"}}>
            <Flex
              className="ml-3 mr-2 mb-2 mt-3"
              alignItems={'start'}
              justifyContent={'between'}
              flexDirection={'row'}>
              <Text className="font-size-sm title-package-box">
                
                <Badge status="success" />
                <Text>{getPackage?.Name}</Text>
              </Text>
            </Flex>
            <Spin spinning={isLoadingPackage}>
              <Card className="ml-2 mr-0 p-3 ant-card-body-p-0">
                <Flex
                  className="mt-1 line_text"
                  alignItems={'start'}
                  justifyContent={'between'}
                  flexDirection={'row'}>
                  <Text>{getTranslate('usage')}</Text>
                  <a href="/app/profile/package">{getTranslate('see_all')}</a>
                </Flex>
                <Divider className="my-2" />
                <Flex
                  className="mt-2 line_text"
                  alignItems={'start'}
                  justifyContent={'between'}
                  flexDirection={'row'}>
                  <Text>{getTranslate('link')}</Text>
                  <Text>
                    <span className="pf">{getPackage?.U_Link}</span> /{' '}
                    <span className="pb">{getPackage?.Q_Link}</span>
                  </Text>
                </Flex>
                <Progress
                  percent={getPackage?.P_Link}
                  showInfo={false}
                  status={getPackage?.P_Link >= 80 ? 'exception' : ''}
                />
                <Flex
                  className="mt-2 line_text"
                  alignItems={'start'}
                  justifyContent={'between'}
                  flexDirection={'row'}>
                  <Text>{getTranslate('page1')}</Text>
                  <Text>
                    <span className="pf">{getPackage?.U_Page}</span> /{' '}
                    <span className="pb">{getPackage?.Q_Page}</span>
                  </Text>
                </Flex>
                <Progress
                  percent={getPackage?.P_Page}
                  showInfo={false}
                  status={getPackage?.P_Page >= 80 ? 'exception' : ''}
                />
                <Divider className="my-2" />
                <div className="text-right  line_text">
                  <UseRights>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        onVisibleChangePackage(true);
                      }}>
                      {getTranslate('adjust_package')}
                    </a>
                  </UseRights>
                </div>
              </Card>
            </Spin>
            <ChangePackage
              companie_id={companie_id}
              visible={visibleChangePackage}
              setVisible={onVisibleChangePackage}
              windowSize={windowSize}
            />
          </div>
        </>
      )} */}
    </React.Fragment>
  );
};

SideNavContent.propTypes = {
  sideNavTheme: PropTypes.string,
  routeInfo: PropTypes.object,
  hideGroupTitle: PropTypes.bool,
  localization: PropTypes.bool,
  onMobileNavToggle: PropTypes.func,
  onVisibleChangePackage: PropTypes.func,
  navCollapsed: PropTypes.bool,
  menuId: PropTypes.number,
  navMenu: PropTypes.array,
  waan: PropTypes.object,
};

const TopNavContent = (props) => {
  const { topNavColor, localization, navMenu } = props;

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navMenu.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }>
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}>
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

TopNavContent.propTypes = {
  topNavColor: PropTypes.string,
  localization: PropTypes.bool,
  navMenu: PropTypes.array,
  waan: PropTypes.object,
};

const MenuContent = (props) => {
  const { menuId } = props;
  const userInfo = props.waan.userInfo;
  const { isAdmin = false } = userInfo;

  let navMenu = [];
  if (isAdmin) {
    navMenu = GroupStaffNavTree;
  } else {
    navMenu =
      menuId === 1
        ? navigationGroup1Nav
        : menuId === 2
        ? navigationGroup2Nav
        : menuId === 3
        ? navigationGroup3Nav
        : menuId === 4
        ? navigationGroup4Nav
        : navigationGroup1Nav;
  }

  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} menuId={menuId} navMenu={navMenu} />
  ) : (
    <TopNavContent {...props} menuId={menuId} navMenu={navMenu} />
  );
};

MenuContent.propTypes = {
  locale: PropTypes.string,
  type: PropTypes.string,
  waan: PropTypes.object,
  menuId: PropTypes.number,
};

const mapStateToProps = ({ theme, waan }) => {
  const { sideNavTheme, topNavColor, locale } = theme;
  return { sideNavTheme, topNavColor, waan, locale };
};

export default connect(mapStateToProps, { onMobileNavToggle, onVisibleChangePackage })(MenuContent);

// const calculatePercent = (use, quantity) => {
//   return parseInt(((use / quantity) * 100).toFixed());
// };
