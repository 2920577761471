import React, { useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import { MailOutlined, HistoryOutlined } from '@ant-design/icons';
import {
  Drawer,
  Row,
  Col,
  Divider,
  Space,
  Typography,
  Avatar,
  Popconfirm,
  message,
  Switch,
  Button,
  Select,
  Form,
} from 'antd';
import {
  dateFormat,
  getTranslate,
  confirmActionText,
  GetUserInfo,
  getplaceholder,
} from 'components/CustomService';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { staffListContext } from 'components/waan-components/use/createContext';
import UseRights from 'components/waan-components/use/UseRights';
import { defaultAdminRights, getTextRights } from 'views/app-views/settings/staffList/staff-list';

const { Text } = Typography;
const { Option } = Select;
export default function ViewStaff() {
  const userInfo = GetUserInfo();
  const companie_id = userInfo.companie_id;
  const {
    visibleView: visible,
    setVisibleView: setVisible,
    refresh,
    setRefresh,
    dataEdit,
    getCaretakerName,
  } = useContext(staffListContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      enable: dataEdit?.attributes.enable === true,
      admin_rights: dataEdit?.attributes.admin_rights,
    });
  }, [visible]);

  async function saveDelete(record) {
    setSubmitLoading(true);
    const invite_user_id = record.attributes.invite_user_id;
    await axios
      .put(process.env.REACT_APP_API + `/api/staff-admin/companie/${record.id}`, {
        data: {
          firstname: record.attributes.firstname,
          lastname: record.attributes.lastname,
          is_deleted: true,
          updated_by_userId: userInfo.autoID,
          companie: companie_id,
        },
      })
      .then(async () => {
        if (invite_user_id) {
          message.success(getTranslate('deleteSuccess'));
        } else {
          message.success(getTranslate('deleteSuccess'));
        }
      })
      .catch(() => {
        message.error(getTranslate('savedFail'));
      });
    setSubmitLoading(false);
    setVisible(false);
    setRefresh(!refresh);
  }

  const getviewData = dataEdit != null ? getCaretakerName(dataEdit, true) : null;

  const onFinish = useCallback(
    async (values) => {
      setSubmitLoading(true);
      await axios
        .put(process.env.REACT_APP_API + `/api/staff-admin/companie/${dataEdit.id}`, {
          data: {
            ...values,
            firstname: dataEdit.attributes.firstname,
            lastname: dataEdit.attributes.lastname,
            updated_by_userId: userInfo.autoID,
            companie: companie_id,
          },
        })
        .then(async () => {
          message.success(getTranslate('savedSuccess'));
          setSubmitLoading(false);
        })
        .catch(() => {
          message.error(getTranslate('savedFail'));
          setSubmitLoading(false);
        });
      setVisible(false);
      setRefresh(!refresh);
    },
    [form, dataEdit, userInfo, companie_id],
  );

  return (
    <div className="page-staff-view">
      <Form
        layout={'vertical'}
        form={form}
        initialValues={{ layout: 'vertical', remember: true }}
        onFinish={onFinish}>
        <Drawer
          className="drawer-view-staff"
          placement="right"
          width={360}
          getContainer={false}
          title={getTranslate('caretaker')}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          extra={
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                return (
                  <Space>
                    <Text>{getTranslate(getFieldValue('enable') ? 'use' : 'inactive')}</Text>
                    <Form.Item noStyle name={'enable'} valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Space>
                );
              }}
            </Form.Item>
          }>
          <div className="drawer-body-bg">
            <Row gutter={16}>
              <Col span={24}>
                <div className="text-center my-4">
                  <Avatar size={150} src={visible && getviewData?.src} />
                  <br />
                  <br />
                  <Text>{visible && getviewData?.name}</Text>
                  <br />
                  <Text type="secondary">
                    {visible && getTextRights(null, 'name', dataEdit?.attributes?.admin_rights)}
                  </Text>
                </div>
                <div className="mx-2">
                  <Divider />
                </div>
                <div className=" m-4">
                  <div className="m-2">
                    <Space>
                      <MailOutlined />
                      <Text>{visible && getviewData?.email}</Text>
                    </Space>
                  </div>
                  <div className="m-2">
                    <Space>
                      <HistoryOutlined />
                      <Text>
                        {getTranslate('last_active')}{' '}
                        {visible && dateFormat(dataEdit?.attributes?.updatedAt)}
                      </Text>
                    </Space>
                  </div>
                </div>
                <div className="mx-2">
                  <Divider />
                  <Form.Item
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: getplaceholder('admin_rights'),
                      },
                    ]}
                    name="admin_rights"
                    label={<Text className="ml-2">{getTranslate('admin_rights')}</Text>}>
                    <Select className="w-100 ">
                      {defaultAdminRights.map((val) => (
                        <Option key={`admin_rights-${val.id}`} value={val.id}>
                          {getTranslate(val.locale)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="text-right mt-4">
                    <Button
                      key="back"
                      onClick={() => {
                        setVisible(false);
                      }}>
                      {getTranslate('cancel')}
                    </Button>
                    <UseRights allowed={[0]}>
                      <Popconfirm
                        placement="topRight"
                        title={getTranslate('confirmSaveData')}
                        onConfirm={() => {
                          form.submit();
                        }}
                        onCancel={null}>
                        <Button loading={submitLoading} className="btn-primary ml-3" type="primary">
                          {getTranslate('record')}
                        </Button>
                      </Popconfirm>
                    </UseRights>
                  </div>
                </div>
                {dataEdit?.attributes.admin_rights !== 0 && (
                  <div className="text-left mx-2">
                    <Divider />
                    <UseRights allowed={[0]}>
                      <Popconfirm
                        placement="topLeft"
                        title={confirmActionText('remove_from_caregiver')}
                        disabled={submitLoading}
                        onConfirm={() => {
                          saveDelete(dataEdit);
                        }}
                        onCancel={null}
                        icon={<ExclamationCircleFilled className="text-danger" />}>
                        <a className="mx-1" href="#API" onClick={(e) => e.preventDefault()}>
                          <Text type="danger">{getTranslate('remove_from_caregiver')}</Text>
                        </a>
                      </Popconfirm>
                    </UseRights>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Drawer>
      </Form>
    </div>
  );
}
