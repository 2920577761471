import antdThTH from 'antd/es/locale/th_TH';
import thMsg from "../locales/th_TH.json";

const THLang = {
  antd: antdThTH,
  locale: 'th-TH',
  messages: {
    ...thMsg
  },
};
export default THLang;