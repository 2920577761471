import React from 'react'
import { APP_NAME } from 'configs/AppConfig';
const package_json = require('../../../package.json');

export default function Footer() {
	return (
		<footer className="footer">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved. Version {package_json.version}  
			{/* <a className='Policy' href='#'>Discliamer & Policy</a>
			<a className='Policy' href='#'>Discliamer & Policy</a>
			<a className='Policy' href='#'>Discliamer & Policy</a> */}
		    
				<a className='ml-3' href="https://www.waankit.com/terms-of-service/" target='_bank'>Terms &amp; Conditions</a><span className='ml-2 mr-2'> | </span>
				<a href="https://www.waankit.com/privacy/" target='_bank'>Privacy Policy</a><span className='ml-2 mr-2'> | </span>
				<a href="https://www.waankit.com/cookie-policy/" target='_bank'>Cookies Policy</a>
			 

			</span>
			 
		</footer>
	)
}

