import { createContext } from 'react';

const customerListContext = createContext();
const customerDrawerContext = createContext();
const branchListContext = createContext();
const staffListContext = createContext();
const productFormContext = createContext();
const orderContext = createContext();
const appLayoutContext = createContext();
const businessCardContext = createContext();
const statisticsContext = createContext();

export {
  customerListContext,
  customerDrawerContext,
  branchListContext,
  staffListContext,
  productFormContext,
  orderContext,
  appLayoutContext,
  businessCardContext,
  statisticsContext,
};
