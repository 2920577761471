export const REFRESH_CHANGE = 'REFRESH_CHANGE';
export const USER_INFO_CHANGE = 'USER_INFO_CHANGE';
export const CUSTOMER_TAGS_CHANGE = 'CUSTOMER_TAGS_CHANGE';
export const CUSTOMER_TAGS_GET = 'CUSTOMER_TAGS_GET';
export const LOADDATA_CUSTOMER_TAGS = 'LOADDATA_CUSTOMER_TAGS';
export const CUSTOMER_TAGS_UPDATE = 'CUSTOMER_TAGS_UPDATE';
export const COMPANIES_CHANGE = 'COMPANIES_CHANGE';
export const COMPANIES_GET = 'COMPANIES_GET';
export const WAAN_SHOW_LOADING = 'WAAN_SHOW_LOADING';
export const WAAN_HIDE_LOADING = 'WAAN_HIDE_LOADING';
export const NOTIFICATION_CHANGE = 'NOTIFICATION_CHANGE';
export const NOTIFICATION_GET = 'NOTIFICATION_GET';
export const NOTIFICATION_COUNT_READ_CHANGE = 'NOTIFICATION_COUNT_READ_CHANGE';
export const IMPORT_DATA_ID_CHANGE = 'IMPORT_DATA_ID_CHANGE';
export const VISIBLE_CHANGE_PACKAGE = 'VISIBLE_CHANGE_PACKAGE';
