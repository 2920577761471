import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { onUserInfoChange } from 'redux/actions/Waan';
import PropTypes from 'prop-types';
import { getTextRights } from 'views/app-views/settings/staffList/staff-list';
import { Link } from 'react-router-dom';
import { getTranslate, GetDfaultCompanieImg } from 'components/CustomService';

export const NavOrganization = ({ onUserInfoChange, waan }) => {
  const userInfo = waan.userInfo;
  const companies = waan.companies;
  const loading = waan.loading;
  const [firstLoad, setFirstLoad] = useState(true);
  const [checkNull, setCheckNull] = useState(false);

  const [menuId, setmenuId] = useState(userInfo.companie_id);
  // const profileImg = '/img/avatars/animal-avatar-bear.png';

  useEffect(() => {
    setmenuId(userInfo.companie_id);
  }, [userInfo.companie_id]);

  useEffect(() => {
    if (!loading && firstLoad) {
      setFirstLoad(false);
    } else if (!firstLoad && !loading && !checkNull) {
      // const getCompanie = checkCompanie(companies, userInfo);
      // if (getCompanie.isUpdate) {
      // set เลือก companie
      // onUserInfoChange(getCompanie.userInfo);
      // }
      setCheckNull(true);
    }
  }, [companies, loading, firstLoad]);

  // if (companies == null || companies.length === 0) {
  //   return <></>;
  // }

  // const defaultSrc = (e) => (e.target.src = profileImg);

  const menu = (
    <Menu key={'menu-companies'}>
      {companies.map((m) => (
        <Menu.Item
          disabled={
            m.attributes.staff_admin?.attributes.enable === true &&
            m.attributes.staff_admin?.attributes.status === true
              ? false
              : true
          }
          key={m.id}
          className={'ant-dropdown-menu-item-active org-list-item'}
          onClick={() => {
            const admin_rights = m.attributes.staff_admin?.attributes.admin_rights;
            setmenuId(m.id);
            // set เลือก companie
            onUserInfoChange({
              ...userInfo,
              companie_id: m.id,
              companie_name: m.attributes.name,
              companie_uid: m.attributes.uid,
              admin_rights: admin_rights == null ? null : admin_rights,
            });

            // window.location.href = window.location.pathname;
            //  history.push(window.location.pathname);
          }}>
          <div className="d-flex  flex-row align-items-center Head-Org-Menu-Body">
            <div className="pr-3">
              {/* <img style={{ maxWidth: "20px" }} src={`${m.attributes.pic_path}${m.attributes.pic_name}`} alt={"th"} /> */}
              <Avatar
                size={35}
                src={GetDfaultCompanieImg(m)}
                // onError={defaultSrc}
              />
            </div>
            <div className="mr-1 ml-auto ">
              <div className="font-weight-normal txt-head">{`${m.attributes.name}`}</div>
              <div className="txt-sub">
                {m.attributes.staff_admin?.attributes.enable ? (
                  <CheckCircleFilled className=" waan-blue-color mr-1" />
                ) : (
                  <CloseCircleFilled className="text-gray-light mr-1" />
                )}
                {getTextRights(m?.attributes?.staff_admin)}
              </div>
            </div>
            <span className="">
              {menuId === m.id ? (
                <CheckCircleFilled className="img-checked ml-3 text-primary" />
              ) : (
                <CloseCircleFilled className="img-unchecked ml-3 " />
              )}
            </span>
          </div>
        </Menu.Item>
      ))}
      <Menu.Item key={'all'}>
        <div className="nav-org-footer">
          <Link to={'/app/profile/company'}>{getTranslate('view_all')}</Link>
        </div>
      </Menu.Item>
    </Menu>
  );

  const viewMenu = () => {
    const companie = companies.filter((f) => f.id === Number(menuId));

    return (
      // <React.Fragment>
      //   {companie.length > 0 && (
      <a className="ant-dropdown-link" href="#">
        <span className="txt-head">
          {companie.length > 0 ? companie[0].attributes.name : ''} <DownOutlined type="down" />
        </span>
        <span className="txt-sub">{getTextRights(companie[0]?.attributes?.staff_admin)}</span>
      </a>
      //   )}
      // </React.Fragment>
    );
    // if (companie.length > 0) {
    //   return (
    //     <a href="#/" onClick={(e) => e.preventDefault()}>
    //       {companie[0].attributes.name }x <DownOutlined style={{ fontSize: "10px" }} />
    //     </a>
    //   );
    // } else {
    //   return (
    //     <a href="#/" onClick={(e) => e.preventDefault()}>
    //       <DownOutlined style={{ fontSize: "10px" }} />
    //     </a>
    //   );
    // }
  };

  return (
    <Dropdown
      disabled={loading}
      placement="bottomRight"
      overlay={menu}
      trigger={['click']}
      className="Head-Org-Menu text-nowrap">
      {/* <Menu mode="horizontal">
        <Menu.Item key={0}>{viewMenu()}</Menu.Item>
      </Menu> */}
      {viewMenu()}
    </Dropdown>
  );
};

NavOrganization.propTypes = {
  onUserInfoChange: PropTypes.func,
  waan: PropTypes.object,
  locale: PropTypes.string,
};

const mapStateToProps = ({ waan, theme }) => {
  const { locale } = theme;
  return { waan, locale };
};
export default connect(mapStateToProps, { onUserInfoChange })(NavOrganization);
