import { isValidElement, cloneElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function UseRights({ userInfo, allowed = [0, 1], children, isHide = true, callOnClick }) {
  const admin_rights = userInfo.admin_rights;
  const isAdmin = userInfo.isAdmin ?? false;

  if (!allowed.includes(admin_rights) && !isAdmin) {
    if (isHide) {
      return <></>;
    }
    if (isValidElement(children)) {
      let attribute = {
        disabled: true,
      };
      if (callOnClick) {
        delete attribute.disabled;
      }
      const newDayComponent = cloneElement(children, {
        ...attribute,
        onClick: (e) => {
          if (callOnClick && typeof callOnClick === 'function') {
            callOnClick();
          } else {
            if (e?.target?.tagName === 'A') {
              e.preventDefault();
            }
          }
        },
      });
      return newDayComponent;
    }
  }
  return children;
}

UseRights.propTypes = {
  userInfo: PropTypes.object,
  allowed: PropTypes.array,
  children: PropTypes.node,
  isHide: PropTypes.bool,
  callOnClick: PropTypes.func,
};

const mapStateToProps = ({ waan }) => {
  const { userInfo } = waan;
  return { userInfo };
};

export default connect(mapStateToProps, {})(UseRights);
