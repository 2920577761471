import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Typography, message } from 'antd';
import { MailOutlined, BellOutlined, WarningOutlined, MessageTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Flex from 'components/shared-components/Flex';
import {
  notificationChange,
  notificationGet,
  countReadChange,
  onUserInfoChange,
  importDataIdChange,
} from 'redux/actions/Waan';
import {
  getTranslate,
  getCookieToken,
  dateFormat,
  translate_message,
  checkNull,
} from 'components/CustomService';
import axios from 'axios';
import useConfirm from 'components/waan-components/useConfirm';
const { io } = require('socket.io-client');
const { Text } = Typography;
const { Title } = Typography;

const getAvatar = (item) => {
  switch (item.attributes.typeId) {
    case 1:
      return { type: 'info', icon: <MailOutlined /> };
    case 2:
      return { type: 'info', icon: <BellOutlined /> };
    case 3:
      return { type: 'danger', icon: <WarningOutlined /> };
    case 4:
      return { type: 'info', icon: <MessageTwoTone /> };
    default:
      return { type: 'info', icon: <BellOutlined /> };
  }
};

export const NavNotification = ({
  waan,
  notificationChange,
  notificationGet,
  countReadChange,
  onUserInfoChange,
  importDataIdChange,
}) => {
  const userInfo = waan.userInfo;
  const data = waan.notification;
  const companies = {};
  waan.companies.map((companie) => {
    companies[companie.id] = companie;
  });
  const { showConfirm } = useConfirm({
    title: getTranslate('confirm_switch_shop_company'),
    content: getTranslate('confirm_switch_shop_company_message'),
  });
  const count_read = waan.count_read;
  const [visible, setVisible] = useState(false);
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    axios.defaults.headers.common['api_key'] = process.env.REACT_APP_API_KEY;
    axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
    responseSocket();
  }, []);

  const dataNoti = [];
  data.map((m) => {
    const getCompanie = companies[m.attributes.companie_id];
    const companie_id = waan.userInfo.companie_id;
    if (m.attributes.companie_id && getCompanie == null) {
      return;
    }
    const url = m.attributes.url;
    dataNoti.push({
      ...m,
      is_companie: getCompanie ? true : false,
      is_active_companie: getCompanie
        ? m.attributes.companie_id === companie_id
          ? true
          : false
        : false,
      companie: getCompanie,
      is_baseUrl: url ? url.includes('{{baseUrl}}') : false,
    });
  });

  const responseSocket = () => {
    const JWT_TOKEN = getCookieToken();
    const socket = io(process.env.REACT_APP_SOCKET, {
      auth: {
        token: JWT_TOKEN,
      },
    });

    socket.on('new-noti-' + userInfo.autoID, (messageNew) => {
      //new-noti-dev-
      if (messageNew && messageNew.data && typeof messageNew.data === 'object') {
        notificationChange(messageNew.data);
        countReadChange(messageNew.count_read);
        if (
          messageNew.data.length > 0 &&
          typeof messageNew.data[0].attributes.message_data === 'object'
        ) {
          if (messageNew.data[0].attributes.message_data?.import_data_file_id != null) {
            importDataIdChange(messageNew.data[0].attributes.message_data.import_data_file_id);
          }
        }
      }
    });
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  async function read_all() {
    setIsAll(true);
    await axios
      .post(process.env.REACT_APP_SOCKET + `/api/notification/read_all`, {})
      .then(() => {
        message.success(getTranslate('clear_all_notifications_success'));
      })
      .catch(() => {
        message.error(getTranslate('clear_all_notifications_fail'));
      });
    await notificationGet();
    setIsAll(false);
  }

  const updateRead = async (item) => {
    const toUrl = checkNull(item.attributes?.url)
      ? item.attributes.url.replace(/{{baseUrl}}/g, window.location.origin)
      : null;
    if (!item.attributes.is_read) {
      await axios
        .put(process.env.REACT_APP_SOCKET + `/api/notification/is_read/${item.id}`)
        .then(() => {
          message.success(getTranslate('readSuccess'));
        })
        .catch(() => {
          message.error(getTranslate('readFail'));
        });
      if (toUrl) window.open(toUrl, '_blank');
      await notificationGet();
    } else {
      if (toUrl) window.open(toUrl, '_blank');
    }
  };

  const getListItem = (item, is_onClick) => {
    const get_avatar = getAvatar(item);
    const chackUrl = item.attributes.url == null ? '' : item.attributes.url;

    return (
      <List.Item
        className={`list-clickable ${item.attributes.is_read ? '' : 'bg-gray-lightest'}`}
        onClick={() => {
          if (!is_onClick) {
            showConfirm({
              onOk: async () => {
                // set เลือก companie
                const admin_rights = item.companie.attributes.staff_admin?.attributes.admin_rights;
                await onUserInfoChange({
                  ...userInfo,
                  companie_id: item.companie.id,
                  companie_name: item.companie.attributes.name,
                  companie_uid: item.companie.attributes.uid,
                  admin_rights: admin_rights == null ? null : admin_rights,
                });
                await updateRead(item);
              },
            });
          } else if (
            is_onClick &&
            (item.is_active_companie === true || (!item.attributes.companie_id && !item.is_baseUrl))
          ) {
            updateRead(item);
          } else if (is_onClick && item.attributes.companie_id && !item.is_companie) {
            message.warning(getTranslate('you_no_longer_have_rights_to_this_company'));
          } else if (is_onClick && chackUrl.trim() != '') {
            message.warning(getTranslate('there_was_an_error_in_the_link'));
          }
        }}>
        <Flex alignItems="center">
          <div className="pr-3">
            {item.img ? (
              <Avatar src={`/img/avatars/${item.attributes.img}`} />
            ) : (
              <Avatar className={`ant-avatar-${get_avatar.type}`} icon={get_avatar.icon} />
            )}
          </div>
          <div className="mr-3">
            <Text className="text-dark" strong>
              {translate_message(item.attributes.title)}
            </Text>
            <div>
              <Text className="font-size-sm" type="secondary">
                {dateFormat(item.attributes.createdAt, 'MMM dd, yyyy {{__-__}} HH:mm')}
              </Text>
            </div>
            <div style={{ marginTop: -3 }}>
              <Text className="font-size-sm text-primary" strong>
                {item.companie?.attributes.name}
              </Text>
            </div>
          </div>
          {/* <small className="ml-auto">{item.attributes.createdAt}</small> */}
        </Flex>
      </List.Item>
    );
  };

  const getNotificationBody = (list) => {
    return list.length > 0 ? (
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => {
          if (item.is_companie && item.is_active_companie === false) {
            return getListItem(item, false);
          }
          return getListItem(item, true);
        }}
      />
    ) : (
      <div className="empty-notification">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="empty"
        />
        <p className="mt-3">{getTranslate('you_have_no_notifications')}</p>
      </div>
    );
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center pt-3 pb-2">
        <Title className="mb-0" strong level={4}>
          {getTranslate('warn')}
        </Title>
        <a
          href="#/"
          className={isAll ? `disabled` : ''}
          onClick={(e) => {
            e.preventDefault();
            read_all();
          }}
          size="small">
          {getTranslate('clear_all')}
        </a>
      </div>
      <div className="nav-notification-body">{getNotificationBody(dataNoti)}</div>
      {dataNoti.length > 0 ? (
        <div className="nav-notification-footer">
          <Link
            to={'/app/profile/notification'}
            onClick={() => {
              setVisible(false);
            }}>
            {getTranslate('view_all')}
          </Link>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}>
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={count_read}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

NavNotification.propTypes = {
  waan: PropTypes.object,
  notificationChange: PropTypes.func.isRequired,
  notificationGet: PropTypes.func.isRequired,
  countReadChange: PropTypes.func.isRequired,
  onUserInfoChange: PropTypes.func.isRequired,
  importDataIdChange: PropTypes.func.isRequired,
};

const mapStateToProps = ({ waan }) => {
  return { waan };
};
export default connect(mapStateToProps, {
  notificationChange,
  notificationGet,
  countReadChange,
  onUserInfoChange,
  importDataIdChange,
})(NavNotification);
