import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import IntlMessage from 'components/util-components/IntlMessage';
import AppLocale from 'lang';
import store from '../redux/store';
import JQL from 'jqljs';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import qs from 'qs';
import { AUTH_TOKEN, WAAN_UIN } from 'redux/constants/Auth';
import { authenticated } from 'redux/actions/Auth';
import { Typography, Tooltip, message as messagePopUp } from 'antd';
import { differenceInMinutes } from 'date-fns';
import { enUS as localeenUS, th as localeTH } from 'date-fns/locale';
import { utcToZonedTime, format } from 'date-fns-tz';
import { omit } from 'lodash';

const charmapJs = require('../assets/data/charmap.json');
var slugify = require('slugify');
slugify.extend(charmapJs);
const { Text } = Typography;

const REACT_APP_SecretKey = process.env.REACT_APP_SecretKey;
const REACT_APP_API = process.env.REACT_APP_API;
const REACT_APP_ELASTIC = process.env.REACT_APP_ELASTIC;
const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const cookieAttributes =
  REACT_APP_ENV === 'production' ? { path: '/', domain: 'waankit.com' } : { path: '/' };

export const GLocale = (localeKey) => <IntlMessage id={localeKey} />;

export const getTranslate = (key) => {
  if (!checkNull(key)) {
    return '';
  }
  const { theme } = store.getState();
  const currentLanguage = theme.locale;
  const currentAppLocale = AppLocale[currentLanguage];
  // let objreturn = (
  //   <IntlProvider
  //     locale={currentAppLocale.locale}
  //     messages={currentAppLocale.messages}
  //   >
  //     <IntlMessage id={key}> </IntlMessage>
  //   </IntlProvider>
  // );
  // return objreturn;
  const messages = currentAppLocale.messages[key];
  return messages !== undefined ? messages : key.toString();
};

export const strToElement = (str, element) => {
  str = str.replace('<status>', ReactDOMServer.renderToString(element));
  var createElement = document.createElement('div');
  createElement.innerHTML = str;
  return createElement;
};

export const preprocess = (data) => {
  const expanded = [];
  data.data.forEach((provinceEntry) => {
    const province = provinceEntry[0];
    const province_id = provinceEntry[1];

    const amphurList = provinceEntry[2];
    amphurList.forEach((amphurEntry) => {
      const amphur = amphurEntry[0];
      const amphur_id = amphurEntry[1];

      const districtList = amphurEntry[2];

      districtList.forEach((districtEntry) => {
        const district = districtEntry[0];
        const district_id = districtEntry[1];
        const zipCode = districtEntry[2];

        expanded.push({
          d: district,
          d_id: district_id,
          a: amphur,
          a_id: amphur_id,
          p: province,
          p_id: province_id,
          z: zipCode,
        });
      });
    });
  });
  return expanded;
};

export const resolveResultbyField = (DB, type, searchStr) => {
  let possibles = [];
  try {
    possibles = DB.select('*').where(type).match(`^${searchStr}`).orderBy(type).fetch();
  } catch (e) {
    return [];
  }

  return possibles;
};

export const searchAddressAll = (DB, _address) => {
  let possibles = [];
  try {
    possibles = DB.select('*')
      .where('z')
      .equalTo(_address.z)
      .where('p')
      .equalTo(_address.p)
      .where('a')
      .equalTo(_address.a)
      .where('d')
      .equalTo(_address.d)
      .orderBy('z')
      .fetch();
  } catch (e) {
    return [];
  }

  return possibles;
};

export const searchAddressAuto = (DB, searchStr) => {
  let possibles = [];
  try {
    possibles = new JQL(
      possibles
        .concat(DB.select('*').where('z').match(searchStr).fetch())
        .concat(DB.select('*').where('p').match(searchStr).fetch())
        .concat(DB.select('*').where('a').match(searchStr).fetch())
        .concat(DB.select('*').where('d').match(searchStr).fetch())
        .map(function (item) {
          return JSON.stringify(item);
        })
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        })
        .map(function (self) {
          // give a likely score, will use to sort data later

          self = JSON.parse(self);
          self.likely = [
            similar_text(searchStr, self.d) * 5,
            similar_text(searchStr, self.a.replace(/^เมือง/, '')) * 3,
            similar_text(searchStr, self.p),
            similar_text(searchStr, self.z),
          ].reduce(function (a, b) {
            return Math.max(a, b);
          });

          return self;
        }),
    )
      .select('*')
      .orderBy('likely desc')
      .fetch();
  } catch (e) {
    return [];
  }

  return possibles;
};

export const similar_text = (first, second, percentage) => {
  // compare 2 strings, return value of similarity compare to each other. more value = more similarity
  first += '';
  second += '';

  var pos1 = 0,
    pos2 = 0,
    max = 0,
    firstLength = first.length,
    secondLength = second.length,
    p,
    q,
    l,
    sum;

  for (p = 0; p < firstLength; p = p + 1) {
    for (q = 0; q < secondLength; q = q + 1) {
      l = 0;
      while (
        p + l < firstLength &&
        q + l < secondLength &&
        first.charAt(p + l) === second.charAt(q + l)
      ) {
        l = l + 1;
      }
      if (l > max) {
        max = l;
        pos1 = p;
        pos2 = q;
      }
    }
  }

  sum = max;

  if (sum) {
    if (pos1 && pos2) {
      sum += similar_text(first.substr(0, pos2), second.substr(0, pos2), false);
    }

    if (pos1 + max < firstLength && pos2 + max < secondLength) {
      sum += similar_text(
        first.substr(pos1 + max, firstLength - pos1 - max),
        second.substr(pos2 + max, secondLength - pos2 - max),
        false,
      );
    }
  }

  if (percentage === false) {
    return sum;
  } else {
    if (first === second) {
      return 100;
    } else {
      if (firstLength > secondLength) {
        return Math.floor((sum / firstLength) * 100);
      } else {
        return Math.floor((sum / secondLength) * 100);
      }
    }
  }
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const formatLabel = (label, value) => {
  if (!value) {
    return label;
  }
  return (
    <span>
      {label.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }
        return prev.concat(
          <Text strong key={value + current}>
            {value}
          </Text>,
          current,
        );
      }, [])}
    </span>
  );
};

export const useAxios = (url_path = null) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
    await axios
      .get(REACT_APP_API + url_path)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
        setData([]);
      });
  };

  useEffect(() => {
    if (url_path !== null) {
      getData();
    }
  }, [url_path]);

  return { data, isError, isLoading };
};

export const useAxiosFetch = (
  url,
  refresh = false,
  timeout,
  baseUrlApi = REACT_APP_API,
  defaultData = [],
  isNullReSetData = false,
) => {
  const [data, setData] = useState(defaultData);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const Time_zone = GetUserInfo('timeZone');

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();

    const getData = async () => {
      setLoading(true);
      axios.defaults.headers.common['api_key'] = process.env.REACT_APP_API_KEY;
      axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
      await axios
        .get(baseUrlApi + url, {
          cancelToken: source.token,
          headers: {
            'time-zone': Time_zone,
            //   api_key: process.env.REACT_APP_API_KEY,
            //   Authorization: `Bearer ` + getCookieToken(),
          },
          //timeout: timeout,
        })
        .then((res) => {
          if (!unmounted) {
            // @ts-ignore
            setData(res.data);
            setStatus(200);
            setLoading(false);
          }
        })
        .catch(function (err) {
          if (!unmounted) {
            setError(true);
            setStatus(400);
            setErrorMessage(err.message);
            setLoading(false);
            if (axios.isCancel(err)) {
              console.log(`request cancelled:${err.message}`);
            } else {
              console.log('another error happened:' + err.message);
            }
          }
        });
    };
    if (isNullReSetData && url == null) {
      setData(defaultData);
      setStatus(null);
    }
    if (url !== null) {
      getData();
    }

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [url, refresh, timeout, isNullReSetData]);

  return { data, isLoading, isError, errorMessage, status };
};

export const getTextAddress = (dbAddress, data) => {
  const district_id = data.district_id;
  const amphure_id = data.amphure_id;
  const province_id = data.province_id;
  const post_code = data.post_code;

  if (district_id == null || amphure_id == null || province_id == null || post_code == null) {
    return {
      status: false,
      text: null,
    };
  }

  const dataAddress = resolveResultbyField(dbAddress, 'z', post_code);
  const address = dataAddress.filter(
    (f) =>
      f.d_id == district_id && f.a_id == amphure_id && f.p_id == province_id && f.z == post_code,
  );

  if (address.length > 0) {
    return {
      ...address[0],
      status: true,
      full_address: `ตำบล${address[0].d} อำเภอ${address[0].a} จังหวัด${address[0].p} ${address[0].z}`,
      key: getKeyAddress(address[0]),
    };
  }
  return {
    status: false,
    text: null,
    key: '',
  };
};

export const getKeyAddress = (address) => {
  return `${address.d_id}-${address.a_id}-${address.p_id}-${address.z}`;
};

export const setUserInfo = (user, exp = 30) => {
  let exp_date = Date.now() + 1 * 24 * 60 * 60 * 1000;
  if (exp === 30) {
    exp_date = Date.now() + 30 * 24 * 60 * 60 * 1000;
  }

  const userInfoSetting = {
    ...user,
    timeZone: user.timeZone == null ? 'Asia/Bangkok' : user.timeZone,
  };
  let convert2 = JSON.stringify(userInfoSetting);
  let ciphertext2 = CryptoJS.AES.encrypt(convert2, REACT_APP_SecretKey);
  Cookies.set(WAAN_UIN, ciphertext2.toString(), {
    ...cookieAttributes,
    expires: new Date(exp_date),
  });
};

export const GetUserInfo = (getKey = 'all') => {
  // ค้นหา default-userInfo
  let userInfoSetting = {
    companie_id: null,
    companie_name: null,
    companie_uid: null,
    autoID: null,
    email: null,
    displayname: null,
    firstname: null,
    lastname: null,
    img_avatar: null,
    path_img_avatar: null,
    roles: null,
    phoneNumber: null,
    timeZone: 'Asia/Bangkok',
    admin_rights: null,
    isAdmin: false,
  };
  const info = Cookies.get(WAAN_UIN);
  if (info != null && info != undefined) {
    var _secretKey = REACT_APP_SecretKey;
    let bytes = CryptoJS.AES.decrypt(info, _secretKey);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    let json = JSON.parse(plaintext);
    if (json != null) {
      userInfoSetting = json;
    }
  }

  if (getKey === 'all') {
    return userInfoSetting;
  } else if (getKey !== 'all') {
    if (userInfoSetting[getKey] != null) {
      return userInfoSetting[getKey];
    } else {
      return userInfoSetting;
    }
  } else {
    return userInfoSetting;
  }
};

export const formCategories = (dataCategories) => {
  const categories = [];

  dataCategories?.data
    ?.filter((f) => f.attributes.parent_category === 0)
    .forEach((cat_1) => {
      const list_2 = dataCategories?.data?.filter((f) => f.attributes.parent_category === cat_1.id);
      let category_2 = [];
      list_2.forEach((cat_2) => {
        let category_3 = [];
        const list_3 = dataCategories?.data?.filter(
          (f) => f.attributes.parent_category === cat_2.id,
        );
        list_3.forEach((cat_3) => {
          category_3.push({
            id: cat_3.id,
            ...cat_3.attributes,
            value: cat_3.id,
            label: cat_3.attributes.category,
          });
        });
        category_2.push({
          id: cat_2.id,
          ...cat_2.attributes,
          children: category_3,
          value: cat_2.id,
          label: cat_2.attributes.category,
        });
      });
      categories.push({
        id: cat_1.id,
        ...cat_1.attributes,
        children: category_2,
        value: cat_1.id,
        label: cat_1.attributes.category,
      });
    });

  return categories;
};

export const setCookieToken = (string_token, exp = 30) => {
  let exp_date = Date.now() + 1 * 24 * 60 * 60 * 1000;
  if (exp === 30) {
    exp_date = Date.now() + 30 * 24 * 60 * 60 * 1000;
  }

  const json_token = {
    token: string_token,
  };
  let convert = JSON.stringify(json_token);
  let ciphertext = CryptoJS.AES.encrypt(convert, REACT_APP_SecretKey);
  Cookies.set(AUTH_TOKEN, ciphertext.toString(), {
    ...cookieAttributes,
    expires: new Date(exp_date),
  });
};

export const getCookieToken = () => {
  const token = Cookies.get(AUTH_TOKEN);

  // eslint-disable-next-line
  if (token != null && token != undefined) {
    var _secretKey = REACT_APP_SecretKey;
    try {
      let bytes = CryptoJS.AES.decrypt(token, _secretKey);
      let plaintext = bytes.toString(CryptoJS.enc.Utf8);
      let json = JSON.parse(plaintext);
      return json.token;
    } catch (err) {
      authenticated(null);
      cleanCookieAuth();
      return null;
    }
  }
  return null;
};

export const getParams = (
  search,
  firstLoad = false,
  default_params = { sort: 'updatedAt:desc' },
  delete_params = [],
) => {
  let params = {};
  let params_url = {};
  let query = '';
  if (firstLoad) {
    params = default_params;
    params_url = {
      ...params,
    };
    query = qs.stringify(params, {
      encodeValuesOnly: true,
    });
  }
  search = search.substring(search.indexOf('='));
  if (search.indexOf('=') != -1) {
    try {
      search = search.substring(1);
      let bytes = CryptoJS.AES.decrypt(search, process.env.REACT_APP_ENCRYPT);
      let plaintext = bytes.toString(CryptoJS.enc.Utf8);
      params = {
        ...params,
        ...qs.parse(plaintext),
      };
      params = omit(params, delete_params);
      params_url = {
        ...params,
        ...qs.parse(plaintext),
      };
      query = qs.stringify(params, {
        encodeValuesOnly: true,
      });
    } catch (err) {
      params_url = {
        ...params,
      };
      query = qs.stringify(params, {
        encodeValuesOnly: true,
      });
    }
  }

  return {
    urlParams: params_url,
    urlQuery: query,
  };
};

export const setUrlParams = (path, encrypt) => {
  let ciphertext = CryptoJS.AES.encrypt(encrypt, process.env.REACT_APP_ENCRYPT);
  let url = window.location.origin.toString() + path + ciphertext.toString();
  window.history.pushState(null, null, url);
};

export const paramToquery = (value) => {
  return qs.stringify(
    { ...value },
    {
      encodeValuesOnly: true,
    },
  );
};

export const customLabel = (key, necessary = false) => {
  return (
    <React.Fragment>
      <Text>{getTranslate(key)}</Text>
      {necessary ? (
        <span className="pl-2 font-size-sm text-danger font-weight-normal">
          * {getTranslate('necessary')}
        </span>
      ) : (
        <span className="pl-2 font-size-sm text-gray-light font-weight-normal">
          ( {getTranslate('choice')} )
        </span>
      )}
    </React.Fragment>
  );
};

export const confirmActionText = (txet) => {
  return (
    <React.Fragment>
      {getTranslate('confirmAction')}
      {getTranslate(txet)}
    </React.Fragment>
  );
};

export const dateFormatUtcToZonedTime = (
  date,
  formDate = 'd-MMM-yyyy HH:mm',
  addDate = null,
  Time_zone,
) => {
  const { locale, locales } = getLocaleView();
  var new_date = new Date(date);
  if (addDate && typeof addDate === 'number') {
    new_date.setDate(new_date.getDate() + addDate);
  }
  if (!Time_zone) {
    Time_zone = GetUserInfo('timeZone');
  }
  const strDate = format(new Date(new_date), formDate, {
    timeZone: Time_zone,
    locale: locales[locale] ?? localeTH,
  });
  if (strDate && typeof strDate === 'string') {
    return translate_message(strDate);
  }
  return strDate;
};

export const dateFormat = (date, formDate = 'd-MMM-yyyy HH:mm', addDate = null, Time_zone) => {
  const { locale, locales } = getLocaleView();
  var new_date = new Date(date);
  if (addDate && typeof addDate === 'number') {
    new_date.setDate(new_date.getDate() + addDate);
  }
  if (!Time_zone) {
    Time_zone = GetUserInfo('timeZone');
  }
  const strDate = format(utcToZonedTime(new Date(new_date), Time_zone), formDate, {
    timeZone: Time_zone,
    locale: locales[locale] ?? localeTH,
  });
  if (strDate && typeof strDate === 'string') {
    return translate_message(strDate);
  }
  return strDate;
};

export const dateFormatThai = (value) => {
  value = value.replace('Jan', 'ม.ค.');
  value = value.replace('Feb', 'ก.พ.');
  value = value.replace('Mar', 'มี.ค.');
  value = value.replace('Apr', 'เม.ย.');
  value = value.replace('May', 'พ.ค.');
  value = value.replace('Jun', 'มิ.ย.');
  value = value.replace('Jul', 'ก.ค.');
  value = value.replace('Aug', 'ส.ค.');
  value = value.replace('Sep', 'ก.ย.');
  value = value.replace('Oct', 'ต.ค.');
  value = value.replace('Nov', 'พ.ย.');
  value = value.replace('Dec', 'ธ.ค.');
  return value;
};

export const dateFormatDistance = (date, formDate = 'd MMM yyyy HH:mm', tooltip = false) => {
  const { locale, locales } = getLocaleView();
  const Time_zone = GetUserInfo('timeZone');
  const minutes = Math.abs(differenceInMinutes(new Date(date), new Date()));
  const hours = Math.floor(minutes / 60);
  // const days = Math.floor(minutes / 1440);
  let str_date = '';
  if (minutes < 60) {
    str_date = `${minutes} minutes ago`;
  } else if (minutes < 1440) {
    str_date = `${hours} hours ago`;
  } else {
    str_date = format(utcToZonedTime(new Date(date), Time_zone), formDate, {
      timeZone: Time_zone,
      locale: locales[locale] ?? localeTH,
    });
    // return `${days} days ago`;
  }
  if (tooltip) {
    const text_date = format(utcToZonedTime(new Date(date), Time_zone), formDate, {
      timeZone: Time_zone,
      locale: locales[locale] ?? localeTH,
    });
    return (
      <Tooltip title={text_date}>
        <Text>{str_date}</Text>
      </Tooltip>
    );
  } else {
    return str_date;
  }
};

export const getMessage = (txet) => {
  return `${getTranslate('pleaseEnter')}${getTranslate(txet)}`;
};

export const getplaceholder = (txet, type = 1) => {
  if (type === 2) {
    return `${getTranslate('pleaseSelect')}${getTranslate(txet)}`;
  } else {
    return `${getTranslate('pleaseEnter')}${getTranslate(txet)}`;
  }
};

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), REACT_APP_SecretKey).toString();
};

export const decryptData = (data) => {
  try {
    let bytes = CryptoJS.AES.decrypt(data, REACT_APP_SecretKey);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plaintext);
  } catch (err) {
    return null;
  }
};

export const encryptString = (value) => {
  return CryptoJS.AES.encrypt(value, process.env.REACT_APP_ENCRYPT).toString();
};

export const decryptString = (value) => {
  const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_ENCRYPT);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
};

export const checkPassword = (value) => {
  var mediumRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  return mediumRegex.test(value) && value.length >= 8;
};

export const confirmation_staff = async (record, history = null) => {
  const companie_id = record.id;
  const staff_id = record.attributes.staff_admin.id;
  const lastname = record.attributes.staff_admin.attributes.lastname;
  const admin_rights = record.attributes.staff_admin.attributes.admin_rights;
  let companie_img = null;
  if (checkNull(record.attributes.pic_path) && checkNull(record.attributes.pic_name)) {
    companie_img = `${record.attributes.pic_path}/${record.attributes.pic_name}`;
  }

  const expiration_date = new Date();
  expiration_date.setHours(expiration_date.getHours() + 24);

  let data = {
    to_email: record.attributes.staff_admin.attributes.email,
    companie_id: companie_id,
    companie_name: record.attributes.name,
    companie_img: companie_img,
    fullname: '',
    to_fullname: `${record.attributes.staff_admin.attributes.firstname} ${
      lastname == null ? '' : lastname
    }`,
    firstname: record.attributes.staff_admin.attributes.firstname,
    lastname: lastname == null ? '' : lastname,
    staff_id: staff_id,
    admin_rights: admin_rights,
    expiration_date: expiration_date.toUTCString(),
  };
  axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
  const res = await axios
    .get(
      process.env.REACT_APP_API_AUTH +
        `/api/Member/GetProfileUser?UserAutoID=${record.attributes.staff_admin.attributes.invite_by_user_id}`,
    )
    .catch(() => {});
  if (res.status === 200 && res.data.Code === 200) {
    const like_confirm = `/auth/accept-invite/?confirmation=`;
    data.fullname = res.data.Result.Displayname;
    const stringify = JSON.stringify(data);
    const confirmation = CryptoJS.AES.encrypt(
      stringify,
      process.env.REACT_APP_SecretKey,
    ).toString();
    if (history) {
      history.push(`${like_confirm}${confirmation}`);
    } else {
      window.open(`${like_confirm}${confirmation}`, '_blank');
    }
  }
  return null;
};

export const getUrlImg = (dataImg) => {
  const Img_url = dataImg.url;
  const Img_avatar = dataImg.hash + dataImg.ext;
  const Path_img_avatar = Img_url.substring(0, Img_url.indexOf(`/${Img_avatar}`));
  return {
    id: dataImg.id,
    path: Path_img_avatar,
    name: Img_avatar,
    url: dataImg.full_url,
  };
};

export const checkNull = (val) => {
  var res = val == null ? null : typeof val === 'string' ? (val.trim() == '' ? null : val) : val;
  return res;
};

export const UrlToVar = (string) => {
  if (string != null) {
    var re = new RegExp(process.env.REACT_APP_PathCDN, 'g');
    var res = string.replace(re, '{{url}}');
    return res;
  } else {
    return '';
  }
};

export const VarToUrl = (string) => {
  if (string != null) {
    var res = string.replace(/{{url}}/g, process.env.REACT_APP_PathCDN);
    return res;
  } else {
    return '';
  }
};

export const dummyRequest = (e) => {
  setTimeout(() => {
    e.onSuccess('ok');
  }, 0);
};

export const getfile_permission = async (url) => {
  let res = await axios
    .post(process.env.REACT_APP_API + '/api/upload/getfile_permission', {
      url: url,
    })
    .catch(() => {});
  if (res?.status === 200) {
    return res.data;
  }
  return null;
};

export const upload_picture = async (
  {
    pic_path = null,
    pic_name = null,
    file,
    pic_full = null,
    typeDelete = undefined,
    AutoID = undefined,
    isMessage = false,
    attDel = {},
  },
  companie_id,
  is_private = false,
  type_path = 'other',
) => {
  if (!companie_id) return null;
  let formData = new FormData();
  formData.append('files', file);
  formData.append('path', is_private ? 'private' : 'public');
  formData.append('company_id', companie_id);
  formData.append('type_path', type_path);
  if (AutoID) {
    formData.append('AutoID', AutoID);
  }
  const result = await axios
    .post(process.env.REACT_APP_API + '/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((res) => res);
  if (result?.status === 200) {
    const dataImg = getUrlImg(result.data);
    if ((checkNull(pic_path) && checkNull(pic_name)) || checkNull(pic_full)) {
      if (checkNull(pic_full)) {
        await delete_picture(`${pic_full}`, typeDelete, attDel);
      } else {
        await delete_picture(`${pic_path}/${pic_name}`, typeDelete, attDel);
      }
    }
    return dataImg;
  } else if (result?.response?.status === 403 && isMessage) {
    if (result?.response?.data?.error) {
      return result.response.data.error;
    }
  }

  return null;
};

export const delete_picture = async (url, type = undefined, att = {}) => {
  try {
    const result = await axios
      .post(process.env.REACT_APP_API + '/api/upload/deletefile_bykey', {
        url: url,
        type: type,
        ...att,
      })
      .catch(() => {});

    if (result?.status === 200) {
      return result.data;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const checkEmail = (string) => {
  /* eslint-disable */
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let vali_email = regex.test(string);
  return vali_email;
};

export const checkNumber = (string) => {
  const reg_tel = /^\d+$/;
  return reg_tel.test(string);
};

export const formAttributeGroups = (data) => {
  const AttributeGroups = [];
  if (data?.parent_attribute_group_id.data) {
    AttributeGroups.push({
      ...data.parent_attribute_group_id.data,
      attributes: {
        ...data.parent_attribute_group_id.data.attributes,
        level_no: 1,
      },
    });
  }
  if (data?.attribute_group_id.data) {
    AttributeGroups.push({
      ...data.attribute_group_id.data,
      attributes: {
        ...data.attribute_group_id.data.attributes,
        level_no: 2,
      },
    });
  }

  return AttributeGroups;
};

export const sugId = (slug_text) => {
  return slugify(slug_text, {
    replacement: '',
    remove: undefined,
    lower: true,
    strict: true,
    locale: 'th',
    trim: true,
  });
};

export const formDate = (date) => {
  var date_regex = /^((0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/;
  return date_regex.test(date);
};

export const isValidURL = (string, checkURLIMg = false) => {
  if (!string) return false;
  string = String(string);
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  if (checkURLIMg && res !== null) {
    return string.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  return res !== null;
};

export const translate_message = (str) => {
  const regex = /{{(\w+)}}|{{(__-__)}}/g;
  if (!str) return str;
  const modifiedText = str.replace(regex, function (match, key1, key2) {
    // const keyRe = match.replace(/{{|}}/gi, '');
    if (key1) {
      return getTranslate(key1);
    } else if (key2) {
      return getTranslate(key2);
    }
    return '';
  });
  return modifiedText;
};

export const toDecimal = (value, decimal) => {
  decimal = 10 ** decimal;
  value = Number(value);
  return Math.round(value * decimal) / decimal;
};

export const es_sync_order_by_id = async (companie_id, order_id) => {
  const urlPost = `${REACT_APP_ELASTIC}${'/order/sync_by_id'}`;
  await axios.post(urlPost, {
    companie_id: Number(companie_id),
    order_id: Number(order_id),
  });
};

export const es_sync_product_by_id = async (companie_id, product_id) => {
  const urlPost = `${REACT_APP_ELASTIC}${'/product/sync_by_id'}`;
  await axios.post(urlPost, {
    companie_id: Number(companie_id),
    product_id: Number(product_id),
  });
};

export const es_sync_customer_by_id = async (companie_id, customer_id) => {
  const urlPost = `${REACT_APP_ELASTIC}${'/customer/sync_by_id'}`;
  await axios.post(urlPost, {
    companie_id: Number(companie_id),
    customer_id: Number(customer_id),
  });
};

export const getCustomerLv = (customer_lvs = [], point = 0, customer) => {
  if (!customer) {
    const customer_lv = customer_lvs.filter(
      (a) => a.attributes.status && a.attributes.lv_type === 0,
    );
    return customer_lv.length > 0 ? customer_lv[0] : null;
  }
  const lv_type = customer.attributes.lv_type;
  const auto_level = customer.attributes.auto_level;
  if (auto_level) {
    const customer_lv = customer_lvs
      .filter((a) => a.attributes.status)
      .sort((a, b) => a.attributes.lv_type - b.attributes.lv_type)
      .find((val, index, arr) => {
        if (arr.length - 1 == index) {
          if (point >= val.attributes.start_point) {
            return true;
          }
        } else {
          if (
            point >= val.attributes.start_point &&
            point < arr[index + 1].attributes.start_point
          ) {
            return true;
          }
        }
        return false;
      });
    return customer_lv ? customer_lv : null;
  } else {
    if (lv_type != null) {
      const customer_lv = customer_lvs.filter(
        (a) => a.attributes.status && a.attributes.lv_type === lv_type,
      );
      return customer_lv.length > 0 ? customer_lv[0] : null;
    } else {
      const customer_lv = customer_lvs.filter(
        (a) => a.attributes.status && a.attributes.lv_type === 0,
      );
      return customer_lv.length > 0 ? customer_lv[0] : null;
    }
  }
};

export const accumulated_points = (points = []) => {
  return points.reduce((x, y) => {
    if (!y.attributes.is_deleted) {
      return x + Number(y.attributes.point);
    }
    return x;
  }, 0);
};

export const calculate_total_point = (points = []) => {
  return points.reduce((total, val) => {
    if (!val.attributes.is_deleted) {
      if (val.attributes.typeId === 1) {
        return total + val.attributes.point;
      } else if (val.attributes.typeId === 2) {
        return total - val.attributes.point;
      } else {
        return total - val.attributes.point;
      }
    }
    return total;
  }, 0);
};

export const minutesToDhms = (minutes, minus = false) => {
  minutes = Number(minutes);
  var d = 0;
  var h = 0;
  var m = 0;

  if (minus) {
    minutes = Math.abs(minutes);
  }
  d = Math.floor(minutes / (60 * 24));
  h = Math.floor((minutes % (60 * 24)) / 60);
  m = Math.floor(minutes % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : '';
  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';

  return {
    day: d,
    hour: h,
    minute: m,
    totalmins: minutes,
    text: dDisplay + hDisplay + mDisplay,
  };
};

export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : '';
  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const get_url_order = (order_no = null, member_id = null) => {
  let filters = {
    search_key: order_no ? order_no : member_id,
  };
  const newParams = {
    filters: filters,
    auto_focus: order_no ? true : false,
  };
  const query = paramToquery(newParams);
  const url_order = `/app/apps/orders/order-list?q=${encryptString(query)}`;
  return url_order;
};

export const get_url_campaign = (id) => {
  const newParams = {
    filters: {
      id: {
        $eq: id,
      },
    },
  };
  const query = paramToquery(newParams);
  const url_campaign = `/app/apps/campaign/index?q=${encryptString(query)}`;
  return url_campaign;
};

export const calculatePriceOrderItems = (
  order_items = [],
  order,
  is_discount_price = false,
  is_shipping_cost = false,
) => {
  if (order_items && typeof order_items === 'object') {
    let total_price = toDecimal(
      order_items.reduce(
        (x, y) => x + toDecimal(Number(y.attributes.price) * Number(y.attributes.quantity), 2),
        0,
      ),
      2,
    );
    const estimate_shipping_cost =
      order.attributes.estimate_shipping_cost == null
        ? 0
        : Number(order.attributes.estimate_shipping_cost);
    if (is_shipping_cost) {
      total_price = total_price + estimate_shipping_cost;
    }
    if (is_discount_price) {
      const discount_price =
        order.attributes.discount_price == null ? 0 : Number(order.attributes.discount_price);
      total_price = total_price - discount_price;
    }
    return total_price;
  }

  return 0;
};

export const onChangePeriodDate = (value, values) => {
  const moment = require('moment');
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });
  values.value = value;
  let filterDate = null;
  switch (values.id) {
    case 0:
      filterDate = [moment(new Date()).startOf('day'), moment(new Date()).endOf('day')];
      break;
    case 1:
      filterDate = [
        moment(new Date()).subtract(1, 'd').startOf('day'),
        moment(new Date()).subtract(1, 'd').endOf('day'),
      ];
      break;
    case 2: {
      const start_date = moment(new Date()).weekday(0).startOf('day');
      const end_date = moment(new Date(start_date)).add(6, 'd').endOf('day');
      filterDate = [start_date, end_date];
      break;
    }
    case 3: {
      const _weeks = moment(new Date()).weeks();
      const start_date = moment(new Date())
        .weeks(_weeks - 1)
        .weekday(0);
      const end_date = moment(new Date(start_date)).add(6, 'd').endOf('day');
      filterDate = [start_date, end_date];
      break;
    }
    case 4:
      filterDate = [moment(new Date()).startOf('month'), moment(new Date()).endOf('month')];
      break;
    case 5:
      filterDate = [
        moment(new Date()).subtract(1, 'M').startOf('month'),
        moment(new Date()).subtract(1, 'M').endOf('month'),
      ];
      break;
    case 6:
      filterDate = [
        moment(new Date()).subtract(29, 'd').startOf('day'),
        moment(new Date()).endOf('day'),
      ];
      break;
    case 7:
      filterDate = [
        moment(new Date()).month(0).startOf('month').startOf('day'),
        moment(new Date()).month(2).endOf('month').endOf('day'),
      ];
      break;
    case 8:
      filterDate = [
        moment(new Date()).month(3).startOf('month').startOf('day'),
        moment(new Date()).month(5).endOf('month').endOf('day'),
      ];
      break;
    case 9:
      filterDate = [
        moment(new Date()).month(6).startOf('month').startOf('day'),
        moment(new Date()).month(8).endOf('month').endOf('day'),
      ];
      break;
    case 10:
      filterDate = [
        moment(new Date()).month(9).startOf('month').startOf('day'),
        moment(new Date()).month(11).endOf('month').endOf('day'),
      ];
      break;
    case 11:
      filterDate = [
        moment(new Date()).subtract(6, 'M').startOf('month'),
        moment(new Date()).subtract(1, 'M').endOf('month'),
      ];
      break;
    case 12:
      filterDate = [
        moment(new Date()).subtract(12, 'M').startOf('month'),
        moment(new Date()).subtract(1, 'M').endOf('month'),
      ];
      break;
    case 13:
      filterDate = [moment(new Date()).startOf('year'), moment(new Date()).endOf('year')];
      break;
    case 14:
      filterDate = [
        moment(new Date()).subtract(1, 'y').startOf('year'),
        moment(new Date()).subtract(1, 'y').endOf('year'),
      ];
      break;
    case 15:
      filterDate = null;
      break;
    default:
      filterDate = null;
      break;
  }
  return filterDate;
};

export const get_url_product = (product) => {
  const newParams = {
    filters: {
      // id: {
      //   $eq: id,
      // },
      id: {
        $eq: product.id,
      },
    },
  };
  const query = paramToquery(newParams);
  return `/app/apps/products/product-list?q=${encryptString(query)}`;
};

export const getLocaleView = () => {
  const { theme } = store.getState();
  const { locale } = theme;
  const locales = {
    th: localeTH,
    en: localeenUS,
  };
  return { locale, locales, localeLang: locales[locale] ?? localeTH };
};

export const toThaiDateString = (date, Time_zone, str = '/', formDate) => {
  const { locale, locales } = getLocaleView();

  if (!Time_zone) {
    Time_zone = GetUserInfo('timeZone');
  }
  return format(
    utcToZonedTime(new Date(date), Time_zone),
    formDate ? formDate : `d${str}MMM${str}yyyy`,
    {
      timeZone: Time_zone,
      locale: locales[locale] ?? localeTH,
    },
  );
};

export const get_url_customer = (id) => {
  const newParams = {
    filters: {
      id: {
        $eq: id,
      },
    },
  };
  const query = paramToquery(newParams);
  const url = `/app/apps/customers/customer-list?q=${encryptString(query)}`;
  return url;
};

export const formLocations = (dataLocations) => {
  const data = dataLocations;
  const locations = [];
  const building = [];
  const floor = [];
  const zone = [];
  const locationAll = data.find(
    (f) => f.attributes.parent_location === -1 && f.attributes.location_type_id === 0,
  );
  if (!locationAll) {
    return {
      building,
      floor,
      zone,
      locations,
      locationAll,
    };
  }
  const allL = {
    id: locationAll.id,
    value: locationAll.id,
    location_type_id: locationAll.attributes.location_type_id,
    parent_location: locationAll.attributes.parent_location,
    sug: locationAll.attributes.sug,
    sug_group: locationAll.attributes.sug_group,
    label: getTranslate('all'),
    name: getTranslate('all'),
  };
  building.push(allL);
  floor.push(allL);
  zone.push(allL);

  data.map((m) => {
    if (m.attributes.location_type_id === 1) {
      building.push({
        id: m.id,
        value: m.id,
        location_type_id: m.attributes.location_type_id,
        parent_location: m.attributes.parent_location,
        sug: m.attributes.sug,
        sug_group: m.attributes.sug_group,
        label: m.attributes.name,
        name: m.attributes.name,
      });
    } else if (m.attributes.location_type_id === 2) {
      floor.push({
        id: m.id,
        value: m.id,
        location_type_id: m.attributes.location_type_id,
        parent_location: m.attributes.parent_location,
        sug: m.attributes.sug,
        sug_group: m.attributes.sug_group,
        label: m.attributes.name,
        name: m.attributes.name,
      });
    } else if (m.attributes.location_type_id === 3) {
      zone.push({
        id: m.id,
        value: m.id,
        location_type_id: m.attributes.location_type_id,
        parent_location: m.attributes.parent_location,
        sug: m.attributes.sug,
        sug_group: m.attributes.sug_group,
        label: m.attributes.name,
        name: m.attributes.name,
      });
    }
  });

  building.forEach((val) => {
    const list_2 =
      val.parent_location === -1 && val.location_type_id === 0
        ? floor
        : floor.filter(
            (f) =>
              f.parent_location === val.id ||
              (f.parent_location === -1 && f.location_type_id === 0),
          );
    let category_2 = [];
    list_2.forEach((val_2) => {
      const list_3 =
        val_2.parent_location === -1 && val_2.location_type_id === 0
          ? zone
          : zone.filter(
              (f) =>
                f.parent_location === val_2.id ||
                (f.parent_location === -1 && f.location_type_id === 0),
            );
      category_2.push({
        ...val_2,
        children: list_3,
      });
    });
    locations.push({
      ...val,
      children: category_2,
    });
  });

  return {
    building,
    floor,
    zone,
    locations,
    locationAll: allL,
  };
};

export const checkUrlMenu = ({ pathname }, tabMenuId) => {
  let isBreak = false;
  let menuId = 1;
  if (pathname.includes('/app/staff')) {
    return 4;
  }
  Object.keys(tabMenuId).map((key) => {
    for (let i = 0; i < tabMenuId[key].length; i++) {
      if (isBreak) {
        break;
      }
      if (pathname.includes(tabMenuId[key][i])) {
        menuId = key == 'home' ? 1 : key == 'setting' ? 2 : key == 'nfc' ? 3 : 1;
        isBreak = true;
      }
    }
    if (isBreak) {
      return;
    }
  });
  return menuId;
};

export const useAxiosFetchGraphql = (graphqlQuery, refresh = false, isTimeseries = false) => {
  const [data, setData] = useState(null);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const Time_zone = GetUserInfo('timeZone');

  // const headers = {
  //   Authorization: `Bearer ${process.env.REACT_APP_ACCKEE_TOKEN}`,
  // };

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();

    const getData = async () => {
      setLoading(true);
      delete axios.defaults.headers.common['api_key'];
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${process.env.REACT_APP_ACCKEE_TOKEN}`;
      await axios
        .post(process.env.REACT_APP_URL_ACCKEE_API, graphqlQuery, {
          cancelToken: source.token,
          headers: {
            'time-zone': Time_zone,
            'time-series': isTimeseries,
          },
        })
        .then((res) => {
          if (!unmounted) {
            // @ts-ignore
            if (res.data?.data) {
              setData(res.data.data);
            } else {
              setData(res.data);
            }
            setStatus(res.status);
            setLoading(false);
          }
        })
        .catch(function (err) {
          if (!unmounted) {
            setError(true);
            setStatus(400);
            setErrorMessage(err.message);
            setLoading(false);
            if (axios.isCancel(err)) {
              console.log(`request cancelled:${err.message}`);
            } else {
              console.log('another error happened:' + err.message);
            }
          }
        });
      axios.defaults.headers.common['api_key'] = process.env.REACT_APP_API_KEY;
      axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
    };

    if (graphqlQuery !== null) {
      getData();
    }

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [graphqlQuery, refresh]);

  return { data, isLoading, isError, errorMessage, status };
};

export const COLORS = (quantity = 1000) => {
  const RandomColours = () => {
    var hue = Math.floor(Math.random() * 360);
    var saturation = Math.floor(Math.random() * 60) + 20;
    var lightness = Math.floor(Math.random() * 20) + 60;
    var pastel = 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
    return pastel;
  };

  const Colors = [];
  for (let i = 0; i < quantity; i++) {
    Colors.push(RandomColours());
  }
  return Colors.sort((a, b) => 0.5 - Math.random());
};

export const convertKBtoMB = (kb) => {
  return parseFloat((kb * 0.0009765625).toFixed(2));
};

export const checkQuotaPackage = async (dataPackage, companie_id) => {
  let quota = null;
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
    // package.count
    const res = companie_id
      ? await axios.get(REACT_APP_API + `/api/companie/myPackage/${companie_id}`).catch(() => {})
      : null;

    if ((companie_id && res.status === 200 && res.data?.data) || dataPackage) {
      if (companie_id) {
        dataPackage = res.data.data;
      }
      if (dataPackage?.package_id) {
        quota = dataPackage;
      }
    }
    return quota;
  } catch (e) {
    return quota;
  }
};

export const diffDays = (date_1, date_2) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

export const diff_year_month_day = (date1, date2) => {
  /*
   * calcDate() : Calculates the difference between two dates
   * @date1 : "First Date in the format MM-DD-YYYY"
   * @date2 : "Second Date in the format MM-DD-YYYY"
   * return : Array
   */

  //new date instance
  const dt_date1 = new Date(date1);
  const dt_date2 = new Date(date2);

  //Get the Timestamp
  const date1_time_stamp = dt_date1.getTime();
  const date2_time_stamp = dt_date2.getTime();

  let calc;

  //Check which timestamp is greater
  if (date1_time_stamp > date2_time_stamp) {
    calc = new Date(date1_time_stamp - date2_time_stamp);
  } else {
    calc = new Date(date2_time_stamp - date1_time_stamp);
  }
  //Retrieve the date, month and year
  const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
  //Convert to an array and store
  const calcFormat = calcFormatTmp.split('-');
  //Subtract each member of our array from the default date
  const days_passed = Number(Math.abs(calcFormat[0]) - 1);
  const months_passed = Number(Math.abs(calcFormat[1]) - 1);
  const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

  //Set up custom text
  // const yrsTxt = ['year', 'years'];
  // const mnthsTxt = ['month', 'months'];
  // const daysTxt = ['day', 'days'];
  const yrsTxt = ['ปี', 'ปี'];
  const mnthsTxt = ['เดือน', 'เดือน'];
  const daysTxt = ['วัน', 'วัน'];

  //Convert to days and sum together
  const total_days = years_passed * 365 + months_passed * 30.417 + days_passed;
  const total_secs = total_days * 24 * 60 * 60;
  const total_mins = total_days * 24 * 60;
  const total_hours = total_days * 24;
  const total_weeks = total_days >= 7 ? total_days / 7 : 0;

  //display result with custom text
  const result =
    (years_passed == 1
      ? years_passed + ' ' + yrsTxt[0] + ' '
      : years_passed > 1
      ? years_passed + ' ' + yrsTxt[1] + ' '
      : '') +
    (months_passed == 1
      ? months_passed + ' ' + mnthsTxt[0] + ' '
      : months_passed > 1
      ? months_passed + ' ' + mnthsTxt[1] + ' '
      : '') +
    (days_passed == 1
      ? days_passed + ' ' + daysTxt[0]
      : days_passed > 1
      ? days_passed + ' ' + daysTxt[1]
      : '');

  //return the result
  const resultText = result.trim();
  return {
    total_days: Math.round(total_days),
    total_weeks: Math.round(total_weeks),
    total_hours: Math.round(total_hours),
    total_minutes: Math.round(total_mins),
    total_seconds: Math.round(total_secs),
    result: resultText == '' ? '' : `( ${resultText} )`,
  };
};

export const saveAs = (content, fileName) => {
  const a = document.createElement('a');
  const isBlob = content.toString().indexOf('Blob') > -1;
  let url = content;
  if (isBlob) {
    url = window.URL.createObjectURL(content);
  }
  a.href = url;
  a.download = fileName;
  a.click();
  if (isBlob) {
    window.URL.revokeObjectURL(url);
  }
};

export const removeTags = (str) => {
  if (str === null || str === '') return '';
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const getUrlLink = (link, domain_link, path_url_link, typeId) => {
  // Redirect https://waan-to.co/ชื่อลิงก์/
  // Page https://ชื่อโดเมนย่อย.waan-to.co/
  if (path_url_link) {
    if (typeId === 1) {
      // link.attributes.domain_link ||
      return `${`https://${process.env.REACT_APP_Domain_Short_Linke}/`}${path_url_link ?? ''}`;
    } else if (typeId === 2 || typeId === 3) {
      return `https://${path_url_link ?? ''}.${process.env.REACT_APP_Domain_Short_Linke}`;
    }
  }
  if (link?.attributes?.path_url_link) {
    typeId = link?.attributes?.typeId;
    if (typeId === 1) {
      // link.attributes.domain_link ||
      return `${`https://${process.env.REACT_APP_Domain_Short_Linke}/`}${
        link.attributes.path_url_link ?? ''
      }`;
    } else if (typeId === 2 || typeId === 3) {
      return `https://${link.attributes.path_url_link ?? ''}.${
        process.env.REACT_APP_Domain_Short_Linke
      }`;
    }
  } else {
    return ``;
  }
  return ``;
};

export const messageErrorShow = (response, isAdd = true) => {
  const messageError = response?.data?.error?.message ?? '';
  const messageObl = {
    'path_url_link badWords': 'the_URL_link_contains_inappropriate_words',
    'path_url_link already exists': 'url_already_exists',
    'quota is full': 'quota_is_full',
    'package expire': 'package_expire',
    'path_url_card already exists': 'url_already_exists',
    'path_url_card badWords': 'the_URL_link_contains_inappropriate_words',
  };
  if (messageObl[messageError]) {
    messagePopUp.error(getTranslate(messageObl[messageError]));
  } else {
    if (isAdd) {
      messagePopUp.error(getTranslate('addFail'));
    } else {
      messagePopUp.error(getTranslate('upgradeFail'));
    }
  }
};

export const isValidUrlProtocol = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const fetchCheckPathUrlLink = async (
  path_url_link,
  redirect_url_link,
  companie_id,
  nfc_id,
  typeId,
) => {
  let urlCheck = `${
    process.env.REACT_APP_API
  }/api/nfc/checkPathUrlLink?path_url_link=${path_url_link}&companie=${companie_id}&typeId=${typeId}${
    nfc_id ? `&nfc_id=${nfc_id}` : ``
  }`;
  if (redirect_url_link)
    urlCheck = `${
      process.env.REACT_APP_API
    }/api/nfc/checkPathUrlLink?redirect_url_link=${redirect_url_link}&companie=${companie_id}&typeId=${typeId}${
      nfc_id ? `&nfc_id=${nfc_id}` : ``
    }`;
  return await axios
    .get(urlCheck)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return 'other';
    });
};

export function fetchCheckNfc(
  myTimeout,
  path_url_link,
  redirect_url_link,
  companie_id,
  nfc_id,
  typeId,
) {
  if (path_url_link) {
    if (path_url_link.trim() == '' || path_url_link.length < 5) {
      return false;
    }
    return new Promise((resolve) => {
      myTimeout = setTimeout(async () => {
        const CheckPathUrlLink = await fetchCheckPathUrlLink(
          path_url_link,
          null,
          companie_id,
          nfc_id,
          typeId,
        );
        resolve(CheckPathUrlLink ?? 'other');
      }, 2000);
    });
  } else if (redirect_url_link) {
    if (!isValidUrlProtocol(redirect_url_link)) {
      return false;
    }
    return new Promise((resolve) => {
      myTimeout = setTimeout(async () => {
        const CheckPathUrlLink = await fetchCheckPathUrlLink(
          null,
          redirect_url_link,
          companie_id,
          nfc_id,
          typeId,
        );
        resolve(CheckPathUrlLink ?? 'other');
      }, 2000);
    });
  }
}

export const getAdminRightsName = (admin_rights) => {
  const rightsName = {
    0: getTranslate('owner'),
    1: getTranslate('manager'),
    2: getTranslate('team_leader'),
    3: getTranslate('team_members'),
    4: getTranslate('data_analyst'),
  };
  if (rightsName[admin_rights]) {
    return rightsName[admin_rights];
  }
  return '';
};

export const GetDfaultUrlImgProfile = (value) => {
  if (
    value &&
    typeof value === 'object' &&
    value.hasOwnProperty('Path_img_avatar') &&
    value.hasOwnProperty('Img_avatar')
  ) {
    if (checkNull(value.Path_img_avatar) && checkNull(value.Img_avatar)) {
      return `${process.env.REACT_APP_PathCDN}/${
        value.Path_img_avatar === 'avatar/' ? 'avatar' : value.Path_img_avatar
      }/${value.Img_avatar}`;
    }
  }
  return `${process.env.REACT_APP_PathCDN}/avatar/default.svg`;
};

export const GetDfaultCompanieImg = (value, defaultImg) => {
  if (value && typeof value === 'object') {
    if (checkNull(value?.attributes?.pic_path) && checkNull(value?.attributes?.pic_name)) {
      return `${process.env.REACT_APP_PathCDN}/${value.attributes.pic_path}/${value.attributes.pic_name}`;
    }
  }
  if (defaultImg !== undefined) {
    return defaultImg;
  }
  return `${process.env.REACT_APP_PathCDN}/avatar/business-default.svg`;
};

export const OrderDetailsGetTotalPrice = (dataOrder) => {
  const price_type = dataOrder.attributes?.price_type ?? 0;
  const price = dataOrder.attributes?.price ?? 0;
  let total_price = 0;
  switch (price_type) {
    case 1:
      total_price = price;
      break;
    case 2:
      total_price = price * 6;
      break;
    case 3:
      total_price = price * 12;
      break;
    default:
      break;
  }
  return total_price;
};

export const getCheckQuotaLink = (companie_id, nfc_id) => {
  const [data, setData] = useState(null);
  const Time_zone = GetUserInfo('timeZone');

  useEffect(() => {
    const getData = async () => {
      setData(null);
      axios.defaults.headers.common['api_key'] = process.env.REACT_APP_API_KEY;
      axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
      await axios
        .post(
          process.env.REACT_APP_API + `/api/nfc/checkQuotaLink/${companie_id}/${nfc_id}`,
          {
            data: {},
          },
          {
            headers: {
              'time-zone': Time_zone,
            },
          },
        )
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {});
    };
    if (companie_id && nfc_id) {
      getData();
    }
  }, [companie_id, nfc_id]);

  return data;
};

export const newGroupId = (change, focusIdx, groupId) => {
  setTimeout(() => {
    const shadowRoot = document.getElementById('VisualEditorEditMode').shadowRoot;
    const elGroupId = shadowRoot.querySelectorAll(`[id="${groupId}"]`);
    if (elGroupId.length > 1) {
      const newGroupId = Math.floor(Math.random() * 10000);
      change(`${focusIdx}.data.value.groupId`, newGroupId);
    }
  }, 100);
};

export const newLogActivity = async (data) => {
  axios.defaults.headers.common['api_key'] = process.env.REACT_APP_API_KEY;
  axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
  await axios
    .post(process.env.REACT_APP_API + `/api/log-activities`, {
      data: data,
    })
    .then(() => {})
    .catch(() => {});
};

export const getUrlCard = (urlLink, path) => {
  return `${urlLink}/${path}`;
};

export const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const pdfMakeFonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
  // Roboto: {
  //   normal: 'Roboto-Regular.ttf',
  //   bold: 'Roboto-Medium.ttf',
  //   italics: 'Roboto-Italic.ttf',
  //   bolditalics: 'Roboto-MediumItalic.ttf',
  // },
  Itim: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/Itim/Itim-Regular.ttf`,
  },
  Kanit: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/Kanit/Kanit-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/Kanit/Kanit-Bold.ttf`,
    italics: `${process.env.REACT_APP_PATHWEB}/fonts/Kanit/Kanit-Italic.ttf`,
    bolditalics: `${process.env.REACT_APP_PATHWEB}/fonts/Kanit/Kanit-BoldItalic.ttf`,
  },
  Lato: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/Lato/Lato-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/Lato/Lato-Bold.ttf`,
    italics: `${process.env.REACT_APP_PATHWEB}/fonts/Lato/Lato-Italic.ttf`,
    bolditalics: `${process.env.REACT_APP_PATHWEB}/fonts/Lato/Lato-BoldItalic.ttf`,
  },
  NotoSansThai: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/NotoSansThai/NotoSansThai-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/NotoSansThai/NotoSansThai-Bold.ttf`,
  },
  OpenSans: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/OpenSans/OpenSans-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/OpenSans/OpenSans-Bold.ttf`,
    italics: `${process.env.REACT_APP_PATHWEB}/fonts/OpenSans/OpenSans-Italic.ttf`,
    bolditalics: `${process.env.REACT_APP_PATHWEB}/fonts/OpenSans/OpenSans-BoldItalic.ttf`,
  },
  Prompt: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/Prompt/Prompt-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/Prompt/Prompt-Bold.ttf`,
    italics: `${process.env.REACT_APP_PATHWEB}/fonts/Prompt/Prompt-Italic.ttf`,
    bolditalics: `${process.env.REACT_APP_PATHWEB}/fonts/Prompt/Prompt-BoldItalic.ttf`,
  },
  Roboto: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/Roboto/Roboto-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/Roboto/Roboto-Bold.ttf`,
    italics: `${process.env.REACT_APP_PATHWEB}/fonts/Roboto/Roboto-Italic.ttf`,
    bolditalics: `${process.env.REACT_APP_PATHWEB}/fonts/Roboto/Roboto-BoldItalic.ttf`,
  },
  Sarabun: {
    normal: `${process.env.REACT_APP_PATHWEB}/fonts/Sarabun/Sarabun-Regular.ttf`,
    bold: `${process.env.REACT_APP_PATHWEB}/fonts/Sarabun/Sarabun-Bold.ttf`,
    italics: `${process.env.REACT_APP_PATHWEB}/fonts/Sarabun/Sarabun-Italic.ttf`,
    bolditalics: `${process.env.REACT_APP_PATHWEB}/fonts/Sarabun/Sarabun-BoldItalic.ttf`,
  },
};

export const ruleValidHTMLTag = [
  () => ({
    validator: async (_, val) => {
      if (val && isValidHTMLTag(val)) {
        return Promise.reject(getTranslate('unable_to_insert_HTML_tags'));
      }
      return Promise.resolve();
    },
  }),
];

function isValidHTMLTag(str) {
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp(/<(\"[^\"]*\"|'[^']*'|[^'\">])*>/);

  if (str == null) {
    return false;
  }

  if (regex.test(str) == true) {
    return true;
  } else {
    return false;
  }
}

export const getCookieRedirect = (isReMove = false) => {
  const COOKIE_REDIRECT = 'redirect_url';
  if (isReMove) {
    Cookies.remove(COOKIE_REDIRECT, { ...cookieAttributes });
    return null;
  }

  const cookieStr = Cookies.get(COOKIE_REDIRECT);
  if (cookieStr != null && cookieStr != undefined) {
    try {
      let bytes = CryptoJS.AES.decrypt(cookieStr, REACT_APP_SecretKey);
      let plaintext = bytes.toString(CryptoJS.enc.Utf8);
      let json = JSON.parse(plaintext);
      Cookies.remove(COOKIE_REDIRECT, { ...cookieAttributes });
      return json.redirect;
    } catch (err) {
      Cookies.remove(COOKIE_REDIRECT, { ...cookieAttributes });
      return null;
    }
  }
  Cookies.remove(COOKIE_REDIRECT, { ...cookieAttributes });
  return null;
};

export const cleanCookieAuth = (removeAuth = true, removeUser = true) => {
  if (removeAuth) {
    Cookies.remove(AUTH_TOKEN, { ...cookieAttributes });
  }
  if (removeUser) {
    Cookies.remove(WAAN_UIN, { ...cookieAttributes });
  }
};
let checkBlockType = false;
export const checkBlockTypePageBuilder = (data, arrStr = [], isFirst = true) => {
  if (isFirst) {
    checkBlockType = false;
    if (data == null) return checkBlockType;
  } else {
    if (checkBlockType) return;
  }
  if (!isFirst && data == null) {
    return;
  }

  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      data.forEach((value) => {
        checkBlockTypePageBuilder(value, arrStr, false);
      });
    } else {
      if (data.hasOwnProperty('type')) {
        checkBlockTypePageBuilder(data['type'], arrStr, false);
      }
      if (data.hasOwnProperty('children')) {
        checkBlockTypePageBuilder(data['children'], arrStr, false);
      }
    }
  } else if (typeof data === 'string') {
    if (data && arrStr.includes(data)) {
      checkBlockType = true;
    }
  }
  if (isFirst) return checkBlockType;
};

export const checkCompanieStaff = (companies, userInfo) => {
  const { companie_id, isAdmin = false } = userInfo;
  let isStatus = false;
  let isUpdate = false;
  if (isAdmin) {
    return {
      isUpdate,
      userInfo,
    };
  }
  if (companie_id) {
    const companie = companies.find((companie) => companie.id === companie_id);
    if (companie) {
      const { staff_admin } = companie.attributes;
      if (staff_admin?.attributes.enable === true && staff_admin?.attributes.status === true) {
        isStatus = true;
      }
    }
  }
  if (!isStatus) {
    const companie = companies.find(
      (companie) =>
        companie.id !== companie_id &&
        companie.attributes?.staff_admin?.attributes.enable === true &&
        companie.attributes?.staff_admin?.attributes.status === true,
    );
    if (companie) {
      userInfo = {
        ...userInfo,
        companie_id: companie.id,
        companie_name: companie.attributes.name,
        companie_uid: companie.attributes.uid,
        admin_rights: companie.attributes.staff_admin.attributes.admin_rights,
      };
    } else {
      userInfo = {
        ...userInfo,
        companie_id: null,
        companie_name: null,
        companie_uid: null,
        admin_rights: null,
      };
    }
    isUpdate = true;
  }

  return {
    isUpdate: isUpdate,
    userInfo: { ...userInfo, isAdmin: false },
  };
};

export const checkStringEqualTo = (val) => {
  if (val && typeof val === 'string') {
    if (val[0] === '=') {
      return val.substring(1);
    }
  }
  return val;
};

export async function fetchUsersList(value) {
  const newParams = {
    order: 'AutoID|DESC',
    page: 0,
    pageSize: 10000,
  };
  if (value) {
    // newParams.search = value;
  }
  axios.defaults.headers.common['api_key'] = process.env.REACT_APP_API_KEY;
  axios.defaults.headers.common['Authorization'] = `Bearer ` + getCookieToken();
  return await axios
    .post(process.env.REACT_APP_API_AUTH + '/api/Staff/GetAllUserWaan', {
      ...newParams,
    })
    .then((res) => {
      return res?.data.Result ?? [];
    })
    .catch(() => {
      return [];
    });
}

export const checkPackageFreeExceedQuotaAndExpire = (
  typeId,
  dataPackage,
  is_digital_business_card = false,
) => {
  const resultExpire = {
    status: true,
    is_expire: true,
    message: getTranslate('can_t_edit_link_expire'),
  };
  const resultExceedQuota = {
    status: true,
    is_expire: false,
    message: getTranslate(
      'unable_to_save_and_edit_work_because_you_have_exceeded_the_package_limit',
    ),
  };
  const resultSuccess = {
    status: false,
    is_expire: false,
    message: '',
  };
  if (!typeId || !dataPackage) {
    return resultSuccess;
  }
  const { package_id, count, Is_business_card, package: packageDes } = dataPackage;
  if (package_id) {
    if (package_id !== 1) {
      if (dataPackage.is_expire) {
        return resultExpire;
      }
      return resultSuccess;
    } else {
      if (dataPackage.is_expire) {
        return resultExpire;
      }
    }
  } else {
    return resultExpire;
  }

  const { link, page, business_card, digital_business_card } = count;
  const { Q_Link, Q_Page, Q_Business_Card, Q_Digital_Business_Card } = packageDes;

  switch (typeId) {
    case 1: {
      if (link && Q_Link >= link) {
        return resultSuccess;
      }
      break;
    }
    case 2: {
      if (page && Q_Page >= page) {
        return resultSuccess;
      }
      break;
    }
    case 3: {
      if (Is_business_card) {
        if (business_card && Q_Business_Card >= business_card) {
          if (is_digital_business_card) {
            if (digital_business_card && Q_Digital_Business_Card >= digital_business_card) {
              return resultSuccess;
            }
          } else {
            return resultSuccess;
          }
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  return resultExceedQuota;
};

export function isNum(v) {
  return /\d/.test(v);
}
