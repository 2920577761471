import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { Drawer, Form, Input, Card, Row, Col, Button, Select, message, Popconfirm } from 'antd';
import {
  getTranslate,
  GetUserInfo,
  customLabel,
  getMessage,
  getplaceholder,
  checkQuotaPackage,
} from 'components/CustomService';
import { staffListContext } from 'components/waan-components/use/createContext';
import { defaultAdminRights } from 'views/app-views/settings/staffList/staff-list';

const { Option } = Select;

export default function AddStaff() {
  const userInfo = GetUserInfo();

  const companie_id = userInfo.companie_id;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkQuota, setCheckQuota] = useState(true);
  const [quotaExpire, setQuotaExpire] = useState(false);

  const { visible, setVisible, refresh, setRefresh } = useContext(staffListContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setCheckQuota(true);
      setQuotaExpire(false);
    }
  }, [visible]);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    setCheckQuota(true);
    setQuotaExpire(false);

    const getQuotaPackage = await checkQuotaPackage(null, companie_id);
    if (!getQuotaPackage || getQuotaPackage.is_expire) {
      setQuotaExpire(true);
      setSubmitLoading(false);
      return;
    } else if (getQuotaPackage.checkQuotaFull.isInviteModeratorsFull) {
      setCheckQuota(false);
      setSubmitLoading(false);
      return;
    }

    const result = await CheckUserbyEmail(values.email);

    if (result) {
      await axios
        .post(process.env.REACT_APP_API + `/api/staff-admin/companie`, {
          data: {
            ...values,
            enable: true,
            invite_by_user_id: userInfo.autoID,
            companie: companie_id,
            created_by_userId: userInfo.autoID,
            updated_by_userId: userInfo.autoID,
            invite_user_id: result.status ? result.AutoID : null,
          },
        })
        .then(async (res) => {
          await axios.post(process.env.REACT_APP_API + `/api/coreemail/send_mail_an_invitation`, {
            data: {
              to_email: values.email,
              companie_id: companie_id,
              companie_name: userInfo.companie_name,
              fullname: userInfo.displayname,
              staff_id: res.data.data.id,
              to_firstname: values.firstname == null ? '' : values.firstname,
              to_lastname: values.lastname == null ? '' : values.lastname,
              admin_rights: values.admin_rights,
            },
          });
          message.success(getTranslate('savedSuccess'));
          setVisible(false);
          setRefresh(!refresh);
        })
        .catch(() => {
          message.error(getTranslate('savedFail'));
        });
    } else {
      message.error(getTranslate('savedFail'));
    }
    setSubmitLoading(false);
  };

  async function CheckUserbyEmail(email) {
    const dataCheck = await axios
      .post(process.env.REACT_APP_API_AUTH + `/api/Member/CheckUserbyEmail`, {
        Email: email,
      })
      .catch(() => {});
    if (dataCheck.data.Code === 200) {
      return dataCheck.data.Result;
    } else if (dataCheck.data.Code === 500 && dataCheck.data.Message_Local != null) {
      if (
        dataCheck.data.Result.AutoID === 0 &&
        dataCheck.data.Message_Local === 'api.checkuserbyemail.error.user.notfound'
      ) {
        return dataCheck.data.Result;
      } else {
        message.error(getTranslate(dataCheck.data.Message_Local));
      }
    }
    return null;
  }

  return (
    <div className="page-staff-add">
      <Drawer
        width={640}
        className="drawer-add-staff"
        title={getTranslate('invite_moderators')}
        placement="right"
        getContainer={false}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}>
        <div className="drawer-body-bg">
          <Row gutter={16}>
            <Col span={24}>
              <Form
                layout={'vertical'}
                form={form}
                initialValues={{ layout: 'vertical', remember: true }}
                onFinish={onFinish}>
                <Card className="card-add-customer-info w-100">
                  <Form.Item
                    required={false}
                    rules={[{ required: true, min: 0, message: getMessage('firstname') }]}
                    name="firstname"
                    label={customLabel('name', true)}
                    className="d-inline-block w-50 pr-3">
                    <Input placeholder={getTranslate('placeholder_Name')} maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    rules={[{ min: 0, message: getMessage('lastname') }]}
                    name="lastname"
                    label={customLabel('lastname')}
                    className="d-inline-block w-50">
                    <Input placeholder={getTranslate('placeholder_LastName')} maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: getTranslate('validateEmail1'),
                      },
                      {
                        type: 'email',
                        message: getTranslate('validateEmail2'),
                      },
                    ]}
                    name="email"
                    label={customLabel('email', true)}>
                    <Input placeholder={getTranslate('placeholder_Email')} maxLength={255} />
                  </Form.Item>
                </Card>
                <Card className="card-add-customer-info w-100">
                  <Form.Item
                    required={false}
                    rules={[{ required: true, message: getplaceholder('admin_rights', 2) }]}
                    name="admin_rights"
                    label={customLabel('admin_rights', true)}>
                    <Select
                      className="w-100"
                      showSearch
                      style={{ width: 200 }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={getplaceholder('admin_rights', 2)}>
                      {defaultAdminRights.map((m) => (
                        <Option key={m.id} value={m.id} name={getTranslate(m.locale)}>
                          {getTranslate(m.locale)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {!checkQuota && !quotaExpire && (
                    <div className="mt-2">
                      <span className="pl-2 font-size-sm text-danger font-weight-normal">
                        {getTranslate('can_t_invite_moderators')}{' '}
                        <a href="/app/profile/package"> {getTranslate('packages_quotas')}</a>
                      </span>
                    </div>
                  )}
                  {quotaExpire && (
                    <div className="mt-2">
                      <span className="pl-2 font-size-sm text-danger font-weight-normal">
                        {getTranslate('can_t_invite_moderators_expire')}{' '}
                        <a href="/app/profile/package"> {getTranslate('packages_quotas')}</a>
                      </span>
                    </div>
                  )}
                </Card>
                <div className="float-right mb-5">
                  <Button className="mr-3" key="back" onClick={() => setVisible(false)}>
                    {getTranslate('cancel')}
                  </Button>
                  <Popconfirm
                    placement="topRight"
                    title={getTranslate('confirmSaveData')}
                    onConfirm={() => {
                      form.submit();
                    }}
                    onCancel={null}>
                    <Button loading={submitLoading} className="btn-primary" type="primary">
                      {getTranslate('record')}
                    </Button>
                  </Popconfirm>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
}
