import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent';
// import { tabMenuId } from 'configs/NavigationConfig';
// import { checkUrlMenu } from 'components/CustomService';

const { Sider } = Layout;

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
  menuId,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization, navCollapsed };
  // let menuId = checkUrlMenu(window.location, tabMenuId);
  return (
    <Sider
      className={`side-nav ${navCollapsed ? '' : ''} ${
        sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''
      }`}
      width={menuId == 3 ? 0 : SIDE_NAV_WIDTH}
      collapsed={navCollapsed}>
      <Scrollbars autoHide>
        <MenuContent type={NAV_TYPE_SIDE} {...props} menuId={menuId}/>
      </Scrollbars>
    </Sider>
  );
};

SideNav.propTypes = {
  navCollapsed: PropTypes.bool,
  sideNavTheme: PropTypes.string,
  routeInfo: PropTypes.object,
  hideGroupTitle: PropTypes.string,
  localization: PropTypes.bool,
  menuId: PropTypes.number,
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
