import React, { useState, useEffect, useMemo } from 'react';
import {
  getTranslate,
  getParams,
  useAxiosFetch,
  GetUserInfo,
  dateFormat,
  paramToquery,
  setUrlParams,
  confirmActionText,
  GetDfaultUrlImgProfile,
} from 'components/CustomService';
import {
  Card,
  Table,
  Row,
  Button,
  Col,
  Tabs,
  Input,
  Dropdown,
  Menu,
  Tag,
  Radio,
  PageHeader,
  Breadcrumb,
  Pagination,
  Space,
  Typography,
  Select,
  Divider,
  Popconfirm,
  message,
  Checkbox,
  Skeleton,
} from 'antd';
import {
  HomeOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  MailFilled,
  SearchOutlined,
  // SettingOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import Flex from 'components/shared-components/Flex';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { staffListContext } from 'components/waan-components/use/createContext';
import AddStaff from '../add-staff';
import ViewStaff from '../view-staff';
import UseRights from 'components/waan-components/use/UseRights';

const { TabPane } = Tabs;
const { Search } = Input;
const { Text } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group;

const defaultSort = 3;
const allowed = [0, 1, 4];
function Index() {
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const userInfo = GetUserInfo();
  const companie_id = userInfo.companie_id;
  const admin_rights = userInfo.admin_rights;
  const isAdmin = userInfo.isAdmin ?? false;
  const { urlParams, urlQuery } = getParams(location.search, firstLoad, { sort: 'updatedAt:desc' });

  const [activeTab, setActiveTab] = useState('1');
  const [visible, setVisible] = useState(false);
  const [listRights, setListRights] = useState(defaultAdminRights);
  const [openSelecterRights, setOpenSelecterRights] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [sortValue, setsortValue] = useState(defaultSort);
  const [searchBy, setSearchBy] = useState('');
  const [listFilter, setListFilter] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [urlDataList, setUrlDataList] = useState(
    companie_id == null ? null : `/api/staff-admin/by_companie_id/${companie_id}?${urlQuery}`,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isFilters, setIsFilters] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [loadProfile, setLoadProfile] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  const isPermission = useMemo(() => {
    return !allowed.includes(admin_rights) && !isAdmin ? false : true;
  }, [allowed, admin_rights, isAdmin]);

  useEffect(() => {
    const sort = urlParams.sort;
    const firstname = urlParams?.filters?.firstname;
    const status = urlParams?.filters?.status?.$eq;
    const list_rights = urlParams?.filters?.admin_rights?.$in;

    let listFilters = [...listFilter];
    let LRights = [...listRights];
    if (status) {
      setActiveTab(status === 'false' ? '2' : '1');
    }
    if (sort) {
      const getsort = defaultListSort.filter((f) => f.filter === sort);
      if (getsort.length > 0) {
        setsortValue(getsort[0].id);
        listFilters = listFilters.filter((f) => f.title !== 'sort');
        listFilters.push({
          id: getsort[0].id,
          title: 'sort',
          name: getsort[0].name,
          translate: getsort[0].translate,
        });
      }
    }
    if (firstname != null) {
      listFilters.push({
        id: null,
        title: 'keywordSearch',
        name: firstname.$containsi,
      });
    }
    if (list_rights != null) {
      LRights.map((val) => {
        if (list_rights.includes(`${val.id}`)) {
          val.checked = true;
          listFilters.push({
            id: Number(val.id),
            title: 'admin_rights',
            name: val.locale,
            filter: val.filter,
            translate: val.translate,
            locale: val.locale,
          });
        }
      });
      setListFilter(listFilters);
    }
    setListRights(LRights);
    setListFilter(listFilters);
    setFirstLoad(false);
  }, []);

  const { data, isLoading } = useAxiosFetch(isPermission ? urlDataList : null, refresh);
  const pagination =
    data?.meta?.pagination == null
      ? { page: pageNumber, pageCount: 1, pageSize: pageSize, total: 0 }
      : data.meta.pagination;
  const all_user = data?.data
    ?.map((userId) => userId.attributes.invite_user_id)
    .filter((userId) => userId != null);

  useEffect(() => {
    if (isFilters) {
      filtersData();
    }
  }, [isFilters]);

  useEffect(() => {
    if (!visibleView) {
      setDataEdit(null);
    }
  }, [visibleView]);

  useEffect(() => {
    if (!isLoading) {
      gatDataProfile();
    }
  }, [isLoading]);

  async function gatDataProfile() {
    if (data.data != null && data.data.length > 0 && all_user != null && all_user.length > 0) {
      setLoadProfile(true);
      await axios
        .post(process.env.REACT_APP_API_AUTH + '/api/Member/GetListProfileUser', [
          ...new Set(all_user),
        ])
        .then((res) => {
          if (res.data.Code === 200) {
            setDataProfile(res.data.Result);
          }
          setLoadProfile(false);
        })
        .catch(function () {
          setLoadProfile(false);
        });
    }
  }

  function filtersData() {
    let listFilters = [...listFilter];
    let filters = {};

    if (activeTab === '2') {
      filters = {
        ...filters,
        status: {
          $eq: false,
        },
      };
    }

    const filterSearchBy = listFilters.filter((f) => f.title === 'keywordSearch');
    if (filterSearchBy.length > 0) {
      filters = {
        ...filters,
        firstname: {
          $containsi: filterSearchBy[0].name,
        },
      };
    }

    const filterRights = listFilters.filter((f) => f.title === 'admin_rights');
    if (filterRights.length > 0) {
      filters = {
        ...filters,
        admin_rights: {
          $in: filterRights.map((m) => m.id),
        },
      };
    }

    let sort = {};
    const filterSort = listFilters.filter((f) => f.title === 'sort');
    if (filterSort.length > 0) {
      sort.sort = filterSort[0].filter;
    }

    const newParams = {
      ...urlParams,
      filters: filters,
      ...sort,
      pagination: {
        page: Number(pageNumber),
        pageSize: Number(pageSize),
      },
    };

    const query = paramToquery(newParams);
    const url = `/api/staff-admin/by_companie_id/${companie_id}?${query}`;
    setIsFilters(false);
    setUrlDataList(url);
    setUrlParams(`/app/setting-shop/staff/staff-list?q=`, query);
  }

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item href={`${APP_PREFIX_PATH}/profile/edit-profile`}>
        <HomeOutlined />
      </Breadcrumb.Item>
      {/* <Breadcrumb.Item href={`${APP_PREFIX_PATH}/setting-shop/store-setting`}>
        <SettingOutlined />
        <span>{getTranslate('sidenav.setting.shop')}</span>
      </Breadcrumb.Item> */}
      <Breadcrumb.Item>{getTranslate('sidenav.setting.shop.staff')}</Breadcrumb.Item>
    </Breadcrumb>
  );

  function onChangeTabs(key) {
    setActiveTab(key);
    setIsFilters(true);
  }

  function onChangeSort(e) {
    let listFilters = [...listFilter];
    const get_id = Number(e.key);
    const getsort = defaultListSort.filter((f) => f.id === get_id);
    listFilters = listFilters.filter((f) => f.title !== 'sort');
    listFilters.push({
      id: getsort[0].id,
      title: 'sort',
      name: getsort[0].name,
      filter: getsort[0].filter,
      translate: getsort[0].translate,
    });
    setListFilter(listFilters);
    setsortValue(get_id);
    setIsFilters(true);
  }

  function onSearchBy(val = searchBy) {
    if (val != null && val.trim() !== '') {
      let listFilters = [...listFilter];
      listFilters = listFilters.filter((f) => f.title !== 'keywordSearch');
      listFilters.push({
        id: null,
        title: 'keywordSearch',
        name: val,
      });
      setListFilter(listFilters);
      setIsFilters(true);
    }
    setSearchBy('');
  }

  function onNameClick(val) {
    const getData = data?.data?.filter((m) => m.id === val.id);
    if (getData.length > 0) {
      setDataEdit(getData[0]);
      setVisibleView(true);
    }
  }

  const menuSort = (
    <Menu openKeys={sortValue} onClick={onChangeSort}>
      <Menu.ItemGroup key="Menu-sortBy" title={getTranslate('sortBy')}>
        {defaultListSort.map((m) => (
          <Menu.Item key={m.id} id={m.id}>
            <Radio checked={sortValue === m.id} />
            {getTranslate(m.name)}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  function clearFilters() {
    let listFilters = [];

    const getsort = defaultListSort.filter((f) => f.id === defaultSort);
    listFilters.push({
      id: getsort[0].id,
      title: 'sort',
      name: getsort[0].name,
      translate: getsort[0].translate,
    });
    let LRights = [...listRights];
    LRights.forEach((val) => {
      val.checked = false;
    });
    setListRights(LRights);
    setsortValue(defaultSort);
    setSearchBy('');
    setListFilter(listFilters);
    setActiveTab('1');
    const newParams = {
      sort: getsort[0].filter,
    };
    const query = paramToquery(newParams);
    const url = `/api/staff-admin/by_companie_id/${companie_id}?${query}`;
    setUrlDataList(url);
    setUrlParams(`/app/setting-shop/staff/staff-list?q=`, query);
  }

  function closTag(val) {
    const value = val.name;
    let listFilters = [...listFilter];
    listFilters = listFilters.filter((f) => f.name !== value);
    if (listFilters.filter((f) => f.title === 'sort').length === 0) {
      const getsort = defaultListSort.filter((f) => f.id === defaultSort);
      listFilters.push({
        id: getsort[0].id,
        title: 'sort',
        name: getsort[0].name,
        translate: getsort[0].translate,
      });
      setsortValue(defaultSort);
    } else if (val.title === 'admin_rights') {
      let LRights = [...listRights];
      LRights.forEach((m) => {
        if (m.locale === value) {
          m.checked = false;
        }
      });
      setListRights(LRights);
    }

    setListFilter(listFilters);
    setIsFilters(true);
  }

  async function saveDelete(record) {
    const invite_user_id = record.attributes.invite_user_id;
    await axios
      .put(process.env.REACT_APP_API + `/api/staff-admin/companie/${record.id}`, {
        data: {
          firstname: record.attributes.firstname,
          lastname: record.attributes.lastname,
          is_deleted: true,
          updated_by_userId: userInfo.autoID,
          companie: companie_id,
        },
      })
      .then(async () => {
        if (invite_user_id) {
          message.success(getTranslate('deleteSuccess'));
        } else {
          message.success(getTranslate('deleteSuccess'));
        }
      })
      .catch(() => {
        message.error(getTranslate('deleteFail'));
      });
    setRefresh(!refresh);
  }

  async function send_mail(record) {
    const gatDataSave = getCaretakerName(record, true);
    await axios
      .post(process.env.REACT_APP_API + `/api/coreemail/send_mail_an_invitation`, {
        data: {
          to_email: gatDataSave.email,
          companie_id: companie_id,
          companie_name: userInfo.companie_name,
          fullname: userInfo.displayname,
          staff_id: gatDataSave.staff_id,
          to_firstname: gatDataSave.firstname,
          to_lastname: gatDataSave.lastname,
          admin_rights: record.attributes.admin_rights,
        },
      })
      .then(() => {
        message.success(getTranslate('savedSuccess'));
      })
      .catch(() => {
        message.error(getTranslate('savedFail'));
      });
  }

  function getCaretakerName(record, isData = false) {
    const invite_user_id = record.attributes.invite_user_id;
    let dataAdmin = {
      name: `${record.attributes.firstname} ${
        record.attributes.lastname ? record.attributes.lastname : ''
      }`,
      subTitle: record.attributes.email,
      firstname: record.attributes.firstname == null ? '' : record.attributes.firstname,
      lastname: record.attributes.lastname == null ? '' : record.attributes.lastname,
      staff_id: record.id,
      email: record.attributes.email,
      src: GetDfaultUrlImgProfile(),
    };
    if (invite_user_id != null) {
      const getProfile = dataProfile.find((f) => f.AutoID === invite_user_id);
      if (getProfile) {
        dataAdmin = {
          name: getProfile.Displayname,
          subTitle: getProfile.Email,
          firstname: getProfile.Firstname,
          lastname: getProfile.Lastname == null ? '' : getProfile.Lastname,
          staff_id: record.id,
          email: getProfile.Email,
          src: GetDfaultUrlImgProfile(getProfile),
        };
      } else {
        dataAdmin.name = getTranslate('the_user_was_not_found_in_the_system');
        dataAdmin.subTitle = '-';
      }
    }
    if (isData) {
      return dataAdmin;
    }
    dataAdmin.name = <a>{dataAdmin.name}</a>;
    return <AvatarStatus size={60} id={record.id} {...dataAdmin} onNameClick={onNameClick} />;
  }

  const columns = [
    {
      title: getTranslate('caretaker_name'),
      render: (_, record) => (
        <div className="d-flex">
          <Skeleton
            loading={record.attributes.invite_user_id == null ? false : loadProfile}
            active
            avatar
            title={false}
            paragraph={{ rows: 2 }}>
            {getCaretakerName(record)}
          </Skeleton>
        </div>
      ),
    },
    {
      title: getTranslate('enable_right'),
      render: (_, record) => (
        <div className="d-flex">
          <Space>
            {record.attributes.enable ? (
              <React.Fragment>
                <CheckCircleFilled className=" waan-blue-color" />
                <Text>{getTextRights(null, 'name', record.attributes.admin_rights)}</Text>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CloseCircleFilled className="text-gray-light" />
                <Text>{getTextRights(null, 'name', record.attributes.admin_rights)}</Text>
              </React.Fragment>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: getTranslate('status'),
      render: (_, record) => (
        <div className="d-flex">
          <Space>
            {record.attributes.status ? (
              <Tag color="green">{getTranslate('participate')}</Tag>
            ) : (
              <React.Fragment>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection={'column'}
                  mobileFlex={false}>
                  <div className="text-center">
                    <Tag className="bg-gray-lighter m-0">
                      {getTranslate('waiting_for_a_response')}
                    </Tag>
                  </div>
                  <div className="text-center">
                    <UseRights allowed={[0]}>
                      <Popconfirm
                        key={record.id}
                        icon={<MailFilled className="text-primary" />}
                        placement="topRight"
                        title={getTranslate('resend_invitation')}
                        onConfirm={() => send_mail(record)}
                        onCancel={null}>
                        <a
                          href="#API"
                          className="font-size-sm px-2"
                          onClick={(e) => {
                            e.preventDefault();
                          }}>
                          {getTranslate('resend_invitation')}
                        </a>
                      </Popconfirm>
                    </UseRights>
                  </div>
                </Flex>
              </React.Fragment>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: getTranslate('last_active'),
      render: (_, record) => (
        <div className="d-flex">{dateFormat(record.attributes.updatedAt)}</div>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      className: 'text-center',
      render: (_, record) => (
        <React.Fragment>
          {(record.attributes.admin_rights !== 0 ||
            (!record.attributes.status && record.attributes.admin_rights === 0)) && (
            <UseRights allowed={[0]}>
              <Popconfirm
                placement="topLeft"
                title={confirmActionText('delete')}
                onConfirm={() => {
                  saveDelete(record);
                }}
                onCancel={null}
                icon={<ExclamationCircleFilled className="text-danger" />}>
                <a href="#API" onClick={(e) => e.preventDefault()}>
                  <Text type="danger">{getTranslate('delete')}</Text>
                </a>
              </Popconfirm>
            </UseRights>
          )}
        </React.Fragment>
      ),
    },
  ];

  function onChangePagination(pageNumber, pageSize) {
    setPageNumber(Number(pageNumber));
    setPageSize(Number(pageSize));
    setIsFilters(true);
  }

  function onBlurRights() {
    let LRights = [...listRights];
    LRights.forEach((val) => {
      if (
        val.checked &&
        listFilter.filter((f) => f.title === 'admin_rights' && f.locale === val.locale).length === 0
      ) {
        val.checked = false;
      } else if (
        !val.checked &&
        listFilter.filter((f) => f.title === 'admin_rights' && f.locale === val.locale).length > 0
      ) {
        val.checked = true;
      }
    });
    setListRights(LRights);
    setOpenSelecterRights(false);
  }

  function onChangeRights(value, option) {
    const get_key = option.key;
    const get_idx = option.idx;
    let LRights = [...listRights];
    if (get_key !== 'search') {
      LRights[get_idx].checked = !LRights[get_idx].checked;
      setListRights(LRights);
    } else {
      let listFilters = [...listFilter];
      listFilters = listFilters.filter((f) => f.title !== 'admin_rights');
      LRights.map((val) => {
        if (val.checked) {
          listFilters.push({
            id: val.id,
            title: 'admin_rights',
            name: val.locale,
            filter: val.filter,
            translate: val.translate,
            locale: val.locale,
          });
        }
      });
      setListFilter(listFilters);
      setIsFilters(true);
      setOpenSelecterRights(false);
    }
  }

  const c_length = listFilter.filter((h) => h.title === 'admin_rights').length;

  const contextValue = {
    getCaretakerName,
    dataEdit,
    visible,
    setVisible,
    visibleView,
    setVisibleView,
    refresh,
    setRefresh,
  };

  return (
    <div className="page-staff-list">
      <PageHeader
        subTitle={breadcrumb}
        extra={[
          <UseRights key="1" allowed={[0]}>
            <Button key="1" className="btn-primary" type="primary" onClick={() => setVisible(true)}>
              {getTranslate('invite_moderators')}
            </Button>
          </UseRights>,
        ]}
      />
      <Card className="card-staff-list">
        <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={onChangeTabs}>
          <TabPane tab={getTranslate('admin')} key="1" />
          <TabPane tab={getTranslate('send_an_invitation')} key="2" />
        </Tabs>
        <Flex
          alignItems="center"
          className="filter-table-list"
          justifyContent="between"
          mobileFlex={false}>
          <div className="w-100 Input-group-search Group-Search-Tag mr-md-3 mb-3">
            <Search
              placeholder={getTranslate('search_for_admin_name')}
              enterButton={
                <React.Fragment>
                  <SearchOutlined className="mr-1" />
                  {getTranslate('search')}
                </React.Fragment>
              }
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              onSearch={onSearchBy}
            />
          </div>
          <div className="mr-md-3 mb-3">
            <ButtonGroup>
              <Select
                showSearch={false}
                className="ant-btn select-admin_rights"
                id="select-admin_rights"
                value={
                  <Text className="pl-3">
                    {getTranslate('admin_rights')} {c_length > 0 ? `(${c_length})` : ''}
                  </Text>
                }
                bordered={false}
                suffixIcon={null}
                dropdownMatchSelectWidth={175}
                placement="bottomRight"
                onBlur={onBlurRights}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    setOpenSelecterRights(open);
                  }
                }}
                open={openSelecterRights}
                onChange={onChangeRights}>
                {listRights.map((val, idx) => (
                  <Option
                    key={`admin_rights-${val.id}`}
                    labal={getTranslate(val.locale)}
                    checked={val.checked}
                    idx={idx}>
                    <Checkbox className="mr-2" checked={val.checked} /> {getTranslate(val.locale)}
                  </Option>
                ))}
                <Option
                  className="text-center p-0 m-0"
                  key={'search'}
                  id={null}
                  labal={null}
                  checked={false}
                  idx={null}>
                  <Divider className="p-0 m-0" />
                  <div className="p-2">
                    <a>{getTranslate('search')}</a>
                  </div>
                </Option>
              </Select>
            </ButtonGroup>
          </div>
          <div className="mb-3">
            <Dropdown overlay={menuSort} placement="bottomRight">
              <Button
                onMouseEnter={() => {
                  setOpenSelecterRights(false);
                }}>
                {getTranslate('sort')}
              </Button>
            </Dropdown>
          </div>
        </Flex>
        <Row>
          <Col>
            {listFilter.length > 0 && (
              <React.Fragment>
                <Tag className="mr-md-2 mb-2" onClick={clearFilters}>
                  <a>{getTranslate('clearAllFilters')}</a>
                </Tag>
                {listFilter.map((val, idx) => (
                  <Tag
                    key={`listFilter-${idx}`}
                    className="mr-md-2 mb-2"
                    onClose={() => closTag(val, idx)}
                    closable={val.title === 'sort' && val.id === 3 ? false : true}
                    visible={true}>
                    {getTranslate(val.title)} : {val.translate ? getTranslate(val.name) : val.name}
                  </Tag>
                ))}
              </React.Fragment>
            )}
          </Col>
        </Row>
        <div className="table-responsive">
          <Table
            className="table-staff-list"
            pagination={false}
            loading={isLoading}
            columns={columns}
            dataSource={data?.data}
            rowKey="id"
          />
        </div>
        <br />
        <Pagination
          className="ant-table-pagination ant-table-pagination-right"
          showSizeChanger
          showLessItems
          showQuickJumper
          defaultCurrent={pagination.page}
          current={pagination.page}
          total={pagination.total}
          pageSize={pageSize}
          onChange={onChangePagination}
        />
      </Card>
      <staffListContext.Provider value={contextValue}>
        <AddStaff />
        <ViewStaff />
      </staffListContext.Provider>
    </div>
  );
}
export default Index;

const defaultListSort = [
  {
    id: 0,
    filter: 'firstname:asc',
    name: 'sortNameAtAsc',
    translate: true,
  },
  {
    id: 1,
    filter: 'firstname:desc',
    name: 'sortNameAtDesc',
    translate: true,
  },
  {
    id: 2,
    filter: 'updatedAt:asc',
    name: 'sortLastActiveDateAsc',
    translate: true,
  },
  {
    id: 3,
    filter: 'updatedAt:desc',
    name: 'sortLastActiveDateDesc',
    translate: true,
  },
];

export const defaultAdminRights = [
  {
    id: 0,
    filter: {
      admin_rights: {
        $eq: 0,
      },
    },
    name: 'owner',
    locale: 'owner',
    translate: true,
    src: '/img/avatars/actor-chaplin-comedy.png',
    checked: false,
  },
  {
    id: 1,
    filter: {
      admin_rights: {
        $eq: 1,
      },
    },
    name: 'manager',
    locale: 'manager',
    translate: true,
    src: '/img/avatars/addicted-draw-love.png',
    checked: false,
  },
  // {
  //   id: 2,
  //   filter: {
  //     admin_rights: {
  //       $eq: 2,
  //     },
  //   },
  //   name: getTranslate('team_leader'),
  //   locale: 'team_leader',
  //   translate: true,
  //   src: '/img/avatars/afro-avatar-male-2.png',
  //   checked: false,
  // },
  // {
  //   id: 3,
  //   filter: {
  //     admin_rights: {
  //       $eq: 3,
  //     },
  //   },
  //   name: getTranslate('team_members'),
  //   locale: 'team_members',
  //   translate: true,
  //   src: '/img/avatars/afro-avatar-male.png',
  //   checked: false,
  // },
  {
    id: 4,
    filter: {
      admin_rights: {
        $eq: 4,
      },
    },
    name: 'data_analyst',
    locale: 'data_analyst',
    translate: true,
    src: '/img/avatars/afro-boy-child.png',
    checked: false,
  },
];

export const getTextRights = (staff_admin, key = 'name', rights) => {
  let admin_rights = staff_admin?.attributes?.admin_rights;
  if (admin_rights == null) {
    if (rights == null) {
      return '';
    }
    admin_rights = rights;
  }
  const val = defaultAdminRights.find((f) => f.id === admin_rights);
  if (key === 'name') {
    return val ? getTranslate(val.locale) : '';
  }
  return val ? val[key] : '';
};
