import { getTranslate } from 'components/CustomService';
import { Modal } from 'antd';
const { confirm } = Modal;

const useConfirm = (props = { title: null, content: null }) => {
  const { title, content } = props;
  const _default = {
    visible: false,
    title: title ?? `${getTranslate('confirmSaveData')} ?`,
    content: content ?? getTranslate('content_save'),
    _onOk: () => {
      return;
    },
    _onCancel: () => {
      return;
    },
  };

  function showConfirm(val) {
    confirm({
      className: 'waan-modal-confirm',
      title: _default.title,
      content: _default.content,
      width: 600,
      onOk: _default.onOk,
      onCancel: _default.onCancel,
      ...val,
    });
  }

  return { showConfirm };
};

export default useConfirm;
