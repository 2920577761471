import {
  AppstoreOutlined,
  InboxOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  GiftOutlined,
  TeamOutlined,
  ShoppingOutlined,
  RadarChartOutlined,
  PieChartOutlined,
  SubnodeOutlined,
  SoundOutlined,
  QrcodeOutlined,
  HistoryOutlined,
  // LayoutOutlined,
  // ProjectOutlined,
  //AndroidOutlined,
  UserOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  CreditCardOutlined,
  ClusterOutlined,
  BlockOutlined,
  RobotOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

// const dashBoardNavTree = [
//   {
//     key: "home",
//     path: `${APP_PREFIX_PATH}/home`,
//     title: "home",
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];

const Group1NavTree = [
  {
    key: 'apps',
    path: '',
    title: '',
    breadcrumb: false,
    submenu: [
      {
        key: 'apps-overall',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'sidenav.group1.overall',
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'apps-point',
        path: `${APP_PREFIX_PATH}/apps/point`,
        title: 'sidenav.group1.point',
        icon: PlusCircleOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'apps-point-addpoint',
            path: `${APP_PREFIX_PATH}/apps/point/add-point`,
            title: 'sidenav.group1.addpoint',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apps-point-pullpoint',
            path: `${APP_PREFIX_PATH}/apps/point/pull-point`,
            title: 'sidenav.group1.pullpoint',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apps-point-transferpoint',
            path: `${APP_PREFIX_PATH}/apps/point/transfer-point`,
            title: 'sidenav.group1.point.transferpoint',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apps-point-transactions',
            path: `${APP_PREFIX_PATH}/apps/point/transactions`,
            title: 'sidenav.group1.point.transactions',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'apps-campaign',
        path: `${APP_PREFIX_PATH}/apps/campaign`,
        title: 'sidenav.group1.campaign',
        icon: SoundOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'apps-campaign-all',
            path: `${APP_PREFIX_PATH}/apps/campaign/index`,
            title: 'sidenav.group1.campaign.all',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apps-campaign-transactions',
            path: `${APP_PREFIX_PATH}/apps/campaign/transactions`,
            title: 'sidenav.group1.campaign.transactions',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'apps-coupon',
        path: `${APP_PREFIX_PATH}/apps/coupon`,
        title: 'sidenav.group1.coupon',
        icon: GiftOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'apps-coupon-redeem',
            path: `${APP_PREFIX_PATH}/apps/coupon/redeem-coupon`,
            title: 'sidenav.group1.coupon.redeem',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'apps-customer',
        path: `${APP_PREFIX_PATH}/apps/customers`,
        title: 'sidenav.group1.customer',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'apps-customer-list',
            path: `${APP_PREFIX_PATH}/apps/customers/customer-list`,
            title: 'sidenav.group1.customer.all',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apps-customer-customersLvSetting',
            path: `${APP_PREFIX_PATH}/apps/customers/customers-lv-setting`,
            title: 'sidenav.group1.customer.customersLvSetting',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'apps-customer-Segments',
            path: `${APP_PREFIX_PATH}/apps/automation/segments`,
            title: 'sidenav.group1.customer.Segments',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const Group2NavTree = [
  {
    key: 'store',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.group2',
    breadcrumb: false,
    submenu: [
      {
        key: 'store-order',
        path: `${APP_PREFIX_PATH}/apps/orders`,
        title: 'sidenav.group2.order',
        icon: ShoppingOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'store-order-list',
            path: `${APP_PREFIX_PATH}/apps/orders/order-list`,
            title: 'sidenav.group2.order.all',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'store-product',
        path: `${APP_PREFIX_PATH}/apps/products`,
        title: 'sidenav.group2.product',
        icon: InboxOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'store-product-list',
            path: `${APP_PREFIX_PATH}/apps/products/product-list`,
            title: 'sidenav.group2.product.all',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const Group3NavTree = [
  {
    key: 'tools',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.group3',
    breadcrumb: false,
    submenu: [
      {
        key: 'tools-analytical',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'sidenav.groups3.tools',
        icon: RadarChartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'tools-analytical-index',
            path: `${APP_PREFIX_PATH}/home`,
            title: 'sidenav.tools.xx',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'tools-reports',
        path: `${APP_PREFIX_PATH}/apps/report`,
        title: 'sidenav.groups3.reports',
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'tools-reports-campaign',
            path: `${APP_PREFIX_PATH}/apps/report/campaign`,
            title: 'campaign',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'tools-reports-demographics',
            path: `${APP_PREFIX_PATH}/apps/report/demographics`,
            title: 'sidenav.groups3.reports.demographics',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'tools-reports-staff',
            path: `${APP_PREFIX_PATH}/apps/report/staff`,
            title: 'sidenav.groups3.reports.staff',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'tools-reports-product',
            path: `${APP_PREFIX_PATH}/apps/report/product`,
            title: 'sidenav.groups3.reports.product',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'tools-reports-customer',
            path: `${APP_PREFIX_PATH}/apps/report/customer`,
            title: 'sidenav.groups3.reports.customer_point_history',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const settingsNavTree = [
  {
    key: 'setting',
    path: `${APP_PREFIX_PATH}/setting`,
    title: 'sidenav.setting',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: 'system-index',
      //   path: `${APP_PREFIX_PATH}/apps/system`,
      //   title: 'sidenav.group1.system',
      //   icon: SettingOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: 'system-activity',
      //       path: `${APP_PREFIX_PATH}/apps/system/system-activity`,
      //       title: 'sidenav.group1.system.activity',
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
      {
        key: 'setting-shop',
        path: `${APP_PREFIX_PATH}/setting-shop`,
        title: 'sidenav.setting.shop',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'setting-shop-overall',
            path: `${APP_PREFIX_PATH}/setting-shop/store-setting`,
            title: 'sidenav.setting.shop.overall',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: 'setting-shop-branchList',
          //   path: `${APP_PREFIX_PATH}/setting-shop/branch/branch-list`,
          //   title: 'sidenav.setting.shop.branchList',
          //   icon: '',
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: 'setting-shop-staff',
          //   path: `${APP_PREFIX_PATH}/setting-shop/staff/staff-list`,
          //   title: 'sidenav.setting.shop.staff',
          //   icon: '',
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: 'setting-shop-share-qr',
            path: `${APP_PREFIX_PATH}/setting-shop/share-qr`,
            title: 'sidenav.setting.shop.shareQr',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'setting-shop-portal',
            path: `${APP_PREFIX_PATH}/setting-shop/portal`,
            title: 'sidenav.setting.shop.portal',
            icon: '',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const GroupConnectNavTree = [
  {
    key: 'group-connect',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.connect',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-connect-line',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'sidenav.connect.line',
        icon: SubnodeOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'group-connect-line-1',
            path: `${APP_PREFIX_PATH}/home`,
            title: 'sidenav.connect.linx.xxx',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'group-connect-ecom',
        path: `${APP_PREFIX_PATH}/apps/connect`,
        title: 'sidenav.connect.ecom',
        icon: SubnodeOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'group-connect-ecom-lazada',
            path: `${APP_PREFIX_PATH}/apps/connect/lazada`,
            title: 'sidenav.connect.ecom.lazada',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const GroupNfcNavTree = [
  {
    key: 'group-nfc',
    path: `${APP_PREFIX_PATH}/nfc`,
    title: '',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-nfc-statistics',
        path: `${APP_PREFIX_PATH}/apps/nfc/statistics`,
        title: 'statistics',
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-nfc-index',
        path: `${APP_PREFIX_PATH}/apps/nfc/index`,
        title: 'NFC_QR_Link',
        icon: QrcodeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'group-nfc-page-builder',
      //   path: `${APP_PREFIX_PATH}/apps/page-builder/index`,
      //   title: 'page',
      //   icon: LayoutOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      // {
      //   key: 'group-nfc-questionnaire',
      //   path: `${APP_PREFIX_PATH}/apps/nfc/questionnaire`,
      //   title: 'questionnaire',
      //   icon: ProjectOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
  {
    key: 'group-tools',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.group3',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-tools-writer',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'NFC_Writer',
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'group-tools-writer-download_program',
            path: `${APP_PREFIX_PATH}/apps/nfc/download-program`,
            title: 'download_program',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'group-tools-writer-Instruction_manual',
            path: `${APP_PREFIX_PATH}/apps/nfc/Instruction-manual`,
            title: 'Instruction_manual',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const GroupProfileNavTree = [
  {
    key: 'group-profile',
    path: `${APP_PREFIX_PATH}/profile`,
    title: '',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-profile-edit',
        path: `${APP_PREFIX_PATH}/profile/edit-profile`,
        title: 'profile',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
        subSelectedKeys: [
          `${APP_PREFIX_PATH}/profile/movement`,
          `${APP_PREFIX_PATH}/profile/safety`,
          `${APP_PREFIX_PATH}/profile/company`,
          `${APP_PREFIX_PATH}/profile/notification`,
          `${APP_PREFIX_PATH}/profile/manage-data-usage`,
          `${APP_PREFIX_PATH}/profile/api-developers`,
        ],
      },
    ],
  },
  {
    key: 'group-store',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.group2',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-store-branch',
        path: `${APP_PREFIX_PATH}/setting-shop/branch/branch-list`,
        title: 'branch_management',
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-store-admin',
        path: `${APP_PREFIX_PATH}/setting-shop/staff/staff-list`,
        title: 'sidenav.groups3.reports.staff',
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-store-packages-quota',
        path: `${APP_PREFIX_PATH}/profile/package`,
        title: 'Packages_Quota',
        icon: AppstoreAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-store-bill-Payment',
        path: `${APP_PREFIX_PATH}/profile/billing`,
        title: 'Bill_Payment',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'group-system',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'system',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-system-movement',
        path: `${APP_PREFIX_PATH}/apps/system/system-activity`,
        title: 'movement_system',
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// const OverAllNavTree = [
//   {
//     key: "setting",
//     path: `${APP_PREFIX_PATH}/setting`,
//     title: "",
//     icon: SettingOutlined,
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "overall-dashboard",
//         path: `${APP_PREFIX_PATH}/xxxxxx/xxxxx`,
//         title: "xxx",
//         icon: SettingOutlined,
//         breadcrumb: true,

//       },
//     ],
//   },
// ];

// const appsNavTree = [
//   {
//     key: "apps",
//     path: `${APP_PREFIX_PATH}/apps`,
//     title: "sidenav.apps",
//     icon: AppstoreOutlined,
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "apps-products",
//         path: `${APP_PREFIX_PATH}/apps/products`,
//         title: "sidenav.apps.products",
//         icon: InboxOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "apps-products-productList",
//             path: `${APP_PREFIX_PATH}/apps/products/product-list`,
//             title: "sidenav.apps.products.productList",
//             icon: "",
//             breadcrumb: false,
//             submenu: [],
//           },
//           {
//             key: "apps-products-addProduct",
//             path: `${APP_PREFIX_PATH}/apps/products/add-product`,
//             title: "sidenav.apps.products.addProduct",
//             icon: "",
//             breadcrumb: false,
//             submenu: [],
//           },
//           {
//             key: "apps-products-editProduct",
//             path: `${APP_PREFIX_PATH}/apps/products/edit-product/1`,
//             title: "sidenav.apps.products.editProduct",
//             icon: "",
//             breadcrumb: false,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "apps-customers",
//         path: `${APP_PREFIX_PATH}/apps/customers`,
//         title: "sidenav.apps.customers",
//         icon: UserOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "apps-customers-customerList",
//             path: `${APP_PREFIX_PATH}/apps/customers/customer-list`,
//             title: "sidenav.apps.customers.customerList",
//             icon: "",
//             breadcrumb: false,
//             submenu: [],
//           },
//         ],
//       },
//     ],
//   },
// ];

const navigationConfig = [
  ...Group1NavTree,
  ...Group2NavTree,
  ...Group3NavTree,
  ...settingsNavTree,
  ...GroupConnectNavTree,
  ...GroupNfcNavTree,
  ...GroupProfileNavTree,
];

export default navigationConfig;

export const GroupStaffNavTree = [
  {
    key: 'group-staff',
    path: `${APP_PREFIX_PATH}/staff`,
    title: '',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-staff-overview',
        path: `${APP_PREFIX_PATH}/staff/overview`,
        title: 'overview_report',
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-statistics',
        path: `${APP_PREFIX_PATH}/staff/statistics`,
        title: 'statistics_report',
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-link',
        path: `${APP_PREFIX_PATH}/staff/link/index`,
        title: 'all_links_and_pages',
        icon: QrcodeOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'group-staff-document',
    path: `${APP_PREFIX_PATH}/staff`,
    title: 'document',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-staff-document-order',
        path: `${APP_PREFIX_PATH}/staff/order-package/index`,
        title: 'to_order',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'group-staff-settings',
    path: `${APP_PREFIX_PATH}/staff`,
    title: 'setting',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-staff-settings-company',
        path: `${APP_PREFIX_PATH}/staff/company/index`,
        title: 'company_group',
        icon: ClusterOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-settings-user',
        path: `${APP_PREFIX_PATH}/staff/user/index`,
        title: 'user2',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-settings-current-package',
        path: `${APP_PREFIX_PATH}/staff/current-package/index`,
        title: 'current_package',
        icon: AppstoreAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'group-staff-system',
    path: `${APP_PREFIX_PATH}/staff`,
    title: 'system',
    breadcrumb: false,
    submenu: [
      {
        key: 'group-staff-system-template',
        path: `${APP_PREFIX_PATH}/staff/template/index`,
        title: 'template',
        icon: BlockOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-system-template-block',
        path: `${APP_PREFIX_PATH}/staff/template/blocks`,
        title: 'layout_template',
        icon: BlockOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-settings-waan-staff',
        path: `${APP_PREFIX_PATH}/staff/waan-staff/index`,
        title: 'Waan_STAFF',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-system-movement',
        path: `${APP_PREFIX_PATH}/staff/log_activity/index`,
        title: 'movement_staff',
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-system-movement-user',
        path: `${APP_PREFIX_PATH}/staff/system-activity/index`,
        title: 'movement_user',
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-email-movement',
        path: `${APP_PREFIX_PATH}/staff/log_email/index`,
        title: 'email_movement',
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-system-timer-system',
        path: `${APP_PREFIX_PATH}/staff/link/index`,
        title: 'timer_system',
        icon: RobotOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'group-staff-system-tools-print',
        path: `${APP_PREFIX_PATH}/staff/tools-print/index`,
        title: 'tools_print',
        icon: PrinterOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

export const navigationGroup1Nav = [
  ...Group1NavTree,
  ...Group2NavTree,
  ...Group3NavTree,
  ...settingsNavTree,
  ...GroupConnectNavTree,
];

export const navigationGroup2Nav = [...GroupProfileNavTree];

export const navigationGroup3Nav = [...GroupNfcNavTree];

export const navigationGroup4Nav = [...GroupStaffNavTree];

const Group1Url = [
  '/app/home',
  '/app/apps/point',
  '/app/apps/campaign',
  '/app/apps/coupon',
  '/app/apps/customers',
  '/app/apps/automation',
  '/app/apps/orders',
  '/app/apps/products',
  '/app/apps/report',
  '/app/apps/connect',
  '/app/setting-shop/store-setting',
  '/app/setting-shop/share-qr',
  '/app/setting-shop/portal',
];

const Group2Url = [
  '/app/setting-shop/staff/staff-list',
  '/app/setting-shop/branch/branch-list',
  '/app/profile',
  '/app/apps/system',
];

const Group3Url = ['/app/apps/nfc', '/app/apps/page-builder'];

const Group4Url = [
  '/app/staff/overview',
  '/app/staff/statistics',
  '/app/staff/link/index',
  '/app/staff/order-package/index',
  '/app/staff/company/index',
  '/app/staff/user/index',
  '/app/staff/current-package/index',
  '/app/staff/template/index',
  '/app/staff/waan-staff/index',
  '/app/staff/log_activity/index',
  '/app/staff/log_email/index',
  '/app/staff/system-activity/index',
  '/app/staff/tools-print/index',
];

export const tabMenuId = {
  setting: Group2Url,
  nfc: Group3Url,
  home: Group1Url,
  staff: Group4Url,
};
