import {
  REFRESH_CHANGE,
  USER_INFO_CHANGE,
  CUSTOMER_TAGS_CHANGE,
  CUSTOMER_TAGS_GET,
  LOADDATA_CUSTOMER_TAGS,
  CUSTOMER_TAGS_UPDATE,
  COMPANIES_CHANGE,
  COMPANIES_GET,
  WAAN_SHOW_LOADING,
  WAAN_HIDE_LOADING,
  NOTIFICATION_CHANGE,
  NOTIFICATION_GET,
  NOTIFICATION_COUNT_READ_CHANGE,
  IMPORT_DATA_ID_CHANGE,
  VISIBLE_CHANGE_PACKAGE,
} from '../constants/Waan';

export function onRefreshChange(refresh) {
  return {
    type: REFRESH_CHANGE,
    refresh,
  };
}

export function onUserInfoChange(user) {
  return {
    type: USER_INFO_CHANGE,
    userInfo: user,
  };
}

export function customerTagsChange(data) {
  return {
    type: CUSTOMER_TAGS_CHANGE,
    customerTags: data,
  };
}

export function getCustomerTags(companie_id) {
  return {
    type: CUSTOMER_TAGS_GET,
    payload: { companie_id: companie_id },
  };
}

export function loadDataCustomerTags(status) {
  return {
    type: LOADDATA_CUSTOMER_TAGS,
    status,
  };
}

export function updateCustomerTags(companie_id) {
  return {
    type: CUSTOMER_TAGS_UPDATE,
    payload: { companie_id: companie_id },
  };
}

export function companiesChange(companies) {
  return {
    type: COMPANIES_CHANGE,
    companies,
  };
}

export function companiesGet() {
  return {
    type: COMPANIES_GET,
    payload: {},
  };
}

export const showLoading = () => {
  return {
    type: WAAN_SHOW_LOADING,
  };
};

export const hideLoading = () => {
  return {
    type: WAAN_HIDE_LOADING,
  };
};

export function notificationChange(notification) {
  return {
    type: NOTIFICATION_CHANGE,
    notification,
  };
}

export function notificationGet() {
  return {
    type: NOTIFICATION_GET,
    payload: {},
  };
}

export function countReadChange(count_read) {
  return {
    type: NOTIFICATION_COUNT_READ_CHANGE,
    count_read,
  };
}

export function importDataIdChange(id) {
  return {
    type: IMPORT_DATA_ID_CHANGE,
    importId: id,
  };
}

export function onVisibleChangePackage(visible) {
  return {
    type: VISIBLE_CHANGE_PACKAGE,
    visible: visible,
  };
}
